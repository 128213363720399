import { include } from "named-urls";

export const Endpoints = {
  api: include("api", {
    common: include("common", {
      location: include("location", {
        states: "states",
        countiesByState: "state/:stateId/county",
      }),
      settings: include("settings", {
        version: "version",
        sessionConfig: "sessionConfig",
        systemSetting: "systemSetting/:ss",
      }),
      vendors: include("vendors", {
        weekList: include("weekList", {
          list: "",
          transmitweekList: "transmitWeeks",
        }),
      }),
    }),
  }),
};
