import { STButton } from "StaffTrack";
import styled from "styled-components";
import { mobileQuery } from "App/media";

export const HeaderLeftContainer = styled.div`
  flex-basis: 90%;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-top: 0.2rem;
  position: relative;
`;

export const LogoContainer = styled.div`
  * {
    height: 3.5rem;
  }
  @media ${mobileQuery} {
    width: 4.5rem;
    margin-left: -0.5rem;
  }
`;

type MenuButtonProps = {
  // This boolean value needs to be a number so React does not give us the warning:
  // Warning: Received `false` for a non-boolean attribute `active`.
  shouldclose: number;
};

export const MenuButton = styled(STButton)<MenuButtonProps>`
  transform: scale(0.75, 0.75)
    ${({ shouldclose }) => (!(shouldclose === 0) ? `rotate(0.5turn)` : ``)};
  transition: all 0.3s;
  &:enabled:hover {
    transform: scale(0.9, 0.9)
      ${({ shouldclose }) => (!(shouldclose === 0) ? `rotate(0.5turn)` : ``)};
  }
`;
