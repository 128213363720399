import APiError from "Components/APIError";

const Error404 = () => (
  <APiError
    status={404}
    message="Page not found"
    submessage="The page you were looking for does not exist."
  />
);

export default Error404;
