import { STBreadCrumb } from "Types/Breadcrumb";
import { paths } from "Routes/Paths";

export const dashBoardBreadcrumbs: STBreadCrumb[] = [
  {
    title: "Main",
    url: "/",
    external: false,
    translationResource: "breadcrumbs:dashboard.main",
    subMenuId: 6,
    menuId: 1,
  },
  {
    title: "Dashboard",
    url: "/",
    external: false,
    translationResource: "breadcrumbs:dashboard.dashboard",
    menuId: 1,
  },
  {
    title: "ActionRequired",
    url: "/",
    external: false,
    translationResource: "breadcrumbs:dashboard.ActionRequired",
  },
  {
    title: "ApproveHours",
    url: paths.dashboard.approveHours,
    external: false,
    translationResource: "breadcrumbs:dashboard.approveHours",
    subMenuId: 8,
  },
  {
    title: "AddAssociates",
    url: paths.dashboard.addAssociate,
    external: false,
    translationResource: "breadcrumbs:dashboard.addAssociates",
    subMenuId: 25,
  },
  {
    title: "AddHours",
    url: paths.dashboard.addHours.main,
    external: false,
    translationResource: "breadcrumbs:dashboard.addHours",
    subMenuId: 24,
  },
  {
    title: "SchedAssocMissingTime",
    url: paths.dashboard.addHours.scheduleMissingTime,
    external: false,
    translationResource: "breadcrumbs:dashboard.schedAssocMissingTime",
    subMenuId: 24,
  },
  {
    title: "TakeAction",
    url: paths.dashboard.takeAction,
    external: false,
    translationResource: "breadcrumbs:dashboard.takeAction",
    subMenuId: 23,
  },
  {
    title: "HelpLibrary",
    url: paths.dashboard.helpLibrary,
    external: false,
    translationResource: "breadcrumbs:dashboard.helpLibrary",
    menuId: 1,
  },
  {
    title: "WorkLocationCodes",
    url: paths.dashboard.workLocationCodes,
    external: false,
    translationResource: "breadcrumbs:dashboard.workLocationCodes",
    menuId: 1,
  },
  {
    title: "DashboardActionRequest",
    url: paths.dashboard.actionRequests,
    external: false,
    translationResource: "breadcrumbs:associates.ActionRequest",
    subMenuId: 7,
  },
  {
    title: "DashboardNewActionRequest",
    url: paths.associatePaths.caarMain.caarSearchAssociate,
    external: false,
    translationResource: "breadcrumbs:dashboard.actionNewRequest",
    subMenuId: 7,
  },
  {
    title: "ViewInvoices",
    url: paths.dashboard.invoices.list,
    external: false,
    translationResource: "breadcrumbs:dashboard.viewInvoices",
    subMenuId: 39,
  },
  {
    title: "ApproveInvoices",
    url: paths.dashboard.invoices.list,
    external: false,
    translationResource: "breadcrumbs:dashboard.approveInvoices",
    subMenuId: 39,
  },
  {
    title: "InvoiceDetails",
    url: paths.dashboard.invoices.view,
    external: false,
    translationResource: "breadcrumbs:dashboard.invoiceDetails",
    subMenuId: 39,
  },
];
