import styled from "styled-components";

export const Title = styled.div`
  font-size: 1.3125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.gray860};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.4375rem 1.25rem 1.4375rem 1.25rem;
  background-color: var(--gray-150);
  height: 3.5rem;
`;
