import { useAtom } from "jotai";
import { menuExtraInfoAtom } from "Context/AppGlobalState";
import { useEffect } from "react";
import styled from "styled-components";
import { viewModeAtom } from "Context/AppGlobalState";
import { useAppContext } from "Context/AppContext";
import { useIsOfficeHavePendingTickler } from "Api/Hooks/Associate/CaarMain";

const RedDot = styled.div`
  &:after {
    content: " ";
    display: inline-block;
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    vertical-align: middle;
    margin-left: 4px;
    margin-bottom: 3px;
  }
`;

const useMenuLogic = () => {
  const [, setMenuExtraInfo] = useAtom(menuExtraInfoAtom);
  const [viewMode] = useAtom(viewModeAtom);
  const { currentOffice } = useAppContext();
  const offc = currentOffice?.officeId;
  const { data } = useIsOfficeHavePendingTickler(offc);

  // Add a new useEffect for every different logic
  useEffect(() => {
    if (data === undefined || data.data === undefined) {
      setMenuExtraInfo([]);
      return;
    }
    // create your logic here and set the menu you need wie the reddot
    if (viewMode === "vendor" && data.data) {
      setMenuExtraInfo([
        { id: 37, element: <RedDot /> },
        { id: 1, element: <RedDot /> },
      ]);
    } else {
      setMenuExtraInfo([]);
    }
  }, [data, viewMode]);
};

export default useMenuLogic;
