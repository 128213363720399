/* eslint-disable */
export const theme = {
  surfaceB: "var(--surface-b)",
  surfaceC: "var(--surface-c)",
  surfaceD: "var(--surface-d)",
  surfaceE: "var(--surface-e)",
  surfaceF: "var(--surface-f)",
  textColor: "var(--text-color)",
  textColorSecondary: "var(--text-color-secondary)",
  primaryColor: "var(--primary-color)",
  surfaceO: "var(--surface-0)",
  surface50: "var(--surface-50)",
  surface100: "var(--surface-100)",
  surface200: "var(--surface-200)",
  surface300: "var(--surface-300)",
  surface400: "var(--surface-400)",
  surface500: "var(--surface-500)",
  surface600: "var(--surface-600)",
  surface700: "var(--surface-700)",
  surface800: "var(--surface-800)",
  surface900: "var(--surface-900)",
  gray50: "var(--gray-50)",
  gray100: "var(--gray-100)",
  gray200: "var(--gray-200)",
  gray300: "var(--gray-300)",
  gray400: "var(--gray-400)",
  gray500: "var(--gray-500)",
  gray600: "var(--gray-600)",
  gray700: "var(--gray-700)",
  gray800: "var(--gray-800)",
  gray900: "var(--gray-900)",
  contentPadding: "var(--content-padding:1.25rem)",
  inlineSpacing: "var(--inline-spacing:0.5rem)",
  borderRadius: "var(--border-radius:6px)",
  surfaceGround: "var(--surface-ground)",
  surfaceSection: "var(--surface-section)",
  surfaceCard: "var(--surface-card)",
  surfaceOverlay: "var(--surface-overlay)",
  surfaceBorder: "var(--surface-border)",
  surfaceHover: "var(--surface-hover)",
  focusRing: "var(--focus-ring: 0 0 0 0.2rem #C7D2FE)",
  maskBg: "var(--maskbg: rgba(0, 0, 0, 0.4)",
  blue50: "var(--blue-50)",
  blue100: "var(--blue-100)",
  blue200: "var(--blue-200)",
  blue300: "var(--blue-300)",
  blue400: "var(--blue-400)",
  blue500: "var(--blue-500)",
  blue600: "var(--blue-600)",
  blue700: "var(--blue-700)",
  blue800: "var(--blue-800)",
  blue830: "var(--blue-830)",
  blue900: "var(--blue-900)",
  green50: "var(--green-50)",
  green100: "var(--green-100)",
  green200: "var(--green-200)",
  green300: "var(--green-300)",
  green400: "var(--green-400)",
  green500: "var(--green-500)",
  green600: "var(--green-600)",
  green700: "var(--green-700)",
  green800: "var(--green-800)",
  green900: "var(--green-900)",
  yellow50: "var(--yellow-50)",
  yellow100: "var(--yellow-100)",
  yellow200: "var(--yellow-200)",
  yellow300: "var(--yellow-300)",
  yellow400: "var(--yellow-400)",
  yellow500: "var(--yellow-500)",
  yellow600: "var(--yellow-600)",
  yellow700: "var(--yellow-700)",
  yellow800: "var(--yellow-800)",
  yellow900: "var(--yellow-900)",
  cyan50: "var(--cyan-50)",
  cyan100: "var(--cyan-100)",
  cyan200: "var(--cyan-200)",
  cyan300: "var(--cyan-300)",
  cyan400: "var(--cyan-400)",
  cyan500: "var(--cyan-500)",
  cyan600: "var(--cyan-600)",
  cyan700: "var(--cyan-700)",
  cyan800: "var(--cyan-800)",
  cyan900: "var(--cyan-900)",
  pink50: "var(--pink-50)",
  pink100: "var(--pink-100)",
  pink200: "var(--pink-200)",
  pink300: "var(--pink-300)",
  pink400: "var(--pink-400)",
  pink500: "var(--pink-500)",
  pink600: "var(--pink-600)",
  pink700: "var(--pink-700)",
  pink800: "var(--pink-800)",
  pink900: "var(--pink-900)",
  indigo50: "var(--indigo-50)",
  indigo100: "var(--indigo-100)",
  indigo200: "var(--indigo-200)",
  indigo300: "var(--indigo-300)",
  indigo400: "var(--indigo-400)",
  indigo500: "var(--indigo-500)",
  indigo600: "var(--indigo-600)",
  indigo700: "var(--indigo-700)",
  indigo800: "var(--indigo-800)",
  indigo900: "var(--indigo-900)",
  teal50: "var(--teal-50)",
  teal100: "var(--teal-100)",
  teal200: "var(--teal-200)",
  teal300: "var(--teal-300)",
  teal400: "var(--teal-400)",
  teal500: "var(--teal-500)",
  teal600: "var(--teal-600)",
  teal700: "var(--teal-700)",
  teal800: "var(--teal-800)",
  teal900: "var(--teal-900)",
  orange50: "var(--orange-50)",
  orange100: "var(--orange-100)",
  orange200: "var(--orange-200)",
  orange300: "var(--orange-300)",
  orange400: "var(--orange-400)",
  orange500: "var(--orange-500)",
  orange600: "var(--orange-600)",
  orange700: "var(--orange-700)",
  orange800: "var(--orange-800)",
  orange900: "var(--orange-900)",
  bluegray50: "var(--bluegray-50)",
  bluegray100: "var(--bluegray-100)",
  bluegray200: "var(--bluegray-200)",
  bluegray300: "var(--bluegray-300)",
  bluegray400: "var(--bluegray-400)",
  bluegray500: "var(--bluegray-500)",
  bluegray600: "var(--bluegray-600)",
  bluegray700: "var(--bluegray-700)",
  bluegray800: "var(--bluegray-800)",
  bluegray900: "var(--bluegray-900)",
  purple50: "var(--purple-50)",
  purple100: "var(--purple-100)",
  purple200: "var(--purple-200)",
  purple300: "var(--purple-300)",
  purple400: "var(--purple-400)",
  purple500: "var(--purple-500)",
  purple600: "var(--purple-600)",
  purple700: "var(--purple-700)",
  purple800: "var(--purple-800)",
  purple900: "var(--purple-900)",

  // Custom Colors
  success: "var(--success)",
  warn: "var(--warn)",
  alert: "var(--alert)",

surface350: "var(--surface-350)",
  blue850: "var(--blue-850)",
  blue650: "var(--blue-650)",
  blue660: "var(--blue-660)",
  gray150: "var(--gray-150)",
  gray170: "var(--gray-170)",
  gray850: "var(--gray-850)",
  gray860: "var(--gray-860)",
};
