import styled from "styled-components";

export const Container = styled.div`
  margin-top: 1rem;
  ul {
    list-style-type: none;
    padding: 0;
  }
`;

export const BaseMenuItem = styled.a`
  padding-right: 0.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  transition: all 0.3s;
  text-decoration: none;
  overflow: hidden;
  &:hover {
    background-color: ${({ theme }) => theme.surfaceHover};
  }
  cursor: pointer;
`;
