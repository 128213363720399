import styled from "styled-components";

export const Item = styled.li`
  color: ${({ theme }) => theme.primaryColor};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ItemText = styled.li`
  display: flex;
  align-items: center;
`;

export const LogoutText = styled.span`
  margin-left: 0.5rem;
`;
