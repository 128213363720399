import { include } from "named-urls";

export const Endpoints = {
  api: include("api", {
    user: include("user", {
      root: "",
      currentOffice: "currentOffice",
      currentOfficeAsDefault: "currentOfficeAsDefault",
      changeOffice: "changeOffice",
      officeList: "officeList",
      officeOptions: "officeOptions",
      changePassword: "changePassword",
      validatePassword: "validatePassword",
    }),
  }),
};
