import Logo from "Assets/Logos/logo_mySTN_color_reverse_light.svg";
import { paths } from "Routes/Paths";
import HamburgerButton from "Components/HamburgerButton";
import { stringifyURL } from "StaffTrack";
import { useQueryParams } from "Lib/UseQueryParams";
import { Link } from "react-router-dom";
import { HeaderLeftContainer, LogoContainer } from "./styles";
import "./styles.css";
import { useAtom } from "jotai";
import { serviceTeamAtom } from "Context/AppGlobalState";
import ServiceTeamHeaderToggle from "Components/Header/HeaderLeft/serviceTeamHeaderToggle";
import { REACT_ENV } from "Constants/Environment";
import { useMobile } from "App/media";
import styled from "styled-components";

const DevWarning = styled.div`
  color: var(--danger);
`;

export const HeaderLeft = () => {
  const { getUnsafeNullValue } = useQueryParams();
  const officeId = getUnsafeNullValue("officeId");
  const { isMobile } = useMobile();

  const [serviceTeam] = useAtom(serviceTeamAtom);
  return (
    <HeaderLeftContainer>
      <HamburgerButton />
      <LogoContainer>
        <Link
          to={stringifyURL(paths.home, { queryStringParams: { officeId } })}
          className="mr-6"
        >
          <Logo />
        </Link>
      </LogoContainer>
      {serviceTeam && <ServiceTeamHeaderToggle />}
      {REACT_ENV !== "production" && !isMobile && (
        <DevWarning
          className="font-semibold text-3xl w-30rem"
          id="testMessageError"
        >
          This is TEST SYSTEM {`${window.location.hostname}`}
        </DevWarning>
      )}
    </HeaderLeftContainer>
  );
};
