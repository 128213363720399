import { MdAssignmentInd, MdDashboard, MdDateRange } from "react-icons/md";

import { useMemo } from "react";
import { BiBarChartSquare } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { MenuGroup, Submenu } from "Components/Menu/MenuItems/MenuGroup";
import { SecuredResources } from "Constants/Security";
import { paths } from "Routes/Paths";
import { useAtom } from "jotai";
import { viewModeAtom } from "Context/AppGlobalState";
import { useSessionConfig } from "Api/Hooks/Common";
import { useAppContext } from "Context/AppContext";
import { SessionConfigDTO } from "Api/StaffTrack/Common/Models/sessionConfigDto";

type AdditionalCheck = {
  name: string;
  value: boolean | number | string;
};

type ChildMenu = {
  id: number;
  path: string;
  active: boolean;
  label?: string;
  additionalCheck?: AdditionalCheck;
  isAssociateMenu?: boolean;
  queryStringParams?: {};
};

type ParentMenu = {
  id: number;
  icon: JSX.Element;
  resourceId?: number;
  active: boolean;
  submenus?: ChildMenu[];
  additionalCheck?: AdditionalCheck;
};

export const clientMenu = [
  {
    menu: "Dashboard",
    submenus: [
      "DashboardMain",
      "ActionRequest",
      "ApproveHours",
      "ViewInvoices",
      "ApproveInvoices",
    ],
  },
  {
    menu: "OrderWorkers",
    submenus: ["ViewEditOrders", "ViewEditRequisition", "NewRequisitions"],
  },
  {
    menu: "AnalyticsReporting",
    submenus: ["StandardReports", "CustomReports", "CustomDashboards"],
  },
  {
    menu: "Associates",
    submenus: ["AssociateSearchClient", "AssociateClientMain", "Timesheet"],
  },
  {
    menu: "Resources",
    submenus: ["MyServiceTeam", "RecruitingServices", "OtherOfferings"],
  },
];

export const vendorMenu = [
  {
    menu: "Dashboard",
    submenus: [
      "MyTakeActions",
      "AddHours",
      "AddAssociates",
      "VendorActionRequest",
    ],
  },
  {
    menu: "Orders",
    submenus: ["OpenOrders", "PendingOrders"],
  },
  {
    menu: "Associates",
    submenus: [
      "VendorAssociates",
      "AssociateSearchVendor",
      "AssociateMain",
      "RecruitingAndSetup",
      "Payroll",
      "VendorAssocActionRequest",
    ],
  },
  {
    menu: "AnalyticsReporting",
    submenus: ["StandardReports"],
  },
  {
    menu: "Resources",
    submenus: [
      "MyServiceTeam",
      "VendorContactInfo",
      "VendorForms",
      "ImportList",
    ],
  },
];
const menuItems: Map<string, ParentMenu | ChildMenu> = new Map();
menuItems.set("Dashboard", {
  id: 1,
  icon: <MdDashboard size={20} color="var(--blue-660)" />,
  active: true,
  resourceId: SecuredResources.MYSTN2_DASHBOARD,
});
menuItems.set("OrderWorkers", {
  id: 2,
  icon: <MdDateRange size={20} color="var(--blue-660)" />,
  active: true,
  resourceId: SecuredResources.MYSTN2_ORDER_WORKERS,
  additionalCheck: {
    name: "showOrderWorkersMenu",
    value: true,
  },
});
menuItems.set("AnalyticsReporting", {
  id: 3,
  icon: <BiBarChartSquare size={20} color="var(--blue-660)" />,
  active: true,
  resourceId: SecuredResources.MYSTN2_ANALYTICS_REPORTING,
});
menuItems.set("Associates", {
  id: 4,
  icon: <MdAssignmentInd size={20} color="var(--blue-660)" />,
  active: true,
  resourceId: SecuredResources.MYSTN2_ASSOCIATES,
});
menuItems.set("Resources", {
  id: 5,
  icon: <FaUsers size={20} color="var(--blue-660)" />,
  active: true,
  resourceId: SecuredResources.MYSTN2_RESOURCES,
});
menuItems.set("DashboardMain", {
  id: 6,
  path: "/",
  active: true,
});
menuItems.set("ActionRequest", {
  id: 7,
  path: paths.dashboard.actionRequests,
  active: true,
  additionalCheck: {
    name: "isOfficeCaarEnabled",
    value: true,
  },
});
menuItems.set("ApproveHours", {
  id: 8,
  path: paths.dashboard.approveHours,
  active: true,
  resourceId: SecuredResources.ASSOCIATE_CURRENT_ATTENDANCE,
  additionalCheck: {
    name: "showApproveHours",
    value: true,
  },
});
menuItems.set("ApproveWorkerRates", {
  id: 9,
  path: "/",
  active: true,
});
menuItems.set("ApproveInvoices", {
  id: 10,
  path: "/",
  active: true,
});
menuItems.set("ViewEditOrders", {
  id: 11,
  path: paths.orderWorkers.main,
  active: true,
  additionalCheck: {
    name: "canEditOrder",
    value: true,
  },
});
menuItems.set("ViewEditRequisition", {
  id: 12,
  path: paths.orderWorkers.viewRequisition,
  active: true,
  additionalCheck: {
    name: "clientRequisitionEntryEnabled",
    value: true,
  },
});
menuItems.set("NewRequisitions", {
  id: 13,
  path: paths.orderWorkers.addRequisition,
  active: true,
  additionalCheck: {
    name: "clientRequisitionEntryEnabled",
    value: true,
  },
});
menuItems.set("StandardReports", {
  id: 14,
  path: "/standardReports",
  active: true,
});
menuItems.set("CustomReports", {
  id: 15,
  path: paths.customReportsPaths.root,
  active: true,
});
menuItems.set("CustomDashboards", {
  id: 16,
  path: paths.customDashboardsPaths.root,
  active: true,
  resourceId: SecuredResources.MYSTN2_CUSTOM_DASHBOARD,
  additionalCheck: {
    name: "showCustomDashboardMenu",
    value: true,
  },
});
menuItems.set("ReportWriter", {
  id: 17,
  path: "/",
  active: true,
});
menuItems.set("AssociateSearchVendor", {
  id: 18,
  path: paths.associatePaths.associateSearchAndList.search,
  active: true,
});
menuItems.set("MyServiceTeam", {
  id: 19,
  path: paths.resources.myServiceTeam,
  active: true,
});
menuItems.set("RecruitingServices", {
  id: 20,
  path: paths.resources.recruitingServices,
  active: true,
  additionalCheck: {
    name: "showRecruitingServices",
    value: true,
  },
});
menuItems.set("OtherOfferings", {
  id: 21,
  path: paths.resources.otherOfferings,
  active: true,
  resourceId: SecuredResources.MYSTN2_OTHER_OFFERINGS,
  additionalCheck: {
    name: "showOtherOfferings",
    value: true,
  },
});
menuItems.set("Orders", {
  id: 22,
  icon: <MdDateRange size={20} color="var(--blue-660)" />,
  active: true,
  resourceId: SecuredResources.MYSTN2_ORDERS,
});
menuItems.set("OpenOrders", {
  id: 25,
  path: paths.orders.openOrders.main,
  active: true,
});
menuItems.set("PendingOrders", {
  id: 34,
  path: paths.orders.pendingOrders.main,
  active: true,
});
menuItems.set("MyTakeActions", {
  id: 23,
  path: paths.dashboard.takeAction,
  active: true,
});
menuItems.set("AddHours", {
  id: 24,
  path: paths.dashboard.addHours.main,
  active: true,
  additionalCheck: {
    name: "isTimeEntryEnabled",
    value: true,
  },
});
menuItems.set("AddAssociates", {
  id: 25,
  path: paths.dashboard.addAssociate,
  active: true,
});
menuItems.set("VendorAssociates", {
  id: 26,
  path: paths.associatePaths.associateSearchAndList.list,
  queryStringParams: { status: "1, 3", viewMode: "vendor" },
  active: true,
});
menuItems.set("AssociateSearchClient", {
  id: 27,
  path: paths.associatePaths.associateSearchAndList.search,
  active: true,
});
menuItems.set("AssociateMain", {
  id: 28,
  path: paths.associatePaths.main.root,
  isAssociateMenu: true,
  active: true,
});
menuItems.set("RecruitingAndSetup", {
  id: 29,
  path: paths.associatePaths.recruitingAndSetup.root,
  isAssociateMenu: true,
  active: true,
});
menuItems.set("Payroll", {
  id: 30,
  path: paths.associatePaths.payroll.main,
  active: true,
  isAssociateMenu: true,
});
menuItems.set("VendorContactInfo", {
  id: 31,
  path: paths.resources.vendorContactInfo,
  active: true,
});
menuItems.set("VendorForms", {
  id: 32,
  path: paths.resources.vendorForms,
  active: true,
});
menuItems.set("ImportList", {
  id: 33,
  path: paths.resources.importList.weeks,
  active: true,
});
menuItems.set("AssociateClientMain", {
  id: 35,
  path: paths.associatePaths.main.root,
  isAssociateMenu: true,
  active: true,
});
menuItems.set("VendorAssocActionRequest", {
  id: 36,
  path: paths.associatePaths.caarMain.ticklerList,
  isAssociateMenu: true,
  active: true,
  additionalCheck: {
    name: "isOfficeVaarEnabled",
    value: true,
  },
});
menuItems.set("VendorActionRequest", {
  id: 37,
  path: paths.dashboard.actionRequests,
  active: true,
  additionalCheck: {
    name: "isOfficeVaarEnabled",
    value: true,
  },
});
menuItems.set("ViewInvoices", {
  id: 39,
  path: paths.dashboard.invoices.list,
  queryStringParams: { type: "V" },
  active: true,
  additionalCheck: {
    name: "showViewInvoices",
    value: true,
  },
});
menuItems.set("ApproveInvoices", {
  id: 39,
  path: paths.dashboard.invoices.list,
  queryStringParams: { type: "A" },
  active: true,
  additionalCheck: {
    name: "showApproveInvoices",
    value: true,
  },
});
menuItems.set("Timesheet", {
  id: 40,
  path: paths.associatePaths.timesheet.main,
  active: true,
  isAssociateMenu: true,
});

const validateAdditionalCheck = (
  additionalCheck?: AdditionalCheck,
  sessionConfig?: SessionConfigDTO
): boolean => {
  if (additionalCheck === undefined) return true;
  if (!sessionConfig || !sessionConfig[additionalCheck.name]) return false;
  if (sessionConfig[additionalCheck.name]) {
    return sessionConfig[additionalCheck.name] === additionalCheck.value;
  }
  return false;
};

const useMenuItems = () => {
  const [view] = useAtom(viewModeAtom);
  const menuType = view === "vendor" ? "vendor" : "client";

  const { currentOffice } = useAppContext();
  const { data: sessionConfig } = useSessionConfig(currentOffice?.officeId);

  return useMemo(() => {
    const appMenu = menuType === "client" ? clientMenu : vendorMenu;
    if (menuItems.size === 0) return null;
    return appMenu
      .filter((m) => {
        const menuItem = menuItems.get(m.menu) as ParentMenu;
        return validateAdditionalCheck(
          menuItem.additionalCheck,
          sessionConfig?.data
        );
      })
      .map((item) => {
        const newItem = menuItems.get(item.menu) as ParentMenu;
        if (!newItem) throw Error(`Menu was not found for ${item.menu}`);
        newItem.submenus = item.submenus
          .map((sm) => {
            const newSubmenu: ChildMenu = menuItems.get(sm) as ChildMenu;
            newSubmenu.label = sm;
            return newSubmenu;
          })
          .filter((sm) =>
            validateAdditionalCheck(sm.additionalCheck, sessionConfig?.data)
          );
        const newMenu: MenuGroup = {
          id: newItem.id,
          label: item.menu,
          icon: newItem.icon,
          resourceId: newItem.resourceId,
          submenus: newItem.submenus.map((sb) => {
            const subMenu: Submenu = {
              id: sb.id,
              label: sb.label || "",
              path: sb.path,
              isAssociateMenu: sb.isAssociateMenu,
              queryStringParams: sb.queryStringParams,
            };
            return subMenu;
          }),
        };
        return newMenu;
      });
  }, [menuType, menuItems.size, sessionConfig?.data]);
};

export default useMenuItems;
