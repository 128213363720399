import { IoChevronDownSharp } from "react-icons/io5";
import styled from "styled-components";
import { BaseMenuItem } from "Components/Menu/MenuItems/styles";

type MenuChevronProps = {
  active?: boolean;
};

export const MenuChevron = styled(IoChevronDownSharp)<MenuChevronProps>`
  transform: ${({ active }) => (active ? `rotate(0.5turn)` : ``)};
`;

export const MenuGroupItem = styled(BaseMenuItem)`
  padding-left: 0.6rem;
  color: ${({ theme }) => theme.indigo700};
  svg {
    margin-right: 0.2rem;
  }
  > div {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.blue660};
  }
  &:hover {
    background-color: var(--surface-100);
  }
`;
