import { STButton, STText } from "StaffTrack";
import { useTranslation } from "react-i18next";
import FormHeader from "Templates/Headers/PanelHeaders/FormHeader";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";

const ForgotPasswordSentForm = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="panel panel-header">
          <FormHeader headerTitle={t("login.resetPasswordRequest")} />
        </div>
        <div className="panel-body">
          <div style={{ width: "20rem", overflow: "hidden" }}>
            <div className={"text-center my-2"}>
              <STText
                label={t("login.resetRequestSent")}
                variant={"plainText"}
                labelCapitalized={false}
              />
            </div>

            <div className="flex justify-content-end mt-4">
              <STButton
                className="px-3"
                type="button"
                onClick={() => navigate(paths.login)}
              >
                <span>{t("login.ok")}</span>
              </STButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordSentForm;
