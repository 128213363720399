import { useAtom } from "jotai";
import { modalsAtom, ModalState } from "Context/AppGlobalState";

const useAlertModal = () => {
  const [, setModals] = useAtom(modalsAtom);

  const addModal = (
    message: string,
    mode: "attention" | "success",
    title?: string,
    customButtons?: JSX.Element[]
  ) => {
    //Generate id
    const id = Math.random().toString(36).substr(2, 9);
    //Add new modal to the list
    setModals((prev) => {
      const newArray: ModalState[] = [
        ...prev,
        {
          id,
          title,
          message,
          customButtons,
          mode,
        },
      ];
      return newArray;
    });
  };
  const showAttention = (
    message: string,
    title?: string,
    customButtons?: JSX.Element[]
  ) => {
    addModal(message, "attention", title, customButtons);
  };

  const showSuccess = (
    message: string,
    title?: string,
    customButtons?: JSX.Element[]
  ) => {
    addModal(message, "success", title, customButtons);
  };

  return { showAttention, showSuccess };
};

export default useAlertModal;
