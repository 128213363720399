import { include } from "named-urls";

export default {
  orders: include("/orders", {
    openOrders: include("openOrders", {
      main: "",
      addMultiplessociates: "addMultipleAssociates",
      addSingleAssociate: "addSingleAssociate",
      addExistingAssociate: "addExistingAssociate",
    }),
    pendingOrders: include("pendingOrders", {
      main: "",
    }),
  }),
};
