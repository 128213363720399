import { ErrorMessage, STButton, STText } from "StaffTrack";
import { FC, useEffect } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import FormHeader from "Templates/Headers/PanelHeaders/FormHeader";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import FloatField from "Pages/Login/Components/FloatField";
import { ResetPasswordFullForm } from "Pages/Login/Forms/ResetPassword/Logic/resetPasswordFormValues";
import { ForgetPwdQuestionDTO } from "Api/StaffTrack/Auth/Models/forgetPwdQuestionDto";

type Props = {
  isLoading: boolean;
  errorMessage: string;
  questions: ForgetPwdQuestionDTO[];
};
const AnswerSecurityQuestionsForm: FC<Props> = ({
  isLoading,
  errorMessage,
  questions,
}) => {
  const { values, setFieldValue, handleChange, handleSubmit, handleBlur } =
    useFormikContext<ResetPasswordFullForm>();
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  useEffect(() => {
    if (questions && questions.length > 1) {
      setFieldValue("questionId1", questions[0]?.id);
      setFieldValue("questionId2", questions[1]?.id);
    }
  }, [questions]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="panel panel-header">
          <FormHeader headerTitle={t("login.forgotPassword")} />
        </div>
        {errorMessage && <ErrorMessage invertColors message={errorMessage} />}
        <div className="panel-body">
          <STText
            label={t("login.answerSecurityQuestionsDisclaimer")}
            variant="plainText"
            labelCapitalized={false}
          />
          <div style={{ width: "30rem", overflow: "hidden" }}>
            <div className="pt-4">
              <div className="mt-4 capitalize">
                <FloatField
                  id="answer1"
                  label={questions[0]?.question || ""}
                  value={values?.answer1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width="30rem"
                />
              </div>
            </div>
            <div className="pt-4">
              <div className="mt-4 capitalize">
                <FloatField
                  id="answer2"
                  label={questions[1]?.question || ""}
                  value={values?.answer2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width="30rem"
                />
              </div>
            </div>
            <div className="flex justify-content-between mt-4">
              <STButton className="px-5" type="submit" disabled={isLoading}>
                <span className={`${isLoading ? `pr-1` : ``}`}>
                  {t("login.submit")}
                </span>
                {isLoading && <span className="pi pi-spin pi-spinner" />}
              </STButton>
              <STButton
                secondary
                className="px-4"
                type="button"
                disabled={isLoading}
                onClick={() => navigate(paths.login)}
              >
                <span>{t("login.cancel")}</span>
              </STButton>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AnswerSecurityQuestionsForm;
