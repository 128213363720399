import { InputSwitchField } from "StaffTrack";
import { paths } from "Routes/Paths";
import { useAtom } from "jotai";
import { currentAssociatePageAtom, viewModeAtom } from "Context/AppGlobalState";
import { useNavigate } from "react-router-dom";
import "./styles.css";
const ServiceTeamHeaderToggle = () => {
  const [viewMode, setViewMode] = useAtom(viewModeAtom);
  const navigate = useNavigate();
  const [, setCurrentAssociatePage] = useAtom(currentAssociatePageAtom);

  return (
    <div
      className="flex align-items-center w-12rem service-team-container switch-service-team"
      id="switch"
    >
      <span className="toggle-text">Vendor</span>
      <InputSwitchField
        label=""
        labelPosition="left-center"
        checked={viewMode === "client"}
        onToggleValue={() => {
          setViewMode(viewMode === "client" ? "vendor" : "client");
          setCurrentAssociatePage(undefined);
          navigate(paths.home);
        }}
      />
      <span className="toggle-text toggle-text-right">Client</span>
    </div>
  );
};

export default ServiceTeamHeaderToggle;
