import React from "react";
import { RouteDefinition } from "Routes/Definitions/index";
import { paths } from "Routes/Paths";

const AssociateSearchParametersMode = React.lazy(
  () =>
    import(
      "Pages/Associate/AssociateSearch/ParametersMode" /*  webpackChunkName: "AssociateSearch" */
    )
);
const AssociateSearchViewMode = React.lazy(
  () =>
    import(
      "Pages/Associate/AssociateSearch/ViewMode" /*  webpackChunkName: "AssociateSearch" */
    )
);

const AssociateMain = React.lazy(
  () =>
    import(
      "Pages/Associate/AssociateMain" /*  webpackChunkName: "AssociateSearch" */
    )
);

const AssociateHistory = React.lazy(
  () =>
    import(
      "Pages/Associate/AssociateMain/AssociateHistory" /*  webpackChunkName: "AssociateHistory" */
    )
);

const AssociateEdit = React.lazy(
  () => import("Pages/Associate/Edit" /*  webpackChunkName: "AssociateEdit" */)
);

const AssociateCaarMain = React.lazy(
  () =>
    import(
      "Pages/Associate/AssociateMain/CaarMain" /*  webpackChunkName: "TicklerList" */
    )
);

const AddEditTickler = React.lazy(
  () =>
    import(
      "Pages/Associate/AssociateMain/CaarMain/AddEditTickler" /*  webpackChunkName: "AddeditTicklerList" */
    )
);

const CaarSearchAssociate = React.lazy(
  () =>
    import(
      "Pages/Associate/AssociateMain/CaarMain/DashboardAssociatesearch" /*  webpackChunkName: "CaarAssociatesearch" */
    )
);

const RecruitingAndSetup = React.lazy(
  () =>
    import(
      "Pages/Associate/RecruitingAndSetup" /*  webpackChunkName: "RecruitingAndSetup" */
    )
);

const editDocuments = React.lazy(
  () =>
    import(
      "Pages/Associate/RecruitingAndSetup/EditDocuments" /*  webpackChunkName: "EditDocuments" */
    )
);

const Payroll = React.lazy(
  () => import("Pages/Associate/Payroll" /*  webpackChunkName: "Payroll" */)
);

const Timesheet = React.lazy(
  () => import("Pages/Associate/Timesheet" /*  webpackChunkName: "Timesheet" */)
);

const { search, list } = paths.associatePaths.associateSearchAndList;
const {
  main: associateMain,
  recruitingAndSetup,
  caarMain,
} = paths.associatePaths;

const associateDefinition: RouteDefinition[] = [
  {
    component: AssociateSearchViewMode,
    path: list,
    views: ["vendor", "client"],
  },
  {
    component: AssociateSearchParametersMode,
    path: search,
    views: ["vendor", "client"],
  },
  {
    component: AssociateMain,
    path: associateMain.root,
    views: ["vendor", "client"],
  },
  {
    component: AssociateEdit,
    path: associateMain.associateEdit,
    views: ["vendor"],
  },
  {
    component: AssociateCaarMain,
    path: caarMain.ticklerList,
    views: ["client", "vendor"],
  },
  {
    component: AddEditTickler,
    path: caarMain.addEditTickler,
    views: ["client", "vendor"],
  },
  {
    component: CaarSearchAssociate,
    path: caarMain.caarSearchAssociate,
    views: ["client", "vendor"],
  },
  {
    component: AssociateHistory,
    path: associateMain.history,
    views: ["vendor"],
  },
  {
    component: RecruitingAndSetup,
    path: recruitingAndSetup.root,
    views: ["vendor"],
  },
  {
    component: editDocuments,
    path: recruitingAndSetup.editDocuments,
    views: ["vendor"],
  },
  {
    component: Payroll,
    path: paths.associatePaths.payroll.main,
    views: ["vendor"],
  },
  {
    component: Timesheet,
    path: paths.associatePaths.timesheet.main,
    views: ["client"],
  },
];
export default associateDefinition;
