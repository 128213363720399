import React from "react";
import { RouteDefinition } from "Routes/Definitions/index";
import reports from "Routes/Paths/AnalyticsAndReports";
import AssociateReportParametersMode from "Pages/AnaliticsAndReports/StandardReports/Vendor/AssociateReport/ParametersMode";

const RootPage = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports" /*  webpackChunkName: "RootReportPage" */
    )
);

const AssociateReportList = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Vendor/AssociateReport/ViewMode" /*  webpackChunkName: "AssociateReportList" */
    )
);
const ClientAssociateReport = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Client/AssociateReport" /*  webpackChunkName: "ClientAssociateReport" */
    )
);

const RosterReportPageParametersMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Vendor/RosterReport/ParametersMode" /*  webpackChunkName: "RosterReportPage" */
    )
);

const RosterReportPageViewMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Vendor/RosterReport/ViewMode" /*  webpackChunkName: "RosterReportPage" */
    )
);

const ClientRosterReportPageParametersMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Client/RosterReport/ParametersMode" /*  webpackChunkName: "ClientRosterReportPage" */
    )
);

const ClientRosterReportPageViewMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Client/RosterReport/ViewMode" /*  webpackChunkName: "ClientRosterReportPage" */
    )
);

const TimeApprovalReportPageParametersMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Vendor/TimeApprovalReport/ParametersMode" /*  webpackChunkName: "TimeApprovalReportPage" */
    )
);

const TimeApprovalReportPageViewMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Vendor/TimeApprovalReport/ViewMode" /*  webpackChunkName: "TimeApprovalReportPage" */
    )
);

const ClientTimeApprovalReportPageViewMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Client/TimeApprovalReport" /*  webpackChunkName: "ClientTimeApprovalReportPage" */
    )
);

const DocumentAuditReportPageParameterMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Vendor/DocumentAuditReport/ParametersMode" /*  webpackChunkName: "DocumentAuditReportPage" */
    )
);

const DocumentAuditReportPageViewMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Vendor/DocumentAuditReport/ViewMode" /*  webpackChunkName: "DocumentAuditReportPage" */
    )
);

const ClientDocumentAuditReportPageParameterMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Client/DocumentAuditReport/ParametersMode" /*  webpackChunkName: "DocumentAuditReportPage" */
    )
);
const ClientDocumentAuditReportPageViewMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Client/DocumentAuditReport/ViewMode" /*  webpackChunkName: "DocumentAuditReportPage" */
    )
);

const InvoiceReportPage = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Vendor/InvoiceReport/ParametersMode" /*  webpackChunkName: "InvoiceReportPage" */
    )
);

const HoursReportPageParametersMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Vendor/HoursReport/HoursReportParametersMode" /*  webpackChunkName: "HoursReportPageParametersMode" */
    )
);
const HoursReportPageClientParametersMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Client/HoursReport" /*  webpackChunkName: "HoursReportClient" */
    )
);

const HoursReportViewMode = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/StandardReports/Vendor/HoursReport/HoursReportViewMode" /*  webpackChunkName: "HoursReportViewMode" */
    )
);

const CustomReportsPage = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/CustomReports" /*  webpackChunkName: "CustomReports" */
    )
);

const CustomDashboardsPage = React.lazy(
  () =>
    import(
      "Pages/AnaliticsAndReports/CustomDashboards" /*  webpackChunkName: "CustomDashboards" */
    )
);

const { main } = reports.standardReportsPaths;

const {
  associateReports,
  rosterReports,
  timeApprovalReports,
  documentAuditReports,
  invoiceReports,
  hoursReport,
} = reports.standardReportsPaths.vendorReports;

const {
  clientTimeApprovalReports,
  hoursReportClient,
  clientDocumentAuditReports,
  clientAssociateReports,
  clientRosterReports,
} = reports.standardReportsPaths.clientReports;

const { root } = reports.customReportsPaths;

const analiticAndReportsDefinition: RouteDefinition[] = [
  {
    component: RootPage,
    path: main,
    views: ["vendor", "client"],
  },
  {
    component: AssociateReportParametersMode,
    path: associateReports.parameters,
    views: ["vendor"],
  },
  {
    component: AssociateReportList,
    path: associateReports.view,
    views: ["vendor"],
  },
  {
    component: ClientAssociateReport,
    path: clientAssociateReports.main,
    views: ["client"],
  },
  {
    component: RosterReportPageParametersMode,
    path: rosterReports.parameters,
    views: ["vendor"],
  },
  {
    component: RosterReportPageViewMode,
    path: rosterReports.view,
    views: ["vendor"],
  },
  {
    component: ClientRosterReportPageParametersMode,
    path: clientRosterReports.parameters,
    views: ["client"],
  },
  {
    component: ClientRosterReportPageViewMode,
    path: clientRosterReports.view,
    views: ["client"],
  },
  {
    component: TimeApprovalReportPageParametersMode,
    path: timeApprovalReports.parameters,
    views: ["vendor"],
  },
  {
    component: TimeApprovalReportPageViewMode,
    path: timeApprovalReports.view,
    views: ["vendor"],
  },
  {
    component: ClientTimeApprovalReportPageViewMode,
    path: clientTimeApprovalReports.main,
    views: ["client"],
  },
  {
    component: DocumentAuditReportPageParameterMode,
    path: documentAuditReports.parameters,
    views: ["vendor"],
  },
  {
    component: DocumentAuditReportPageViewMode,
    path: documentAuditReports.view,
    views: ["vendor"],
  },
  {
    component: ClientDocumentAuditReportPageParameterMode,
    path: clientDocumentAuditReports.parameters,
    views: ["client"],
  },
  {
    component: ClientDocumentAuditReportPageViewMode,
    path: clientDocumentAuditReports.view,
    views: ["client"],
  },
  {
    component: InvoiceReportPage,
    path: invoiceReports.parameters,
    views: ["vendor"],
  },
  {
    component: HoursReportPageParametersMode,
    path: hoursReport.parameters,
    views: ["vendor"],
  },
  {
    component: HoursReportViewMode,
    path: hoursReport.view,
    views: ["vendor"],
  },
  {
    component: CustomReportsPage,
    path: root,
    views: ["client"],
  },
  {
    component: HoursReportPageClientParametersMode,
    path: hoursReportClient.main,
    views: ["client"],
  },
  {
    component: CustomDashboardsPage,
    path: reports.customDashboardsPaths.root,
    views: ["client"],
  },
];
export default analiticAndReportsDefinition;
