import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import toolbarTranslationEN from "Assets/I18n/en/ToolBar/toolbar.json";
import addAssociateTranslationEN from "Assets/I18n/en/Dashboard/addAssociate.json";
import menuTranslationEN from "Assets/I18n/en/Menu/menu.json";
import commonTranslationEN from "Assets/I18n/en/Common/common.json";
import breadcrumbsTranslationEN from "Assets/I18n/en/Breadcrumbs/breadcrumbs.json";
import importResultEN from "Assets/I18n/en/Components/importResult.json";
import standardReportsEN from "Assets/I18n/en/AnaliticsAndReports/StandardReports/standardReports.json";
import associateSearchAndListEN from "Assets/I18n/en/Associates/SearchAndList/searchAndList.json";
import orderByReportParams from "Assets/I18n/en/AnaliticsAndReports/params/orderByParams.json";
import dateReportParamsEn from "Assets/I18n/en/AnaliticsAndReports/params/dateParams.json";
import importListEN from "Assets/I18n/en/Resources/ImportList/importList.json";
import hoursImportEN from "Assets/I18n/en/Dashboard/hoursImport.json";
import associateBioEN from "Assets/I18n/en/Associates/Shared/bio.json";
import associateMainEN from "Assets/I18n/en/Associates/Main/main.json";
import vendorFormsEN from "Assets/I18n/en/Resources/VendorForms/main.json";
import takeActionEN from "Assets/I18n/en/Dashboard/takeAction.json";
import associatePayrollEN from "Assets/I18n/en/Associates/Payroll/payroll.json";
import myServiceTeamEN from "Assets/I18n/en/Resources/MyServiceTeam/myServiceTeam.json";
import vendorContactInfoEN from "Assets/I18n/en/Resources/VendorContactInfo/vendorContactInfo.json";
import helpLibraryEN from "Assets/I18n/en/Dashboard/helpLibrary.json";
import recruitingAndSetupEN from "Assets/I18n/en/Associates/RecruitingAndSetup/recruitingandsetup.json";
import ordersEn from "Assets/I18n/en/Orders/orders.json";
import hoursReportEN from "Assets/I18n/en/AnaliticsAndReports/HoursReport/hoursReport.json";
import otherOfferingsEN from "Assets/I18n/en/Resources/OtherOfferings/otherOfferings.json";
import recruitingServicesEN from "Assets/I18n/en/Resources/RecruitingServices/recruitingServices.json";
import sessionTimeOutEN from "Assets/I18n/en/Components/sessionTimeOut.json";
import orderWorkersEN from "Assets/I18n/en/OrderWorkers/orderWorkers.json";
import customReportsEN from "Assets/I18n/en/AnaliticsAndReports/CustomReports/customReports.json";
import timeApprovalReportsEN from "Assets/I18n/en/AnaliticsAndReports/TimeApprovalReports/timeApprovalReports.json";
import approveHoursEN from "Assets/I18n/en/Dashboard/approveHours.json";
import associateReportEN from "Assets/I18n/en/AnaliticsAndReports/StandardReports/associateReport.json";
import documentAuditReportEN from "Assets/I18n/en/AnaliticsAndReports/StandardReports/documentAuditReport.json";
import clientDashboardEN from "Assets/I18n/en/Dashboard/clientDashboard.json";
import rosterReportEN from "Assets/I18n/en/AnaliticsAndReports/StandardReports/rosterReport.json";
import associateCaarMainEN from "Assets/I18n/en/Associates/CaarMain/caarmain.json";
import workLocationCodesEN from "Assets/I18n/en/Dashboard/workLocationCodes.json";
import requisitionEN from "Assets/I18n/en/OrderWorkers/requisition.json";
import customDashboardsEN from "Assets/I18n/en/AnaliticsAndReports/CustomDashboards/customDashboards.json";
import invoicesEn from "Assets/I18n/en/Dashboard/invoices.json";

const resources = {
  en: {
    toolbar: toolbarTranslationEN,
    addAssociate: addAssociateTranslationEN,
    menu: menuTranslationEN,
    common: commonTranslationEN,
    breadcrumbs: breadcrumbsTranslationEN,
    importResult: importResultEN,
    standardReports: standardReportsEN,
    associateSearchAndList: associateSearchAndListEN,
    orderByReportParams: orderByReportParams,
    dateReportParams: dateReportParamsEn,
    importList: importListEN,
    hoursImport: hoursImportEN,
    associateBio: associateBioEN,
    associateMain: associateMainEN,
    vendorForms: vendorFormsEN,
    takeAction: takeActionEN,
    myServiceTeam: myServiceTeamEN,
    helpLibrary: helpLibraryEN,
    recruitingAndSetup: recruitingAndSetupEN,
    associatePayroll: associatePayrollEN,
    vendorContactInfo: vendorContactInfoEN,
    hoursReport: hoursReportEN,
    orders: ordersEn,
    otherOfferings: otherOfferingsEN,
    recruitingServices: recruitingServicesEN,
    sessionTimeOut: sessionTimeOutEN,
    orderWorkers: orderWorkersEN,
    customReports: customReportsEN,
    timeApprovalReports: timeApprovalReportsEN,
    approveHours: approveHoursEN,
    associateReport: associateReportEN,
    documentAuditReport: documentAuditReportEN,
    clientDashboard: clientDashboardEN,
    caarAssociateMain: associateCaarMainEN,
    rosterReport: rosterReportEN,
    workLocationCodes: workLocationCodesEN,
    requisition: requisitionEN,
    customDashboards: customDashboardsEN,
    invoices: invoicesEn,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: {
    default: ["en"],
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
