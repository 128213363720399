import { ChangePasswordRequest } from "Api/StaffTrack/Auth/Models/changePasswordRequest";

export interface ChangePasswordFullForm extends ChangePasswordRequest {
  confirmPassword: string;
}
export const changePasswordValues: ChangePasswordFullForm = {
  newPassword: "",
  currentPassword: "",
  confirmPassword: "",
};
