import { STButton } from "StaffTrack";
import {
  ErrorWrapper,
  ErrorContainer,
  ErrorName,
  ErrorDescription,
} from "./styles";

const redirect = () => {
  window.location.pathname = "/mystn2";
};

const ErrorUnknown = () => (
  <ErrorWrapper>
    <ErrorContainer>
      <ErrorName>
        <p className="font-bold">ERROR UNDEFINED</p>
      </ErrorName>
    </ErrorContainer>
    <ErrorContainer>
      <ErrorDescription>
        <p className="font-bold">Oops, something went wrong</p>
      </ErrorDescription>
    </ErrorContainer>
    <ErrorContainer>
      <STButton onClick={redirect}>Take me to homepage</STButton>
    </ErrorContainer>
  </ErrorWrapper>
);

export default ErrorUnknown;
