import styled from "styled-components";
import { useAppContext } from "Context/AppContext";
import { useMobile } from "App/media";
import { useEffect } from "react";

const Div = styled.div`
  display: block;
  position: relative;
  top: 4px;
  left: 1px;
  padding-right: 1rem;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;

  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    z-index: 2;

    -webkit-touch-callout: none;
  }

  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #69b3e7;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  input:checked ~ span {
    opacity: 1;
    background: white;
  }
`;
const HamburgerButton = () => {
  const { setIsMenuOpen, isMenuOpen } = useAppContext();
  const { isMobile } = useMobile();
  useEffect(() => {
    if (isMobile) setIsMenuOpen(false);
  }, [isMobile, setIsMenuOpen]);
  return (
    <Div
      id="menuToggle"
      role="button"
      onClick={() => {
        setIsMenuOpen(!isMenuOpen);
      }}
    >
      <input type="checkbox" checked={isMenuOpen} />
      <span />
      <span />
      <span />
    </Div>
  );
};

export default HamburgerButton;
