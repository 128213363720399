import React from "react";
import { commonPaths } from "Routes/Paths/Common/common";
import { RouteDefinition } from "Routes/Definitions/index";

const Home = React.lazy(
  () => import("Pages/Home" /*  webpackChunkName: "Home" */)
);

const { home } = commonPaths;

const commonDefinitions: RouteDefinition[] = [
  {
    component: Home,
    path: home,
    views: ["client", "vendor"],
  },
];

export default commonDefinitions;
