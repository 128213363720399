import { useAppContext } from "Context/AppContext";
import { useEffect } from "react";
import { useCurrentOffice, useUserOffices } from "Api/Hooks/User";
import { useUserBasicInfo } from "Api/Hooks/Auth";
import { useAtom } from "jotai";
import { serviceTeamAtom, viewModeAtom } from "Context/AppGlobalState";

const officeQuery = "officeId";

const urlParams = new URLSearchParams(window.location.search);

export const useSyncURLParams = () => {
  const { currentOffice, setCurrentOffice } = useAppContext();
  const officeIdParam = urlParams.get("officeId");

  const { data: currentOfficeApi } = useCurrentOffice();
  const { data: userOffices } = useUserOffices();
  const { data: baseUserInfo } = useUserBasicInfo();
  const [viewMode, setViewMode] = useAtom(viewModeAtom);
  const [serviceTeam, setServiceTeam] = useAtom(serviceTeamAtom);

  useEffect(() => {
    if (!baseUserInfo || viewMode || serviceTeam) return;
    setServiceTeam(baseUserInfo.mySTNUserType === "serviceTeam");
    setViewMode(baseUserInfo.mySTNUserType === "client" ? "client" : "vendor");
  }, [baseUserInfo]);

  useEffect(() => {
    const office = currentOfficeApi?.data;
    if (!office) return;

    if (
      !currentOffice &&
      userOffices &&
      userOffices.data &&
      userOffices.data.length > 0
    ) {
      if (userOffices.data.find((o) => o.officeId === office.officeId)) {
        setCurrentOffice(office);
      } else {
        setCurrentOffice(userOffices.data[0]);
      }
    }

    if (!officeIdParam) {
      if (currentOffice?.officeId)
        urlParams.set(officeQuery, currentOffice.officeId.toString());
      else if (office?.officeId)
        urlParams.set(officeQuery, office.officeId.toString());
    }
  }, [currentOfficeApi, officeIdParam, currentOffice, userOffices]);
};
export const useAppURLParams = () => {
  const changeOfficeIdURL = (newOfficeID: string) => {
    urlParams.set(officeQuery, newOfficeID);
  };
  return {
    changeOfficeIdURL,
  };
};
