export class HttpException extends Error {
  constructor(message: string, status: number, messages: string[]) {
    super(messages[0]);
    this.status = status;
    this.messages = messages;
  }

  status: number;

  messages: string[];
}
