import { useMutation, useQuery } from "react-query";
import { cacheQuery } from "Api/Common/Query";
import { APIResult } from "Types/Model";
import { useStApi } from "Api/Common/UseStApi";
import { stringifyURL } from "StaffTrack";
import { Endpoints } from "Api/Hooks/Common/endpoints";
import { SessionConfigDTO } from "Api/StaffTrack/Common/Models/sessionConfigDto";
import { SchedWeekDTO } from "Api/StaffTrack/Resources/Models/schedWeekDto";
import { VersionDTO } from "Api/StaffTrack/Common/Models/versionDto";
import { SystemSettingsDTO } from "Api/StaffTrack/Common/Models/systemSettingsDto";

export const useVersion = () => {
  const { get } = useStApi({ throwExceptionOnStatus: [401] });
  return useQuery<APIResult<VersionDTO>>(
    "version",
    () => get(Endpoints.api.common.settings.version),
    {
      ...cacheQuery,
      retry: false,
      useErrorBoundary: false,
    }
  );
};

export const useSessionConfig = (officeId: number | undefined) => {
  const { get } = useStApi({ throwExceptionOnStatus: [403] });
  return useQuery(
    ["sessionConfig", officeId],
    () =>
      get<APIResult<SessionConfigDTO>>(
        stringifyURL(Endpoints.api.common.settings.sessionConfig, {
          queryStringParams: { officeId },
        })
      ).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err.message);
      }),
    {
      ...cacheQuery,
    }
  );
};

export const useGetAllWeeks = () => {
  const { get } = useStApi();
  return useQuery<APIResult<SchedWeekDTO[]>>("GetAllWeeks", () =>
    get(Endpoints.api.common.vendors.weekList.list)
  );
};

export const useGetTransmitWeeks = () => {
  const { get } = useStApi();
  return useQuery<APIResult<SchedWeekDTO[]>>(
    "GetTransmitWeeks",
    () => get(Endpoints.api.common.vendors.weekList.transmitweekList),
    { ...cacheQuery }
  );
};

export const useGetSystemSetting = (ss: string) => {
  const { get } = useStApi();
  return useQuery<APIResult<SystemSettingsDTO>>(
    ["SystemSetting", ss],
    () =>
      get(
        stringifyURL(Endpoints.api.common.settings.systemSetting, {
          pathParams: { ss },
        })
      ),
    { ...cacheQuery }
  );
};
export const usePingApi = () => {
  const { get } = useStApi();
  return useMutation(() => get(Endpoints.api.common.settings.version));
};
