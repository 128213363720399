import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 0.875rem;
  text-wrap: none;
  flex-wrap: nowrap;
  width: fit-content;
  color: ${({ theme }) => theme.primaryColor};
`;

export const StyledExternalLink = styled.a`
  text-decoration: none;
  font-size: 0.875rem;
  text-wrap: none;
  flex-wrap: nowrap;
  width: fit-content;
  color: ${({ theme }) => theme.primaryColor};
`;
