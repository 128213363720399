const SecuredResources = {
  ASSOCIATE_CURRENT_ATTENDANCE: 88,
  ATTENDANCE_DATA_IMPORT: 144,
  MYSTN2_RESOURCES: 612,
  MYSTN2_ASSOCIATES: 611,
  MYSTN2_ANALYTICS_REPORTING: 610,
  MYSTN2_ORDER_WORKERS: 609,
  MYSTN2_DASHBOARD: 608,
  VENDOR_ATTACH_RESOURCE: 353,
  MYSTN2_IMPORT_LIST: 636,
  MYSTN2_IMPORT_RESULT: 637,
  SITE_ROSTER_REPORT: 153,
  MYSTN2_ASSOCIATE_SEARCH: 633,
  MYSTN2_ASSOCIATE_MAIN: 634,
  MYSTN2_ASSOCIATE_EDIT: 635,
  MYSTN2_ASSOCIATE_REPORT: 638,
  OFFICE_HOURS_APPRL_REPORT: 480,
  MTSTN_DOCUMENT_AUDIT_REPORT: 640,
  MYSTN2_VENDOR_FORMS: 648,
  MYSTN2_TAKE_ACTION: 650,
  MYSTN2_ASSOCIATE_PAYROLL: 654,
  MYSTN2_ORDERS: 653,
  MYSTN2_HELP_LIBRARY: 652,
  MYSTN2_ASSOC_REC_SETUP: 655,
  MYSTN2_V_MY_SERV_TEAM: 657,
  MYSTN_ROSTER_REPORT: 659,
  MYSTN_TIMEAPPROVAL_REPORT: 660,
  MYSTN_INVOICE_REPORT: 661,
  MYSTN_HOURS_REPORT: 662,
  MYSTN2_ADD_DOCUMENTS: 665,
  MYSTN2_C_MY_SERV_TEAM: 666,
  MYSTN2_OTHER_OFFERINGS: 668,
  MYSTN2_RECRUITING_SERVICES: 669,
  MYSTN_CUSTOM_REPORTS: 673,
  MYSTN2_CLIENT_ASSOCIATE_SEARCH: 672,
  MYSTN2_CLIENT_ASSOCIATE_MAIN: 673,
  MYSTN2_CLIENT_CAAR_MAIN: 674,
  MYSTN2_WORK_LOCATION_CODES: 670,
  MYSTN_CLIENT_ROSTER_RPT: 678,
  ORDERS_AND_START_SESSION: 347, //existing resource from ZK
  MYSTN_RESOURCES: Number.MAX_SAFE_INTEGER,
  MYSTN_DASHBOARD: Number.MAX_SAFE_INTEGER,
  MYSTN2_APPROVE_HOURS: 679,
  MYSTN2_CUSTOM_DASHBOARD: 682,
  OFFICE_INVOICE: 686,
  NO_SECURITY: Number.MAX_SAFE_INTEGER,
};

const SystemOperations = {
  VIEW_CONTENT: 0,
  ADD_NEW: 1,
  EDIT: 2,
  ACTIVATE_DEACTIVATE: 3,
  DELETE: 4,
  PRINT: 5,
};

const PublicResource = {
  resource: SecuredResources.NO_SECURITY,
  systemOperation: SystemOperations.VIEW_CONTENT,
};

export const PrivateResource = {
  resource: Number.MAX_SAFE_INTEGER,
  systemOperation: Number.MAX_SAFE_INTEGER,
};

export { SecuredResources };

export { SystemOperations };

export { PublicResource };
