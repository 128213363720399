import { ToastOptions } from "react-toastify";

export const toastDefaults: ToastOptions = {
  position: "top-right",
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  progress: undefined,
  autoClose: 1000,
};
