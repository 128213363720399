import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;
  line-height: 1.5rem;
  width: 29rem;
`;

export const FieldLabel = styled.span`
  padding-left: 1rem;
  flex-basis: 40%;
`;

export const ContactFieldContainer = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
`;
