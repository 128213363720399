import { STBreadCrumb } from "Types/Breadcrumb";
import { paths } from "Routes/Paths";

export const ordersBreadcrumbs: STBreadCrumb[] = [
  {
    title: "Orders",
    url: paths.orders.openOrders.main,
    external: false,
    menuId: 22,
    translationResource: "breadcrumbs:orders.orders",
  },
  {
    title: "OpenOrders",
    url: paths.orders.openOrders.main,
    external: false,
    translationResource: "breadcrumbs:orders.openOrders",
  },
  {
    title: "OrdersAddExisting",
    url: paths.orders.openOrders.addExistingAssociate,
    external: false,
    translationResource: "breadcrumbs:orders.addExisting",
  },
  {
    title: "OrdersAddManual",
    url: paths.orders.openOrders.addSingleAssociate,
    external: false,
    translationResource: "breadcrumbs:orders.addManual",
  },
  {
    title: "OrdersAddUpload",
    url: paths.orders.openOrders.addMultiplessociates,
    external: false,
    translationResource: "breadcrumbs:orders.addUpload",
  },
  {
    title: "PendingOrders",
    url: paths.orders.pendingOrders.main,
    external: false,
    translationResource: "breadcrumbs:orders.pendingOrders",
    menuId: 22,
  },
  {
    title: "OrderWorks",
    url: paths.orders.pendingOrders.main,
    external: false,
    translationResource: "breadcrumbs:orders.orderWorks",
    menuId: 22,
  },
];
