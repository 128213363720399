import { useUserBasicInfo } from "Api/Hooks/Auth";
import { useMemo } from "react";
import { PublicResource } from "Constants/Security";

// The SystemOperationIds object is used to map the operation id to the actual value
const SystemOperationIds = {
  0: 1,
  1: 2,
  2: 4,
  3: 8,
  4: 16,
  5: 32,
};
const useResourceSecurity = (
  resourceId: number | undefined,
  operationId: number | undefined
) => {
  const { data: basicUserInfoData } = useUserBasicInfo();
  return useMemo(() => {
    if (!basicUserInfoData) return true;
    const basicUserInfo = basicUserInfoData;
    if (resourceId === PublicResource.resource) return true;
    if (!basicUserInfo || !basicUserInfo || !basicUserInfo.userRights)
      return false;
    if (resourceId === undefined || operationId === undefined) return true;
    try {
      const resourceRights = basicUserInfo.userRights[resourceId];
      return (SystemOperationIds[operationId] & resourceRights) > 0;
    } catch (err) {
      // Resources right does not exist or user has no rights
      return false;
    }
  }, [operationId, resourceId, basicUserInfoData]);
};

export default useResourceSecurity;
