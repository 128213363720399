import Error500 from "Pages/Error/Error500";
import Error404 from "Pages/Error/Error404";
import Error403 from "Pages/Error/Error403";
import ErrorUnknown from "Pages/Error/Unknown";

const DefaultFallBack = () => <ErrorUnknown />;

export interface ErrorFallbackProps {
  status: number;
  statusText: string;
  internal: boolean;
  data: string;
  error: {};
}

const ErrorFallback: React.FC<ErrorFallbackProps> = (props) => {
  // @ts-ignore: Property 'response' does not exist on type 'Error'
  switch (props.status) {
    case 500:
      return <Error500 />;
    case 404:
      return <Error404 />;
    case 403:
      return <Error403 />;
    default:
      return <DefaultFallBack />;
  }
};

export default ErrorFallback;
