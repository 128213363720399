import {
  addEmptyState,
  ErrorMessage,
  InputTextField,
  STButton,
  STSelect,
  stSetFieldValue,
  STText,
} from "StaffTrack";
import { FC, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import FormHeader from "Templates/Headers/PanelHeaders/FormHeader";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import { useForgePwdQuestions } from "Api/Hooks/Auth";
import { ResetPasswordFullForm } from "Pages/Login/Forms/ResetPassword/Logic/resetPasswordFormValues";

type Props = {
  isLoading: boolean;
  errorMessage: string;
};
const AddSecurityQuestionsForm: FC<Props> = ({ isLoading, errorMessage }) => {
  const { values, handleChange, setFieldValue, handleSubmit, handleBlur } =
    useFormikContext<ResetPasswordFullForm>();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { data: questionsData } = useForgePwdQuestions();

  const allQuestions = useMemo(() => {
    if (!questionsData?.data) return [];
    return addEmptyState(
      questionsData.data.map((question) => ({
        text: question.question || "",
        name: question.id,
      })),
      "",
      "0"
    );
  }, [questionsData]);

  const questions1 = useMemo(() => {
    if (!allQuestions) return [];
    return allQuestions.filter(
      (question) =>
        (question.name != values?.questionId2 &&
          question.name != values?.questionId3) ||
        question?.name == 0
    );
  }, [allQuestions, values?.questionId2, values?.questionId3]);

  const questions2 = useMemo(() => {
    if (!allQuestions) return [];
    return allQuestions.filter(
      (question) =>
        (question.name != values?.questionId1 &&
          question.name != values?.questionId3) ||
        question?.name == 0
    );
  }, [allQuestions, values?.questionId1, values?.questionId3]);

  const questions3 = useMemo(() => {
    if (!allQuestions) return [];
    return allQuestions.filter(
      (question) =>
        (question.name != values?.questionId1 &&
          question.name != values?.questionId2) ||
        question?.name == 0
    );
  }, [allQuestions, values?.questionId1, values?.questionId2]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="panel panel-header">
          <FormHeader headerTitle={t("login.securityQuestions")} />
        </div>
        {errorMessage && <ErrorMessage invertColors message={errorMessage} />}
        <div className="panel-body">
          <STText
            label={t("login.securityQuestionsDisclaimer")}
            variant="plainText"
            labelCapitalized={false}
          />
          <div style={{ width: "30rem", overflow: "hidden" }}>
            <div className="pt-4">
              <STSelect
                id="question1"
                label={`${t("question")} 1`}
                items={questions1}
                labelPosition="left-center"
                labelWidth="9rem"
                showLabelRequired
                width="23rem"
                value={values?.questionId1}
                onValueChange={(id) => {
                  stSetFieldValue(setFieldValue, "questionId1", parseInt(id));
                }}
              />
              <div className="capitalize">
                <InputTextField
                  id="answer1"
                  label={`${t("Answer")}`}
                  value={values?.answer1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelPosition="left-center"
                  labelWidth="7rem"
                  fieldWidth="25rem"
                />
              </div>
            </div>
            <div className="pt-4">
              <STSelect
                id="question2"
                label={`${t("question")} 2`}
                items={questions2}
                labelPosition="left-center"
                labelWidth="9rem"
                showLabelRequired
                width="23rem"
                value={values?.questionId2}
                onValueChange={(id) => {
                  stSetFieldValue(setFieldValue, "questionId2", parseInt(id));
                }}
              />
              <div className="capitalize">
                <InputTextField
                  id="answer2"
                  label={`${t("Answer")}`}
                  value={values?.answer2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelPosition="left-center"
                  labelWidth="7rem"
                  fieldWidth="25rem"
                />
              </div>
            </div>
            <div className="pt-4">
              <STSelect
                id="question3"
                label={`${t("question")} 3`}
                items={questions3}
                labelPosition="left-center"
                labelWidth="9rem"
                showLabelRequired
                width="23rem"
                value={values?.questionId3}
                onValueChange={(id) => {
                  stSetFieldValue(setFieldValue, "questionId3", parseInt(id));
                }}
              />
              <div className="capitalize">
                <InputTextField
                  labelPosition="left-center"
                  id="answer3"
                  label={`${t("Answer")}`}
                  value={values?.answer3}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelWidth="7rem"
                  fieldWidth="25rem"
                />
              </div>
            </div>
            <div className="flex justify-content-between mt-4">
              <STButton className="px-5" type="submit" disabled={isLoading}>
                <span className={`${isLoading ? `pr-1` : ``}`}>
                  {t("login.submit")}
                </span>
                {isLoading && <span className="pi pi-spin pi-spinner" />}
              </STButton>
              <STButton
                secondary
                className="px-4"
                type="button"
                disabled={isLoading}
                onClick={() => navigate(paths.login)}
              >
                <span>{t("login.cancel")}</span>
              </STButton>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddSecurityQuestionsForm;
