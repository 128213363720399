import { STBreadCrumb } from "Types/Breadcrumb";
import { paths } from "Routes/Paths";

export const associateBreadcrumbs: STBreadCrumb[] = [
  {
    title: "Associate",
    url: paths.associatePaths.associateSearchAndList.search,
    external: false,
    translationResource: "breadcrumbs:associates.associate",
    menuId: 4,
  },
  {
    title: "Associates",
    url: paths.associatePaths.associateSearchAndList.search,
    external: false,
    translationResource: "breadcrumbs:associates.associates",
    menuId: 4,
  },
  {
    title: "Associatesearch",
    url: paths.associatePaths.associateSearchAndList.search,
    external: false,
    translationResource: "breadcrumbs:associates.associatesearch",
    subMenuId: 18,
  },
  {
    title: "AssociateSearchResults",
    url: paths.associatePaths.associateSearchAndList.list,
    external: false,
    translationResource: "breadcrumbs:associates.searchResults",
    subMenuId: 18,
  },
  {
    title: "EditAssociate",
    url: paths.associatePaths.main.associateEdit,
    external: false,
    translationResource: "breadcrumbs:associates.associateEdit",
  },
  {
    title: "AssociateMain",
    url: paths.associatePaths.main.root,
    external: false,
    translationResource: "breadcrumbs:associates.associateMain",
    subMenuId: 28,
  },
  {
    title: "AttendanceHistory",
    url: paths.associatePaths.main.history,
    external: false,
    translationResource: "breadcrumbs:associates.AttendanceHistory",
    subMenuId: 28,
  },
  {
    title: "ActionRequest",
    url: paths.associatePaths.caarMain.ticklerList,
    external: false,
    translationResource: "breadcrumbs:associates.ActionRequest",
    subMenuId: 28,
  },
  {
    title: "AddEditActionRequest",
    url: paths.associatePaths.caarMain.addEditTickler,
    external: false,
    translationResource: "breadcrumbs:associates.AddEditActionRequest",
    subMenuId: 28,
  },
  {
    title: "Payroll",
    url: paths.associatePaths.payroll.main,
    external: false,
    translationResource: "breadcrumbs:associates.payroll",
  },
  {
    title: "RecruitingAndSetup",
    url: paths.associatePaths.recruitingAndSetup.root,
    external: false,
    translationResource: "breadcrumbs:associates.RecruitingAndSetup",
  },
  {
    title: "RequiredHRDocs",
    url: paths.associatePaths.recruitingAndSetup.editDocuments,
    external: false,
    translationResource: "breadcrumbs:associates.RequiredHRDocs",
  },
  {
    title: "Timesheet",
    url: paths.associatePaths.timesheet.main,
    external: false,
    translationResource: "breadcrumbs:associates.timesheet",
  },
];
