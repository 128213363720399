import styled from "styled-components";
import { mobileQuery } from "App/media";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #e0e5ee;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const AppBody = styled.main`
  display: flex;
  flex-basis: 100%;
  overflow-x: hidden;
`;

export type SideBarProps = {
  opened?: boolean;
};

export const SideBar = styled.aside<SideBarProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: ${({ opened }) => (opened ? `13.125rem` : 0)};
  transition: 0.3s ease-in-out;

  @media ${mobileQuery} {
    position: absolute;
    background-color: white;
    height: 100vh;
    width: ${({ opened }) => (opened ? `100vw` : 0)};
    z-index: 10000;
  }
`;

type FullBodyProps = {
  isMenuOpen: boolean;
};

export const FullBodyContainer = styled.div<FullBodyProps>`
  width: ${({ isMenuOpen }) =>
    isMenuOpen ? `calc(100vw - 11.875rem)` : `100%`};
`;
