import FooterLogo from "Assets/Logos/logo_mySTN_color_reverse_light.svg";
import styled from "styled-components";
import { useAppContext } from "Context/AppContext";
import { Tooltip } from "primereact/tooltip";
import { useVersion } from "Api/Hooks/Common";
import { VERSION } from "Constants/Environment";

type ContainerProps = {
  isMenuOpen: boolean;
};
const FooterContainer = styled.nav<ContainerProps>`
  border-top: 0 solid var(--gray-400);
`;

const FooterLogin = () => {
  const { isMenuOpen } = useAppContext();
  const { data } = useVersion();
  return (
    <FooterContainer
      isMenuOpen={isMenuOpen}
      className="flex flex-column align-items-center pb-3 mx-4"
    >
      <div className="h-3rem pt-3 ">
        <FooterLogo style={{ width: "100px", height: "32px" }} />
      </div>
      <p
        className="text-sm text-white m-0 pt-2 message text-center"
        data-pr-tooltip={`${data?.data.toolTipContent} ${(
          VERSION || ""
        ).replace(".0", "")} Db: ${data?.data.dbVersion}`}
      >
        © 2023 Staff Management | SMX, a TrueBlue company <br />
        Confidential & Proprietary • All Rights Reserved • Version{" "}
        {data?.data.softwareVersion}
      </p>
      <Tooltip target=".message" className="text-xs" position="top" />
    </FooterContainer>
  );
};

export default FooterLogin;
