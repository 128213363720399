import React, { FC } from "react";
import { SecuredResource } from "Types/Security";
import useResourceSecurity from "Lib/UserSecurity";
import { useAtom } from "jotai";
import { formModeAtom } from "Context/AppGlobalState";
import { SystemOperations } from "Constants/Security";

interface Props {
  securityInfo?: SecuredResource;
  children: React.ReactNode;
  disabled?: boolean;
}
const SecuredAction: FC<Props> = ({
  children,
  securityInfo,
  disabled = false,
}) => {
  const [formMode] = useAtom(formModeAtom);
  const calculatedSecurityInfo =
    formMode === "add" ? SystemOperations.ADD_NEW : SystemOperations.EDIT;
  const actualSystemOperation =
    formMode === "none" ||
    securityInfo?.systemOperation === SystemOperations.VIEW_CONTENT ||
    securityInfo?.systemOperation === SystemOperations.DELETE ||
    securityInfo?.systemOperation === SystemOperations.ACTIVATE_DEACTIVATE ||
    securityInfo?.systemOperation === SystemOperations.PRINT
      ? securityInfo?.systemOperation
      : calculatedSecurityInfo;

  const hasAccess =
    useResourceSecurity(securityInfo?.resource, actualSystemOperation) &&
    !disabled;
  return (
    <>
      {React.Children.map(children, (c) =>
        React.cloneElement(c as React.ReactElement, {
          hasAccess,
        })
      )}
    </>
  );
};

export default SecuredAction;
