import { include } from "named-urls";

export const commonPaths = {
  home: "/",
  login: "/login",
  error: include("/error", {
    unknown: "unknown",
    notFound: "404",
    forbidden: "403",
    serverError: "500",
  }),
};
