import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { invariant } from "ts-invariant";

export const useQueryParams = () => {
  const { search } = useLocation();

  const searchObject = useMemo(() => new URLSearchParams(search), [search]);
  return {
    getUnsafeNullValue: (item): string | null => searchObject.get(item),
    getUnsafeNullArray: (item): string[] | null => searchObject.getAll(item),
    get: (item): string => {
      const result = searchObject.get(item);
      invariant(result !== null, `URI Parameter ${item} is empty`);
      return result;
    },
    getNumber: (item): number => {
      const result = searchObject.get(item);
      invariant(result !== null, `URI Parameter ${item} is empty`);
      return Number(result);
    },
    getBoolean: (item): boolean => {
      const result = searchObject.get(item);
      invariant(result !== null, `URI Parameter ${item} is empty`);
      return result.toUpperCase() === "TRUE";
    },
  };
};
