import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { HttpException } from "Types/HTTPException";
import { sanitizeObject } from "StaffTrack";
import { useAtom } from "jotai";
import { excludedQueriesAtom } from "Api/Common/UseStApi/Atoms";
import { API_DEBUG_MODE } from "Constants/Environment";

export type APIOptions = {
  baseUrl?: string;
  apiHeaderOverrides?: Record<string, string>;
  multipart?: boolean;
  throwExceptionOnStatus?: number[];
  showDefaultText?: boolean;
  messageWhenOk?: string;
  allowHTML?: boolean;
  excludeKey?: string;
};

export type AppLevelAPIOptions = {
  onUnhandledErrorStatus: (
    status: number,
    method: string,
    errorsList: Array<string>
  ) => void;
  setLastApiCall: (dt: Date) => void;
  /* Error message to throw when API don't return anything */
  defaultErrorMessage: string;
  defaultMessagesWhenOk: {
    onGet: string;
    onPost: string;
    onPut: string;
    onDelete: string;
  };
  basePath: string;
};

export function useBaseSTApi(
  appLevelOptions: AppLevelAPIOptions,
  options?: APIOptions
) {
  const baseUrl =
    options && options.baseUrl ? options.baseUrl : appLevelOptions.basePath;
  const apiHeaderOverrides =
    options && options.apiHeaderOverrides
      ? options.apiHeaderOverrides
      : undefined;
  const multipart = options && options.multipart;
  const throwExceptionOnStatus = useMemo(
    () =>
      options && options.throwExceptionOnStatus
        ? options.throwExceptionOnStatus
        : [],
    [options]
  );
  const [, setExcludedQueries] = useAtom(excludedQueriesAtom);

  const generateHeaders = useCallback(
    async (headerOverrides: Record<string, string> = {}) => {
      const headers: Record<string, string> = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      if (multipart) return apiHeaderOverrides || headerOverrides || {};

      Object.assign(headers, apiHeaderOverrides, headerOverrides);

      return headers;
    },
    [apiHeaderOverrides]
  );

  const throwExceptionRequested = useCallback(
    (status: number, errorList?: string[]) => {
      if (throwExceptionOnStatus.some((item) => item === status)) {
        throw new HttpException(
          errorList && errorList.length > 0
            ? errorList[0]
            : appLevelOptions.defaultErrorMessage,
          status,
          errorList || []
        );
      }
    },
    [throwExceptionOnStatus]
  );

  const showSuccessMessages = useCallback(
    (method: string) => {
      switch (method) {
        case "GET":
          toast.success(
            options?.messageWhenOk ||
              appLevelOptions.defaultMessagesWhenOk.onGet
          );
          break;
        case "POST":
          toast.success(
            options?.messageWhenOk ||
              appLevelOptions.defaultMessagesWhenOk.onPost
          );
          break;
        case "PUT":
          toast.success(
            options?.messageWhenOk ||
              appLevelOptions.defaultMessagesWhenOk.onPut
          );
          break;
        case "DELETE":
          toast.success(
            options?.messageWhenOk ||
              appLevelOptions.defaultMessagesWhenOk.onDelete
          );
          break;
      }
    },
    [appLevelOptions]
  );

  const fetchFromSTApi = useCallback(
    async <T>(
      method: string,
      path: string,
      body: RequestInit["body"] = undefined,
      headerOverrides: Record<string, string> = {}
    ) => {
      if (options?.excludeKey) {
        setExcludedQueries((prev) => {
          const newSet = new Set(prev);
          newSet.add(options.excludeKey as string);
          return newSet;
        });
      }

      const lastCall = new Date();
      appLevelOptions.setLastApiCall(lastCall);
      const [headers] = await Promise.all([generateHeaders(headerOverrides)]);
      const url = `${baseUrl}/${path}`;
      if (API_DEBUG_MODE) {
        // eslint-disable-next-line no-console
        console.info(`API Request made to ${url}`);
      }
      const response = await fetch(url, {
        method: method || "GET",
        credentials: "include",
        headers,
        body,
      });
      let bodyResponse;
      switch (response.headers.get("content-type")) {
        case "text/html":
        case "text/plain;charset=ISO-8859-1":
          bodyResponse = await Promise.resolve(response.text());
          break;
        case "application/vnd.ms-excel":
        case "application/pdf":
        case "application/msword":
          bodyResponse = await response.blob();
          break;
        case "application/json":
          bodyResponse = await response.json();
          break;
        default:
          bodyResponse = response;
          break;
      }
      if (typeof bodyResponse === "object") {
        if (!multipart)
          sanitizeObject(bodyResponse, options?.allowHTML || false);
      }

      throwExceptionRequested(response.status, bodyResponse.errorsList);

      if (response.status > 299) {
        appLevelOptions.onUnhandledErrorStatus(
          response.status,
          method,
          bodyResponse.errorsList
        );
        return bodyResponse;
      }

      if (!response.ok) {
        throw bodyResponse;
      }

      if ((response.ok && options?.showDefaultText) || options?.messageWhenOk) {
        showSuccessMessages(method);
      }

      return bodyResponse as Promise<T>;
    },
    [baseUrl, generateHeaders, history, throwExceptionRequested]
  );

  return useMemo(
    () => ({
      del: async <Tout>(
        path: string,
        headerOverrides: Record<string, string> = {}
      ) => fetchFromSTApi<Tout>("DELETE", path, undefined, headerOverrides),

      get: async <Tout>(
        path: string,
        headerOverrides: Record<string, string> = {}
      ) => fetchFromSTApi<Tout>("GET", path, undefined, headerOverrides),

      patch: async <Tout>(
        path: string,
        body: string,
        headerOverrides: Record<string, string> = {}
      ) => fetchFromSTApi<Tout>("PATCH", path, body, headerOverrides),

      post: async <Tout>(
        path: string,
        body: string | FormData,
        headerOverrides: Record<string, string> = {}
      ) => fetchFromSTApi<Tout>("POST", path, body, headerOverrides),

      put: async <Tout>(
        path: string,
        body: string | FormData | undefined,
        headerOverrides: Record<string, string> = {}
      ) => fetchFromSTApi<Tout>("PUT", path, body, headerOverrides),
    }),
    [fetchFromSTApi]
  );
}
