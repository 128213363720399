import { createElement } from "react";
import { RouteDefinition } from "Routes/Definitions";
import withAccessControl from "Routes/Logic/withAccessControl";

export const createRouteElementFromReactType = (
  route: RouteDefinition,
  isPublic: boolean
) => {
  if (!route.element && !route.component)
    throw Error("Route needs at least one component or element");
  return {
    ...route,
    element: route.component
      ? createElement(
          isPublic
            ? route.component
            : withAccessControl(route.component, route.views)
        )
      : route.element,
  };
};
