import { Modal } from "StaffTrack";
import { STButton } from "StaffTrack";
import { useAppContext } from "Context/AppContext";
import TitleHeader from "Templates/Headers/PanelHeaders/TitleHeader";
import { Panel } from "primereact/panel";
import ReactDOM from "react-dom";
import {
  ButtonContainer,
  Container,
  ContactFieldContainer,
  FieldLabel,
} from "./styles";

const ContactHelpModal = () => {
  const { setIsHelpModalOpen } = useAppContext();

  const element = document.getElementById("appToolBar");
  if (!element) return null;
  return ReactDOM.createPortal(
    <Modal visible positionPriority="high">
      <Panel
        headerTemplate={
          <TitleHeader
            title="Contact Help"
            onClose={() => setIsHelpModalOpen(false)}
          />
        }
      >
        <Container>
          <div>For urgent needs please contact the IT Help Desk</div>
          <ContactFieldContainer>
            <FieldLabel>Phone:</FieldLabel>
            <span>1 (800) 850-2558</span>
          </ContactFieldContainer>
          <ContactFieldContainer>
            <FieldLabel>Email</FieldLabel>
            <span>
              <a href="mailto:itsupport@trueblue.com">itsupport@trueblue.com</a>
            </span>
          </ContactFieldContainer>
          <div>For general help, requests, and enhancement requests</div>
          <ContactFieldContainer>
            <FieldLabel>IT Service Team</FieldLabel>
            <span>
              <a
                href="https://trueblue.service-now.com/true"
                target="_blank"
                rel="noreferrer"
              >
                https://trueblue.service-now.com/true
              </a>
            </span>
          </ContactFieldContainer>
          <ContactFieldContainer>
            <FieldLabel>Stafftrack Support</FieldLabel>
            <span>
              <a href="mailto:stafftrack-support-desk@trueblue.com">
                stafftrack-support-desk@trueblue.com
              </a>
            </span>
          </ContactFieldContainer>
          <ButtonContainer>
            <STButton onClick={() => setIsHelpModalOpen(false)}>Close</STButton>
          </ButtonContainer>
        </Container>
      </Panel>
    </Modal>,
    document.body
  );
};

export default ContactHelpModal;
