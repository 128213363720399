import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { IoSearchSharp } from "react-icons/io5";
import { CSSTransition } from "react-transition-group";
import { FC, useMemo } from "react";
import { useAppURLParams } from "Lib/UseAppOffice";

import { Menu } from "./styles";
import { useAppContext } from "Context/AppContext";
import { OfficeDTO } from "Api/StaffTrack/Auth/Models/officeDto";
import { useChangeCurrentOffice } from "Api/Hooks/User";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import { useAtom } from "jotai";
import { filterValueAtom } from "Components/OfficeSelection/Atom";

type HeaderProps = {
  onChange: (value: string) => void;
};
const Header: FC<HeaderProps> = ({ onChange }) => (
  <span className="p-input-icon-right w-full">
    <IoSearchSharp />
    <InputText
      className="border-noround w-full"
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  </span>
);

type Props = {
  data: OfficeDTO[] | undefined;
  onClick: () => void;
  showList: boolean;
};
export const OfficeList: FC<Props> = ({ data = [], showList, onClick }) => {
  const [filterValue, setFilterValue] = useAtom(filterValueAtom);
  const { changeOfficeIdURL } = useAppURLParams();
  const { mutateAsync: changeCurrentOffice } = useChangeCurrentOffice(false);
  const { setCurrentOffice } = useAppContext();
  const navigate = useNavigate();

  const dataFiltered = useMemo(
    () =>
      data
        .sort((a, b) => (a.officeName || "").localeCompare(b.officeName || ""))
        .filter((office) =>
          (office.officeName || "")
            .toUpperCase()
            .includes(filterValue.toUpperCase())
        ),
    [data, filterValue]
  );
  const HeaderCmp = () => (
    <Header
      onChange={(value) => {
        setFilterValue(value);
      }}
    />
  );
  return (
    <CSSTransition
      classNames="fadein animation-duration-150 animate-width"
      timeout={{ enter: 1000, exit: 450 }}
      in={showList}
      unmountOnExit
    >
      <Menu className="shadow-6 z-1 w-full sm:w-auto">
        <DataTable
          style={{ backgroundColor: "yellow" }}
          value={dataFiltered}
          header={HeaderCmp}
          scrollable
          rowHover
          scrollHeight="14rem"
          size="small"
          onRowClick={(e) => {
            setFilterValue("");
            changeCurrentOffice(e.data.officeId).then(() => {
              setCurrentOffice(e.data);
              changeOfficeIdURL(e.data.officeId);
              onClick();
              //redirect to home
              navigate(paths.home);
            });
          }}
        >
          <Column
            field="officeName"
            headerClassName="hidden"
            style={{ cursor: "pointer" }}
          />
        </DataTable>
      </Menu>
    </CSSTransition>
  );
};
