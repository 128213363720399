import { useAtom } from "jotai";
import { lastRouteAtom } from "Context/AppGlobalState";
import { useCallback } from "react";

const useNavigate = () => {
  const [lastRoute, setLastPath] = useAtom(lastRouteAtom);

  const addNewRoute = useCallback(
    (route: string | undefined, query: string | undefined) => {
      if (!route || !query) return;
      if (lastRoute.length === 0) {
        setLastPath([{ route, query }]);
        return;
      }
      if (lastRoute[lastRoute?.length - 1].route !== route) {
        let newRoute = [...lastRoute, { route, query }];
        if (newRoute.length > 10) newRoute = newRoute.slice(1, newRoute.length);
        setLastPath(newRoute);
      }
    },
    [lastRoute]
  );

  const getLastRoute = useCallback(
    (index: number, fallBackRoute?: string) => {
      if (!lastRoute) return fallBackRoute;
      if (lastRoute.length <= 1) return fallBackRoute;
      // The process of generate the query params add one question mark for every param, the easiest and elegant
      // way to remove it is to replace all double question marks for one
      const route = `${lastRoute[lastRoute.length - 1 - index].route}?${
        lastRoute[lastRoute.length - 1 - index].query
      }`.replaceAll("??", "?");
      return route;
    },
    [lastRoute]
  );

  return { addNewRoute, getLastRoute };
};

export default useNavigate;
