import { CSSTransition } from "react-transition-group";
import { useAppContext } from "Context/AppContext";
import { I18nProps } from "Types/I18n";
import { stringifyURL, useQueryParams } from "StaffTrack";
import { SubMenuItem, SubMenuItemExternal, SubMenuList } from "./styles";
import { useAtom } from "jotai";
import {
  currentAssociateAtom,
  menuExtraInfoAtom,
} from "Context/AppGlobalState";
import { useMemo } from "react";
import { useMobile } from "App/media";

interface Props extends I18nProps {
  submenuList: {
    id: number;
    label: string;
    path: string;
    external?: string;
    resourceId?: number;
    additionalCheck?: string;
    isAssociateMenu?: boolean;
    queryStringParams?: object;
  }[];
  showSubmenu: boolean;
}
const MenuLabel = ({ label }) => {
  return <span style={{ fontSize: "0.85rem" }}>{label}</span>;
};

export const SubMenu = ({ submenuList, showSubmenu, t }: Props) => {
  const { currentOffice, selectedMenu, setSelectedMenu, setIsMenuOpen } =
    useAppContext();
  const [currentAssociate] = useAtom(currentAssociateAtom);
  const [menuExtraInfo] = useAtom(menuExtraInfoAtom);
  const amr = useMemo(() => {
    if (!currentAssociate || !currentAssociate.associateMasterRecordId)
      return null;
    return currentAssociate.associateMasterRecordId;
  }, [currentAssociate]);
  const fromMissAndExpiredDocs = useQueryParams().getUnsafeNullValue(
    "fromMissAndExpiredDocs"
  );
  const { isMobile } = useMobile();

  return (
    <CSSTransition
      classNames="fadein animation-duration-150 animate-width"
      timeout={{ enter: 1000, exit: 450 }}
      in={showSubmenu}
      unmountOnExit
    >
      <SubMenuList>
        {submenuList.map((sub) => {
          if (sub.external) {
            const to = stringifyURL(sub.external, {
              queryStringParams: {
                officeId: currentOffice?.officeId,
                ...sub.queryStringParams,
              },
            });
            return (
              <SubMenuItemExternal
                key={sub.id}
                selected={selectedMenu === sub.id}
                onClick={() => setSelectedMenu(sub.id)}
                href={to}
                className="flex align-items-center"
              >
                <MenuLabel label={t(`menu:${sub.label}`)} />
              </SubMenuItemExternal>
            );
          }
          const element = menuExtraInfo.find((item) => item.id === sub.id);
          const to = stringifyURL(sub.path, {
            queryStringParams: {
              officeId: currentOffice?.officeId,
              amr: sub.isAssociateMenu && amr ? amr : null,
              fromMissAndExpiredDocs:
                sub.isAssociateMenu && fromMissAndExpiredDocs
                  ? fromMissAndExpiredDocs
                  : null,
              ...sub.queryStringParams,
            },
          });
          return (
            <SubMenuItem
              key={sub.id}
              selected={selectedMenu === sub.id}
              onClick={() => {
                if (isMobile) setIsMenuOpen(false);
                setSelectedMenu(sub.id);
              }}
              to={to}
              className="flex align-items-center"
            >
              <>
                <MenuLabel label={t(`menu:${sub.label}`)} /> {element?.element}
              </>
            </SubMenuItem>
          );
        })}
      </SubMenuList>
    </CSSTransition>
  );
};
