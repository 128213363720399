import { useMedia } from "react-use";

export const mobileSize = 576;
export const tabletSize = 960;
export const mobileQuery = `screen and (max-width: ${mobileSize}px)`;
export const tabletQuery = `screen and (min-width: ${
  mobileSize + 1
}px) and (max-width: ${tabletSize}px)`;
export const desktopQuery = `screen and (min-width: ${tabletSize + 1}px)`;
export const useMobile = () => {
  const isMobile = useMedia(mobileQuery);
  const isDesktop = useMedia(desktopQuery);
  const isTablet = useMedia(tabletQuery);
  return { isMobile, isTablet, isDesktop };
};
