import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { findBreadcrumbByName } from "Lib/FindBreadcrumbByName";
import { STBreadCrumb } from "Types/Breadcrumb";
import { OfficeDTO } from "Api/StaffTrack/Auth/Models/officeDto";

export interface STPageBreadCrumb extends STBreadCrumb {
  params?: object;
  additionalText?: string;
}
const menuLSName = "mystn-menu-state";
// Gets Menu situation on local storage
export const loadFromLocalStorageMenuState = (): number[] => {
  try {
    const valueFromLS = JSON.parse(localStorage.getItem(menuLSName) || "[]");
    if (valueFromLS && Array.isArray(valueFromLS)) {
      // test if all values are numbers
      let hasNaN = false;
      valueFromLS.forEach((menuId) => {
        if (Number.isNaN(menuId)) {
          hasNaN = true;
        }
      });
      if (hasNaN) return [];
      return valueFromLS;
    }
    return [];
  } catch (e) {
    return [];
  }
};

const saveMenuToLocalStorage = (ids: number[]) => {
  localStorage.setItem(menuLSName, JSON.stringify(ids));
};
export type AppContextProps = {
  actions: JSX.Element[];
  actionButtons: JSX.Element[];
  breadcrumbs: STPageBreadCrumb[];
  apiErrorMessage: string;
  currentOffice: OfficeDTO | undefined;
  isHelpModalOpen: boolean;
  isLogged: boolean;
  isMenuOpen: boolean;
  lastApiCall: Date;
  isProfileOpen: boolean;
  openMenuGroup: (menuGroupId: number) => void;
  openedMenuGroups: number[];
  selectedMenu: number | undefined;
  manuallyToggledMenu: number | undefined;
  isDashboardInChangeMode: boolean;
  setActions: (actions: JSX.Element[]) => void;
  setActionButtons: (actionButtons: JSX.Element[]) => void;
  setApiErrorMessage: (msg: string) => void;
  setBreadcrumbsFromTitleList: (
    breadcrumb: string[],
    params?: object,
    additionalText?: object
  ) => void;
  setCurrentOffice: (office: OfficeDTO) => void;
  clearCurrentOffice: () => void;
  setIsHelpModalOpen: (isOpen: boolean) => void;
  setIsLogged: (isLogged: boolean) => void;
  setIsMenuOpen: (isOpen: boolean) => void;
  setIsProfileOpen: (isOpen: boolean) => void;
  setLastApiCall: (time: Date) => void;
  setSelectedMenu: (id: number) => void;
  toggleDashboardInChangeMode: () => void;
  toggleMenuGroupOpen: (menuGroupId: number, manuallyToggled: boolean) => void;
  setManuallyToggledMenu: (menuGroupId: number) => void;
};

const AppContext = createContext<AppContextProps>({
  actions: [],
  actionButtons: [],
  breadcrumbs: [],
  apiErrorMessage: "",
  currentOffice: undefined,
  isHelpModalOpen: false,
  isLogged: true, // It is logged until we got the first 401
  isMenuOpen: true,
  isProfileOpen: false,
  lastApiCall: new Date(),
  isDashboardInChangeMode: false,
  openMenuGroup: () => {},
  openedMenuGroups: [],
  selectedMenu: undefined,
  manuallyToggledMenu: undefined,
  setActions: () => {},
  setActionButtons: () => {},
  clearCurrentOffice: () => {},
  setCurrentOffice: () => {},
  setBreadcrumbsFromTitleList: () => {},
  setApiErrorMessage: () => {},
  setIsHelpModalOpen: () => {},
  setIsLogged: () => {},
  setIsMenuOpen: () => {},
  setIsProfileOpen: () => {},
  setLastApiCall: () => {},
  setSelectedMenu: () => {},
  toggleDashboardInChangeMode: () => {},
  toggleMenuGroupOpen: () => {},
  setManuallyToggledMenu: () => {},
});
export const useAppContext = () => useContext(AppContext);
export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [isLogged, setIsLogged] = useState<boolean>(true);
  const [selectedMenu, setSelectedMenu] = useState<number | undefined>();
  const [currentOffice, setCurrentOffice] = useState<OfficeDTO>();
  const [lastApiCall, setLastApiCall] = useState<Date>(new Date());
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [actions, setActions] = useState<JSX.Element[]>([]);
  const [actionButtons, setActionButtons] = useState<JSX.Element[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [breadcrumbs, setBreadcrumbs] = useState<STPageBreadCrumb[]>([]);
  const [openedMenuGroups, setOpenedMenuGroups] = useState<number[]>([]);
  const [manuallyToggledMenu, setManuallyToggledMenu] = useState<number>();
  const [isDashboardInChangeMode, setIsDashboardInChangeMode] =
    useState<boolean>(false);

  const openMenuGroup = useCallback(
    (menuGroupId: number) => {
      const newValue = [...openedMenuGroups];
      if (!newValue.find((id) => id === menuGroupId)) {
        newValue.push(menuGroupId);
      }
      saveMenuToLocalStorage(newValue);
      setOpenedMenuGroups(newValue);
    },
    [openedMenuGroups]
  );

  const toggleDashboardInChangeMode = () => {
    setIsDashboardInChangeMode(!isDashboardInChangeMode);
  };
  const toggleMenuGroupOpen = useCallback(
    (menuGroupId: number, manuallyToggled: boolean) => {
      let newValue = [...openedMenuGroups];
      if (!newValue.find((id) => id === menuGroupId)) {
        newValue.push(menuGroupId);
      } else {
        saveMenuToLocalStorage(newValue);
        newValue = [...newValue.filter((id) => id !== menuGroupId)];
      }
      saveMenuToLocalStorage(newValue);
      setOpenedMenuGroups(newValue);
      setManuallyToggledMenu(manuallyToggled ? menuGroupId : undefined);
    },
    [openedMenuGroups]
  );
  const setBreadcrumbsFromTitleList = useCallback(
    (titles: string[], params?: object, additionalText?: object) => {
      const list: STPageBreadCrumb[] = [];
      titles.forEach((title) => {
        const item = findBreadcrumbByName(title);
        let paramsForItem;
        let additionalTextForItem;
        if (item) {
          if (params && item.title.length > 1) {
            paramsForItem =
              params[`${item.title[0].toLowerCase()}${item.title.slice(1)}`];
          }
          if (additionalText && item.title.length > 1)
            additionalTextForItem =
              additionalText[
                `${item.title[0].toLowerCase()}${item.title.slice(1)}`
              ];

          if (item.subMenuId) setSelectedMenu(item.subMenuId);
          else if (item.menuId) setSelectedMenu(item.menuId);

          const i = {
            ...item,
            additionalText: additionalTextForItem,
            params: paramsForItem,
          };
          list.push(i);
        } else {
          // eslint-disable-next-line no-console
          console.error(`The title ${title} does not exist on breadcrumbs`);
        }
      });
      setBreadcrumbs(list);
    },
    [setBreadcrumbs]
  );

  const clearCurrentOffice = () => {
    setCurrentOffice(undefined);
  };
  const value = useMemo(
    () => ({
      actions,
      actionButtons,
      breadcrumbs,
      apiErrorMessage,
      currentOffice,
      isDashboardInChangeMode,
      isHelpModalOpen,
      isLogged,
      isMenuOpen,
      isProfileOpen,
      lastApiCall,
      openMenuGroup,
      openedMenuGroups,
      selectedMenu,
      setActions,
      setActionButtons,
      setBreadcrumbsFromTitleList,
      clearCurrentOffice,
      setCurrentOffice,
      setApiErrorMessage,
      setIsHelpModalOpen,
      setIsLogged,
      setIsMenuOpen,
      setIsProfileOpen,
      setSelectedMenu,
      setLastApiCall,
      toggleMenuGroupOpen,
      toggleDashboardInChangeMode,
      manuallyToggledMenu,
      setManuallyToggledMenu,
    }),
    [
      actions,
      actionButtons,
      breadcrumbs,
      apiErrorMessage,
      currentOffice,
      isDashboardInChangeMode,
      isHelpModalOpen,
      isLogged,
      isMenuOpen,
      isProfileOpen,
      lastApiCall,
      openMenuGroup,
      openedMenuGroups,
      selectedMenu,
      setBreadcrumbsFromTitleList,
      toggleMenuGroupOpen,
      manuallyToggledMenu,
      setManuallyToggledMenu,
    ]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
