import styled from "styled-components";

type ContainerProps = {
  isMenuOpen: boolean;
};
export const Container = styled.nav<ContainerProps>`
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  height: 4rem;
  width: ${({ isMenuOpen }) =>
    isMenuOpen ? `calc(100vw - 11.875rem)` : `100%`};
  box-shadow: 2px 1px 5px -1px rgba(0, 0, 0, 0.67);
  background-color: white;
`;
export const RightContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  position: relative;
  width: 100%;
`;

export const BreadCrumbMenuIconContainer = styled.div`
  border: 1px #6db3e3 solid;
  padding: 0.4rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.span`
  margin-left: -0.7rem;
  margin-right: 0.2rem;
  margin-top: 0.4rem;
`;
