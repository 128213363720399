import styled from "styled-components";

type MenuContainerProps = {
  visible?: boolean;
};

export const MenuContainer = styled.div<MenuContainerProps>`
  flex-direction: column;
  overflow: auto;
  width: ${({ visible }) => (visible ? `100%` : 0)};
  transition-delay: ${({ visible }) => (visible ? `0.3s` : `0`)};
`;
