import APiError from "Components/APIError";

const Error500 = () => (
  <APiError
    status={500}
    message="Interval Server Error"
    submessage="Please be patient or try again later."
  />
);

export default Error500;
