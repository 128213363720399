import APiError from "Components/APIError";

const Error403 = () => (
  <APiError
    status={403}
    message="Forbidden"
    submessage="You don't have access to the page you requested."
  />
);

export default Error403;
