import React, { ComponentType } from "react";
import { useAtom } from "jotai";
import { viewModeAtom, ViewTypes } from "Context/AppGlobalState";
import ErrorUnknown from "Pages/Error/Unknown";
import usePathChange from "Routes/Logic/usePathChange";

const withAccessControl = (
  WrappedComponent: ComponentType,
  roles: Exclude<ViewTypes, undefined>[]
) => {
  return (props) => {
    const [view] = useAtom(viewModeAtom);
    usePathChange();

    if (view === undefined) return <WrappedComponent {...props} />;
    if (roles.includes(view)) {
      return <WrappedComponent {...props} />;
    } else {
      return <ErrorUnknown />;
    }
  };
};

export default withAccessControl;
