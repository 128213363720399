import { useAppContext } from "Context/AppContext";

const useAuth = () => {
  const { isLogged, setIsLogged } = useAppContext();

  const loginUser = () => {
    setIsLogged(true);
  };

  const logoutUser = () => {
    setIsLogged(false);
  };

  return { isAuthenticated: isLogged, loginUser, logoutUser };
};

export default useAuth;
