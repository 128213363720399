import { include } from "named-urls";

export default {
  standardReportsPaths: include("/standardReports", {
    main: "",
    clientReports: include("client", {
      hoursReportClient: include("hoursReport", {
        main: "",
      }),
      clientTimeApprovalReports: include("timeApprovalReport", {
        main: "",
      }),
      clientDocumentAuditReports: include("documentAuditReport", {
        parameters: "parameters",
        view: "view",
        main: "",
      }),
      clientAssociateReports: include("associate", {
        main: "",
      }),
      clientRosterReports: include("rosterReport", {
        main: "",
        parameters: "parameters",
        view: "view",
      }),
    }),
    vendorReports: include("vendor", {
      associateReports: include("associate", {
        parameters: "parameters",
        view: "view",
        main: "",
      }),
      rosterReports: include("rosterReport", {
        parameters: "parameters",
        view: "view",
        main: "",
      }),
      timeApprovalReports: include("timeApprovalReport", {
        parameters: "parameters",
        view: "view",
        main: "",
      }),
      documentAuditReports: include("documentAuditReport", {
        parameters: "parameters",
        view: "view",
        main: "",
      }),
      invoiceReports: include("invoiceReport", {
        parameters: "parameters",
        view: "view",
        main: "",
      }),
      hoursReport: include("hoursReport", {
        parameters: "parameters",
        view: "view",
      }),
    }),
  }),
  customReportsPaths: include("/customReports", {
    root: "",
  }),
  customDashboardsPaths: include("/customDashboards", {
    root: "",
  }),
  externalPaths: include(window.location.origin, {
    stnServletPath: "/mystafftracknet/MyStaffTrackNetReportExportServlet",
    crystalPath: "/servlet/CrystalReportExportServlet",
  }),
};
