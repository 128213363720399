import { useChangeCurrentOffice, useGetUserOffices } from "Api/Hooks/User";
import { useAppContext } from "Context/AppContext";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import { stringifyURL } from "StaffTrack";
import { UserInformation } from "Api/StaffTrack/Auth/Models/userInformation";
import { useAtom } from "jotai";
import { manuallyLoggedOutAtom } from "Context/AppGlobalState";

const useRedirectAfterLogin = (redirectUrl: string | null) => {
  const { mutateAsync: changeCurrentOffice } = useChangeCurrentOffice(false);
  const { mutateAsync: getAvailableOffices } = useGetUserOffices();
  const { setCurrentOffice } = useAppContext();
  const navigate = useNavigate();
  const [manuallyLoggedOut] = useAtom(manuallyLoggedOutAtom);

  const redirectAfterLogin = (userInfo: UserInformation) => {
    if (manuallyLoggedOut) {
      navigate(paths.home);
      return;
    }
    if (redirectUrl) {
      //If redirect has officeId param, compare it with the current office
      //If it's different, change the current office and redirect
      if (redirectUrl.includes("officeId=")) {
        const officeId = parseInt(
          redirectUrl.split("officeId=")[1].split("&")[0]
        );
        if (officeId !== userInfo.currentOffice) {
          getAvailableOffices().then((availableOffices) => {
            //Check if the office is available for the user
            const office = availableOffices.data?.find(
              (o) => o.officeId === officeId
            );
            if (office) {
              changeCurrentOffice(officeId.toString()).then(() => {
                setCurrentOffice(office);
                navigate(redirectUrl);
              });
            } else {
              navigate(
                stringifyURL(paths.home, {
                  queryStringParams: {
                    officeId: userInfo.currentOffice || -1,
                  },
                })
              );
            }
          });
        } else {
          navigate(redirectUrl);
        }
      } else {
        navigate(redirectUrl);
      }
    } else {
      navigate(
        stringifyURL(paths.home, {
          queryStringParams: {
            officeId: userInfo.currentOffice || -1,
          },
        })
      );
    }
  };

  return { redirectAfterLogin };
};

export default useRedirectAfterLogin;
