/* eslint @typescript-eslint/no-explicit-any: 0 */
import { useEffect } from "react";

const useClickAway = (
  ref: any,
  onClickOutSide: () => void,
  initiatorId?: string
) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.id === initiatorId) return;
      if (ref && ref.current && !ref.current.contains(event.target)) {
        onClickOutSide();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [initiatorId, onClickOutSide, ref]);
};
export default useClickAway;
