import styled from "styled-components";

export const ErrorWrapper = styled.div`
  height: 100%;
  opacity: 100%;
  background-color: white;
`;

export const ErrorContainer = styled.div`
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorLogo = styled.div`
  padding: 0;
  margin: 0;
`;

export const ErrorStatus = styled.div`
  padding: 0;
  margin: 0;
  font-size: 4rem;
`;

export const ErrorMessage = styled.div`
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
`;

export const ErrorSubMessage = styled.div`
  padding: 0;
  margin: 0;
`;
