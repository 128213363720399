const calculateBasePath = (envBasePath: string | undefined) => {
  const apiPath = window.location.origin
    ? new URL(window.location.origin).origin
    : "";
  return !envBasePath || envBasePath === "" ? apiPath : envBasePath;
};
export const BASE_PATH = calculateBasePath(
  process.env.ST_REACT_ST_API_BASE_PATH
);

export const API_BASE_PATH = process.env.ST_REACT_ST_API_BASE_PATH;

export const UI_BASE_PATH = process.env.ST_REACT_UI_BASE_PATH;

export const VERSION = process.env.VERSION || "NO_VERSION";

export const REACT_ENV = process.env.ST_REACT_ENV;

export const API_DEBUG_MODE = process.env.ST_REACT_API_DEBUG_MODE;
