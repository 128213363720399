import { include } from "named-urls";

export const associateReportEndpoints = {
  api: include("api", {
    auth: include("reports", {
      vendors: include("vendors", {
        associates: include("associate", {
          statuses: "statuses",
          list: "",
        }),
      }),
      clients: include("clients", {
        associate: include("associateReport", {
          main: "",
        }),
      }),
    }),
  }),
};
