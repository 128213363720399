import { ErrorMessage, STButton } from "StaffTrack";
import { FC } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import FormHeader from "Templates/Headers/PanelHeaders/FormHeader";
import { ForgotPasswordRequest } from "Api/StaffTrack/Auth/Models/forgotPasswordRequest";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import FloatField from "Pages/Login/Components/FloatField";

type Props = {
  isLoading: boolean;
  errorMessage: string;
};
const ForgotPasswordForm: FC<Props> = ({ isLoading, errorMessage }) => {
  const { values, handleChange, handleSubmit, handleBlur } =
    useFormikContext<ForgotPasswordRequest>();
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="panel panel-header">
          <FormHeader headerTitle={t("login.resetPasswordRequest")} />
        </div>
        {errorMessage && <ErrorMessage invertColors message={errorMessage} />}
        <div className="panel-body">
          <div style={{ width: "20rem", overflow: "hidden" }}>
            <div className="pt-4">
              <FloatField
                id="email"
                label={t("login.loginEmail")}
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                width="30rem"
              />
            </div>
            <div className="flex justify-content-between mt-4">
              <STButton className="px-5" type="submit" disabled={isLoading}>
                <span className={`${isLoading ? `pr-1` : ``}`}>
                  {t("login.requestReset")}
                </span>
                {isLoading && <span className="pi pi-spin pi-spinner" />}
              </STButton>
              <STButton
                secondary
                className="px-4"
                type="button"
                disabled={isLoading}
                onClick={() => navigate(paths.login)}
              >
                <span>{t("login.cancel")}</span>
              </STButton>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
