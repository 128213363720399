import { useEffect, useState } from "react";

import {
  loadFromLocalStorageMenuState,
  useAppContext,
} from "Context/AppContext";

import Logo from "Assets/Logos/logo_mySTN_color_reverse_light.svg";
import FooterLogin from "Pages/Login/FooterLogin";
import { useAtom } from "jotai";
import {
  currentAssociateAtom,
  currentAssociatePageAtom,
} from "Context/AppGlobalState";
import { useLocalContext } from "Context/LocalActionsContext";
import { useQueryClient } from "react-query";
import "Pages/Login/styles.css";
import Login from "Pages/Login/Forms/Login";
import ForgotPassword from "Pages/Login/Forms/ForgotPassword";
import { useQueryParams } from "Lib/UseQueryParams";
import ConditionalRender from "Pages/AnaliticsAndReports/StandardReports/Shared/Components/ConditionalRender";
import ResetPassword from "Pages/Login/Forms/ResetPassword";
import ChangePassword from "Pages/Login/Forms/ChangePassword";
import SetSecurityQuestions from "Pages/Login/Forms/Login/setSecurityQuestions";

const LoginPage = () => {
  const { setActions, clearCurrentOffice } = useAppContext();
  useEffect(() => {
    setActions([]);
  }, []);
  const { setActionsLocal } = useLocalContext();
  const queryClient = useQueryClient();
  const [isCleaning, setIsCleaning] = useState(true);
  const [showingQuestions, setShowingQuestions] = useState(false);
  const [, setCurrentAssociate] = useAtom(currentAssociateAtom);
  const [, setCurrentAssociatePage] = useAtom(currentAssociatePageAtom);

  const { getUnsafeNullValue } = useQueryParams();
  const action = getUnsafeNullValue("action");

  useEffect(() => {
    setActionsLocal([]);
    queryClient.invalidateQueries().then(() => {
      queryClient.clear();
      queryClient.cancelQueries().then(() => setIsCleaning(false));
    });
  }, []);

  useEffect(() => {
    setCurrentAssociatePage(undefined);
    setCurrentAssociate(undefined);
    loadFromLocalStorageMenuState();
    clearCurrentOffice();
  }, []);

  return (
    <>
      <div className="login-screen">
        <img src="/images/login-bg.jpg" className="login-bg-image" />

        <Logo
          style={{
            height: "5rem",
          }}
          className="front"
        />
        <div
          className={`front ${
            (action === "resetPassword" && showingQuestions) ||
            action === "setQuestions"
              ? "login-form-big"
              : "login-form"
          }`}
        >
          <ConditionalRender shouldRender={!action}>
            <Login isCleaning={isCleaning} />
          </ConditionalRender>
          <ConditionalRender shouldRender={action === "forgotPassword"}>
            <ForgotPassword />
          </ConditionalRender>
          <ConditionalRender shouldRender={action === "resetPassword"}>
            <ResetPassword setShowingQuestions={setShowingQuestions} />
          </ConditionalRender>
          <ConditionalRender shouldRender={action === "changePassword"}>
            <ChangePassword />
          </ConditionalRender>
          <ConditionalRender shouldRender={action === "setQuestions"}>
            <SetSecurityQuestions />
          </ConditionalRender>
        </div>
        <div className="front login-footer">
          <FooterLogin />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
