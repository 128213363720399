import { STBreadCrumb } from "Types/Breadcrumb";
import { paths } from "Routes/Paths";

export const orderWorkersBreadcrumbs: STBreadCrumb[] = [
  {
    title: "OrderWorkers",
    url: paths.orderWorkers.main,
    external: false,
    translationResource: "breadcrumbs:orderWorkers.orderWorkers",
    menuId: 2,
  },
  {
    title: "ViewEditOrders",
    url: paths.orderWorkers.main,
    external: false,
    translationResource: "breadcrumbs:orderWorkers.viewEditOrders",
    subMenuId: 11,
  },
  {
    title: "ViewEditRequisition",
    url: paths.orderWorkers.viewRequisition,
    external: false,
    translationResource: "breadcrumbs:orderWorkers.viewEditRequisition",
    subMenuId: 12,
  },
  {
    title: "NewRequisitions",
    url: "/",
    external: false,
    translationResource: "breadcrumbs:orderWorkers.newRequisition",
    subMenuId: 13,
  },
  {
    title: "EditRequisition",
    url: "/",
    external: false,
    translationResource: "breadcrumbs:orderWorkers.editRequisition",
    subMenuId: 12,
  },
  {
    title: "EditOrders",
    url: paths.orderWorkers.main,
    external: false,
    translationResource: "breadcrumbs:orderWorkers.editOrders",
    subMenuId: 11,
  },
  {
    title: "SelectEditOrders",
    url: paths.orderWorkers.main,
    external: false,
    translationResource: "breadcrumbs:orderWorkers.selectEditOrders",
    subMenuId: 11,
  },
  {
    title: "BatchEditOrders",
    url: paths.orderWorkers.main,
    external: false,
    translationResource: "breadcrumbs:orderWorkers.batchEditOrders",
    subMenuId: 11,
  },
  {
    title: "RequestNewOrder",
    url: "/",
    external: false,
    translationResource: "breadcrumbs:orderWorkers.requestNewOrder",
    menuId: 2,
  },
  {
    title: "AddStartSession",
    url: "/",
    external: false,
    translationResource: "breadcrumbs:orderWorkers.addStartSession",
    menuId: 2,
  },
  {
    title: "Week",
    url: paths.orderWorkers.main,
    external: false,
    translationResource: "breadcrumbs:orderWorkers.week",
    menuId: 2,
  },
];
