import { Modal, STButton } from "StaffTrack";
import React, { FC } from "react";
import { useAtom } from "jotai";
import { modalsAtom, ModalState } from "Context/AppGlobalState";
import { useTranslation } from "react-i18next";
import { BsExclamationTriangle } from "react-icons/bs";

export const AlertModal: FC = () => {
  const { t } = useTranslation();
  const [modals, setModals] = useAtom(modalsAtom);

  const removeModal = (id: string) => {
    setModals((prev) => prev.filter((modal) => modal.id !== id));
  };

  const buttons = (modal: ModalState) => {
    if (modal.customButtons && modal.customButtons.length)
      return (
        <div className={"flex gap-3"}>
          {modal.customButtons.map((button: JSX.Element) => {
            //Override onClick to close modal
            const originalClickHandler = button.props.onClick;
            const newClickHandler = () => {
              if (originalClickHandler) {
                originalClickHandler();
              }
              removeModal(modal.id);
            };

            return React.cloneElement(button, {
              onClick: newClickHandler,
            });
          })}
        </div>
      );
    switch (modal.mode) {
      case "attention":
      case "success":
        return (
          <>
            <STButton
              className="font-bold"
              onClick={() => removeModal(modal.id)}
              backgroundColor="#2262a9"
              borderColor="#2262a9"
              fontSize="0.8125rem"
            >
              Ok
            </STButton>
          </>
        );
      default:
        return <></>;
    }
  };

  const title = (modal: ModalState) => {
    if (modal.title) return modal.title;
    switch (modal.mode) {
      case "attention":
        return t("common:dialog:attention");
      case "success":
        return t("common:dialog:success");
      default:
        return "";
    }
  };

  const icon = (modal: ModalState) => {
    switch (modal.mode) {
      case "attention":
        return (
          <div className="mx-2">
            <BsExclamationTriangle
              size={40}
              className="m-auto"
              color="#d9001b"
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  const formattedMessage = (modal: ModalState) => {
    if (modal.message && modal.message.includes("\n")) {
      const lines = modal.message.split("\n");
      return lines.map((line, index) => <div key={index}>{line}</div>);
    }
    return modal.message;
  };

  return (
    <>
      {modals.map((modal: ModalState) => (
        <Modal visible={true} key={modal.id}>
          <div
            className="p-2 text-xl flex flex-column"
            style={{ minWidth: "300px" }}
          >
            <div className="font-bold">{title(modal)}</div>
            <div className="mt-5 flex">
              {icon(modal)}
              <div className="text-center w-full m-auto">
                {formattedMessage(modal)}
              </div>
            </div>
            <div className="flex justify-content-end pt-3 gap-3">
              {buttons(modal)}
            </div>
          </div>
        </Modal>
      ))}
    </>
  );
};
