import { commonPaths } from "Routes/Paths/Common/common";
import { dashboardPaths } from "Routes/Paths/Dashboard/dashboard";
import standardReportsPaths from "Routes/Paths/AnalyticsAndReports";
import customReportsPaths from "Routes/Paths/AnalyticsAndReports";
import associatePaths from "Routes/Paths/Associates";
import resourcesPaths from "Routes/Paths/Resources";
import orderPaths from "Routes/Paths/Orders";
import orderWorkersPaths from "Routes/Paths/OrderWorkers";
import externalPaths from "Routes/Paths/External";

export const paths = {
  ...commonPaths,
  ...dashboardPaths,
  ...standardReportsPaths,
  ...customReportsPaths,
  ...associatePaths,
  ...resourcesPaths,
  ...orderPaths,
  ...orderWorkersPaths,
  ...externalPaths,
};

export const publicPaths = {
  login: "/login",
  error404: "/404",
};
