import { FaRegFrown } from "react-icons/fa";
import {
  ErrorWrapper,
  ErrorContainer,
  ErrorLogo,
  ErrorStatus,
  ErrorMessage,
  ErrorSubMessage,
} from "./styles";

type Props = {
  status: number;
  message: string;
  submessage: string;
};

const APiError: React.FC<Props> = ({ status, message, submessage }) => (
  <ErrorWrapper>
    <ErrorContainer>
      <ErrorLogo>
        <FaRegFrown size={128} color="var(--gray-600)" />
      </ErrorLogo>
    </ErrorContainer>
    <ErrorContainer>
      <ErrorStatus>{status}</ErrorStatus>
    </ErrorContainer>
    <ErrorContainer>
      <ErrorMessage>{message}</ErrorMessage>
    </ErrorContainer>
    <ErrorContainer>
      <ErrorSubMessage>{submessage}</ErrorSubMessage>
    </ErrorContainer>
  </ErrorWrapper>
);

export default APiError;
