import { useStApi } from "Api/Common/UseStApi";
import { useMutation, useQuery } from "react-query";
import { associateReportEndpoints } from "Api/Hooks/AnaliticsAndReports/StandardReports/AssociateReport/associateReportEndpoints";
import { APIResult, Page } from "Types/Model";
import { stringifyURL } from "StaffTrack";
import { MySTNAssociateSearchDTO } from "Api/StaffTrack/Reports/Models/myStnAssociateSearchDto";
import { AssociateStageDTO } from "Api/StaffTrack/Reports/Models/associateStageDto";
import { PageInfo } from "Types/PageInfo";
import { ReportType } from "Pages/AnaliticsAndReports/StandardReports/Shared/Constants/ReportType";

export const useAssociateStatuses = (viewMode: String = "client") => {
  const { get } = useStApi();
  return useQuery<APIResult<AssociateStageDTO[]>>("AssociateStatuses", () =>
    get(
      stringifyURL(
        associateReportEndpoints.api.auth.vendors.associates.statuses,
        {
          queryStringParams: { viewMode },
        }
      )
    )
  );
};

export const useAssociateListPaginated = (
  status: string[] | null,
  stageResult: string | null,
  lastName: string | null,
  firstName: string | null,
  page: number,
  pageSize: number,
  assocVendorId: string | null,
  middleName: string | null,
  shiftId: string | null,
  deptId: string | null,
  lineId: string | null,
  progId: string | null,
  viewMode: string | null | undefined
) => {
  const { get } = useStApi();
  return useQuery<APIResult<PageInfo<MySTNAssociateSearchDTO>>>(
    [
      "associate-report",
      status,
      stageResult,
      lastName,
      firstName,
      page,
      pageSize,
    ],
    () =>
      get(
        stringifyURL(
          associateReportEndpoints.api.auth.vendors.associates.list,
          {
            queryStringParams: {
              status,
              lastName,
              firstName,
              stageResult,
              page,
              pageSize,
              paginated: true,
              assocVendorId,
              middleName,
              shiftId,
              deptId,
              lineId,
              progId,
              viewMode,
            },
          }
        )
      ),
    { enabled: status !== null }
  );
};

export const useAssociateListFullList = () => {
  const { get } = useStApi();
  return useMutation(
    ({ vendorId, status }: { vendorId: string; status: string }) =>
      get<APIResult<Page<MySTNAssociateSearchDTO>>>(
        stringifyURL(
          associateReportEndpoints.api.auth.vendors.associates.list,
          {
            queryStringParams: {
              vendorId,
              status,
              lastName: null,
              firstName: null,
              page: 1,
              pageSize: 100000000,
              paginated: false,
            },
          }
        )
      )
  );
};

export const useClientAssociateReportId = () => {
  const { post } = useStApi();

  return useMutation(
    (params: {
      officeId: number;
      reportType: ReportType;
      outputType: number;
      userReportId?: number;
      reportParams?: object;
      isZip?: boolean;
    }) => {
      const { officeId, reportParams, isZip } = params;
      const body = JSON.stringify({
        ...reportParams,
        officeId,
      });

      return post<APIResult<Number>>(
        stringifyURL(associateReportEndpoints.api.auth.clients.associate.main, {
          queryStringParams: { officeId, isZip },
        }),
        body
      );
    }
  );
};
