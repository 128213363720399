import { include } from "named-urls";

export const Endpoints = {
  api: include("api", {
    associate: include("associate", {
      caarMain: include("caarMain", {
        ticklers: include("ticklers", {
          getByStatus: ":aorId/:statusId/byTicklerStatus",
          getAssociateTickler: ":ticklerId/getTickler",
          getTicklerFollowUps: ":ticklerId/getFollowUps",
          getTicklerSubTopics: "getOfficeSubTopics",
          getTicklerSubActions: "getOfficeFollowUps",
          saveAssociateTickler: "saveAssociateTickler",
          getTicklerFollowUpAction: ":ticklerId/getTicklerFollowUpAction",
          saveTicklerFollowUp: "saveTicklerFollowUp",
          isOfficeCaarVaarManaged: "isOfficeCaarVaarManaged",
          isOfficeHavingPendingTickler: "isOfficeHavingPendingTickler",
        }),
        edit: include("edit", {
          root: ":aorId",
        }),
      }),
    }),
  }),
};
