import { Header } from "Components/Header";
import Menu from "Components/Menu";
import React, { FC } from "react";
import { OfficeSelection } from "Components/OfficeSelection";
import ToolBar from "Components/ToolBar";
import { useIsFetching, useIsMutating } from "react-query";
import { STSpinner } from "StaffTrack";
import { useAppContext } from "Context/AppContext";
import {
  AppBody,
  Container,
  Content,
  FullBodyContainer,
  SideBar,
} from "Templates/Layout/styles";
import { Outlet } from "react-router";
import { useSyncURLParams } from "Lib/UseAppOffice";
import SessionTimeOut from "Components/SessionTimeOut";
import useNavigate from "Lib/UseNavigate";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { excludedQueriesAtom } from "Api/Common/UseStApi/Atoms";
import useMenuLogic from "Constants/Menu/MenuLogic";

const Layout: FC = () => {
  const [excludedQueries] = useAtom(excludedQueriesAtom);

  //Get the number of queries that are currently fetching but exclude the one with key in excludedQueries
  const isFetching = useIsFetching({
    predicate: (query) => {
      if (
        typeof query.queryKey === "string" &&
        excludedQueries.has(query.queryKey)
      ) {
        //If query key is string and it is in excludedQueries, return false
        return false;
      } else if (
        Array.isArray(query.queryKey) &&
        query.queryKey.some((key) => excludedQueries.has(key))
      ) {
        //If query key is array and it has a string element that is in excludedQueries, return false
        return false;
      }
      return true;
    },
  });
  const isMutating = useIsMutating();
  const { isMenuOpen } = useAppContext();
  const location = useLocation();
  useSyncURLParams();
  const { addNewRoute } = useNavigate();
  addNewRoute(location.pathname, location.search);
  useMenuLogic();
  return (
    <Container id="appBody">
      {(isFetching > 0 || isMutating > 0) && <STSpinner visible />}
      <Header />
      <SessionTimeOut />
      <AppBody>
        <SideBar opened={isMenuOpen} className="shadow-7" id="appAside">
          <OfficeSelection />
          <Menu visible={isMenuOpen} />
        </SideBar>
        <FullBodyContainer
          isMenuOpen={isMenuOpen}
          className="flex flex-column"
          style={{ backgroundColor: "var(--surface-350)" }}
          id="fullBody"
        >
          <ToolBar />
          <Content>
            <div
              id="appBody"
              className="flex-grow-1"
              style={{ backgroundColor: "var(--surface-350)" }}
            >
              <Outlet />
            </div>
          </Content>
        </FullBodyContainer>
      </AppBody>
    </Container>
  );
};

export default Layout;
