import { ResetPasswordRequest } from "Api/StaffTrack/Auth/Models/resetPasswordRequest";

export interface ResetPasswordFullForm extends ResetPasswordRequest {
  confirmPassword: string;
}
export const resetPasswordValues: ResetPasswordFullForm = {
  email: "",
  password: "",
  confirmPassword: "",
};
