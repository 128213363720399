import React from "react";
import { RouteDefinition } from "Routes/Definitions/index";
import { paths } from "Routes/Paths";

const OpenOrders = React.lazy(
  () =>
    import(
      "Pages/Orders/OpenOrders/OpenOrdersPage" /*  webpackChunkName: "openOrders" */
    )
);
const AddMultipleAssociates = React.lazy(
  () =>
    import(
      "Pages/Orders/OpenOrders/AddMultipleAssociatePage" /*  webpackChunkName: "AddMultipleAssociatePage" */
    )
);
const AddSingleAssociate = React.lazy(
  () =>
    import(
      "Pages/Orders/OpenOrders/AddSingleAssociatePage" /*  webpackChunkName: "AddSingleAssociatePage" */
    )
);
const AddExistingAssociate = React.lazy(
  () =>
    import(
      "Pages/Orders/OpenOrders/AddExistingAssociatePage" /*  webpackChunkName: "AddExistingAssociate" */
    )
);

const PendingOrders = React.lazy(
  () =>
    import(
      "Pages/Orders/PendingOrders" /*  webpackChunkName: "PendingOrders" */
    )
);

const { openOrders, pendingOrders } = paths.orders;

const ordersDefinition: RouteDefinition[] = [
  {
    component: OpenOrders,
    path: openOrders.main,
    views: ["vendor"],
  },
  {
    component: AddMultipleAssociates,
    path: openOrders.addMultiplessociates,
    views: ["vendor"],
  },
  {
    component: AddSingleAssociate,
    path: openOrders.addSingleAssociate,
    views: ["vendor"],
  },
  {
    component: PendingOrders,
    path: pendingOrders.main,
    views: ["vendor"],
  },
  {
    component: AddExistingAssociate,
    path: openOrders.addExistingAssociate,
    views: ["vendor"],
  },
];
export default ordersDefinition;
