import { useStApi } from "Api/Common/UseStApi";
import { APIResult } from "Types/Model";
import { stringifyURL } from "StaffTrack";
import { Endpoints } from "Api/Hooks/Associate/CaarMain/endpoints";
import { AssociateTicklerDTO } from "Api/StaffTrack/Associate/Models/associateTicklerDto";
import { AssociateAddTicklerDTO } from "Api/StaffTrack/Associate/Models/associateAddTicklerDto";
import { TicklerFollowupDTO } from "Api/StaffTrack/Associate/Models/ticklerFollowupDto";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { NameValuePair } from "StaffTrack";
import { associateReportEndpoints } from "Api/Hooks/AnaliticsAndReports/StandardReports/AssociateReport/associateReportEndpoints";
import { viewModeAtom } from "Context/AppGlobalState";
import { useAtom } from "jotai";
import { cacheQuery } from "Api/Common/Query";

export const useGetTicklerByStatus = (
  aorId: string | null,
  statusId: string | null,
  viewType: string | null,
  caarSource: string | null
) => {
  const { get } = useStApi();
  const [viewMode] = useAtom(viewModeAtom);
  return useQuery<APIResult<AssociateTicklerDTO[]>>(
    [
      "AssociateStatusTicklers",
      aorId,
      statusId,
      viewType,
      caarSource,
      viewMode,
    ],
    () =>
      get(
        stringifyURL(Endpoints.api.associate.caarMain.ticklers.getByStatus, {
          pathParams: { aorId, statusId },
          queryStringParams: { viewType, caarSource, viewMode },
        })
      ),
    { enabled: statusId !== null }
  );
};

export const useGetAssocTicklerForm = (
  ticklerId: string | null,
  amr: string | undefined
) => {
  const { get } = useStApi({ allowHTML: true, throwExceptionOnStatus: [400] });
  const [viewMode] = useAtom(viewModeAtom);
  return useQuery<APIResult<AssociateAddTicklerDTO>>(
    ["AssociateTicklerForm", ticklerId, amr],
    () => {
      return get(
        stringifyURL(
          Endpoints.api.associate.caarMain.ticklers.getAssociateTickler,
          {
            pathParams: { ticklerId },
            queryStringParams: { amr, viewMode },
          }
        )
      );
    },
    { enabled: ticklerId !== null && amr !== undefined }
  );
};

export const useGetTicklerFollowups = (ticklerId: string | null) => {
  const { get } = useStApi();

  return useQuery<APIResult<TicklerFollowupDTO[]>>(
    ["getTicklerFollowups", ticklerId],
    () =>
      get(
        stringifyURL(
          Endpoints.api.associate.caarMain.ticklers.getTicklerFollowUps,
          {
            pathParams: { ticklerId },
          }
        )
      ),
    { enabled: ticklerId !== null && ticklerId !== "-1" }
  );
};

export const useGetOfficeSubTopics = (officeId: number | undefined) => {
  const { get } = useStApi();
  const [viewMode] = useAtom(viewModeAtom);
  return useQuery(
    ["OfficeSubTopics", officeId],
    () =>
      get<APIResult<NameValuePair>>(
        stringifyURL(
          Endpoints.api.associate.caarMain.ticklers.getTicklerSubTopics,
          {
            queryStringParams: { officeId, viewMode },
          }
        )
      ),
    {
      enabled: officeId !== undefined,
    }
  );
};

export const useGetOfficeSubActions = (officeId: number | undefined) => {
  const { get } = useStApi();
  const [viewMode] = useAtom(viewModeAtom);
  return useQuery(
    ["OfficeSubActions", officeId],
    () =>
      get<APIResult<NameValuePair>>(
        stringifyURL(
          Endpoints.api.associate.caarMain.ticklers.getTicklerSubActions,
          {
            queryStringParams: { officeId, viewMode },
          }
        )
      ),
    {
      enabled: officeId !== undefined,
    }
  );
};

export const useSaveAssociateTicklerForm = () => {
  const { patch } = useStApi({
    throwExceptionOnStatus: [412, 403, 500],
    allowHTML: true,
  });
  const [viewMode] = useAtom(viewModeAtom);
  return useMutation(({ form }: { form: AssociateAddTicklerDTO }) =>
    patch(
      stringifyURL(
        Endpoints.api.associate.caarMain.ticklers.saveAssociateTickler,
        {
          queryStringParams: { viewMode },
        }
      ),
      JSON.stringify(form)
    )
  );
};

export const useGetTicklerFollowupAction = (ticklerId: String | null) => {
  const { get } = useStApi();

  return useQuery<APIResult<TicklerFollowupDTO>>(
    ["TicklerFollowupAction", ticklerId],
    () =>
      get(
        stringifyURL(
          Endpoints.api.associate.caarMain.ticklers.getTicklerFollowUpAction,
          {
            pathParams: { ticklerId },
          }
        )
      ),
    { enabled: ticklerId !== null }
  );
};

export const useSaveTicklerFollowupAction = () => {
  const { patch } = useStApi({
    throwExceptionOnStatus: [412, 403, 500],
    allowHTML: true,
  });
  const query = useQueryClient();
  return useMutation(
    ({ form }: { form: TicklerFollowupDTO }) =>
      patch(
        stringifyURL(
          Endpoints.api.associate.caarMain.ticklers.saveTicklerFollowUp,
          {}
        ),
        JSON.stringify(form)
      ),
    { onSuccess: () => query.invalidateQueries(["getTicklerFollowups"]) }
  );
};

export const useCaarAssociateListPaginated = (
  page: number,
  pageSize: number,
  viewMode: string | null | undefined,
  firstName: string,
  lastName: string
) => {
  const { get } = useStApi();

  return useQuery(
    [viewMode, firstName, lastName, page],
    () =>
      get(
        stringifyURL(
          associateReportEndpoints.api.auth.vendors.associates.list,
          {
            queryStringParams: {
              status: ["1"],
              lastName,
              firstName,
              page,
              pageSize,
              paginated: true,
              viewMode,
            },
          }
        )
      ),
    { enabled: false }
  );
};

export const useIsOfficeCaarVaarManaged = (officeId: number | undefined) => {
  const { get } = useStApi();
  const [viewMode] = useAtom(viewModeAtom);
  return useQuery<APIResult<boolean>>(
    ["isOfficeCaarVaarManaged", officeId],
    () =>
      get(
        stringifyURL(
          Endpoints.api.associate.caarMain.ticklers.isOfficeCaarVaarManaged,
          {
            queryStringParams: { officeId, viewMode },
          }
        )
      ),
    { enabled: officeId !== null && officeId !== -1 }
  );
};

export const useIsOfficeHavePendingTickler = (officeId: number | undefined) => {
  const { get } = useStApi();
  const [viewMode] = useAtom(viewModeAtom);

  return useQuery<APIResult<boolean>>(
    ["isOfficeHavePendingTickler", officeId],
    () =>
      get(
        stringifyURL(
          Endpoints.api.associate.caarMain.ticklers
            .isOfficeHavingPendingTickler,
          {
            queryStringParams: { officeId, viewMode },
          }
        )
      ),
    { enabled: officeId !== null && officeId !== -1, ...cacheQuery }
  );
};
