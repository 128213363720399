import { useAppContext } from "Context/AppContext";
import { FC } from "react";
import { useLocalContext } from "Context/LocalActionsContext";
import { InvisibleButton } from "./styles";

type Props = {
  onClickItem?: () => void;
  local?: boolean;
};
const ActionList: FC<Props> = ({ onClickItem, local = false }) => {
  const { actions } = useAppContext();
  const { actionsLocal } = useLocalContext();
  if (local) {
    return onClickItem ? (
      <>
        {actionsLocal.map((action) => (
          <InvisibleButton
            key={action.key}
            type="button"
            onClick={() => onClickItem()}
          >
            {action}
          </InvisibleButton>
        ))}
      </>
    ) : (
      <>{actionsLocal.map((action) => action)}</>
    );
  }
  return onClickItem ? (
    <>
      {actions.map((action) => (
        <InvisibleButton
          key={action.key}
          type="button"
          onClick={() => onClickItem()}
        >
          {action}
        </InvisibleButton>
      ))}
    </>
  ) : (
    <>{actions.map((action) => action)}</>
  );
};

export default ActionList;
