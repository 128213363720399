import { STButton, STText } from "StaffTrack";
import { useTranslation } from "react-i18next";
import FormHeader from "Templates/Headers/PanelHeaders/FormHeader";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import { FC } from "react";

type Props = {
  type: string | null;
};
const ResetPasswordChangedForm: FC<Props> = ({ type }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="panel panel-header">
          <FormHeader
            headerTitle={
              type === "forget"
                ? t("login.changePassword")
                : t("login.setResetPassword")
            }
          />
        </div>
        <div className="panel-body">
          <div style={{ width: "20rem", overflow: "hidden" }}>
            <div className={"text-center my-2 flex flex-column"}>
              <STText
                label={t("login.resetPasswordChanged1")}
                variant={"plainText"}
                labelCapitalized={false}
              />
              <STText
                label={t("login.resetPasswordChanged2")}
                variant={"plainText"}
                labelCapitalized={false}
              />
            </div>

            <div className="flex justify-content-end mt-4">
              <STButton
                className="px-3"
                type="button"
                onClick={() => navigate(paths.login)}
              >
                <span>{t("login.ok")}</span>
              </STButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordChangedForm;
