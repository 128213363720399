import { include } from "named-urls";

export default {
  associatePaths: include("/associate", {
    associateSearchAndList: include("searchAndList", {
      search: "search",
      list: "list",
    }),
    main: include("main", {
      root: "",
      associateEdit: "edit",
      history: "history",
    }),
    caarMain: include("caarMain", {
      root: "",
      ticklerList: "ticklerList",
      addEditTickler: "addEditTickler",
      caarSearchAssociate: "caarSearchAssociate",
      caarSearchAssociateList: "caarSearchAssociateList",
    }),
    recruitingAndSetup: include("recruitingAndSetup", {
      root: "",
      editDocuments: "editDocuments",
    }),
    payroll: include("payroll", {
      main: "",
    }),
    timesheet: include("timesheet", {
      main: "",
    }),
  }),
};
