import { include } from "named-urls";

export default {
  resources: include("/resources", {
    importList: include("importList", {
      weeks: "weeks",
      list: "list",
      details: "details",
    }),
    myServiceTeam: "myServiceTeam",
    vendorForms: "vendorForms",
    vendorContactInfo: "vendorContactInfo",
    otherOfferings: "otherOfferings",
    recruitingServices: "recruitingServices",
  }),
};
