import { STButton, STText } from "StaffTrack";
import { useTranslation } from "react-i18next";
import FormHeader from "Templates/Headers/PanelHeaders/FormHeader";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import { useEffect } from "react";
import { useLogoutUser } from "Api/Hooks/Auth";

const ChangePasswordChangedForm = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { mutate: logout } = useLogoutUser(false);

  useEffect(() => {
    //Logout user since the password was changed
    logout();
  }, []);

  return (
    <>
      <div>
        <div className="panel panel-header">
          <FormHeader headerTitle={t("login.changePassword")} />
        </div>
        <div className="panel-body">
          <div style={{ width: "20rem", overflow: "hidden" }}>
            <div className={"text-center my-2 flex flex-column"}>
              <STText
                label={t("login.changePasswordDone1")}
                variant={"plainText"}
                labelCapitalized={false}
              />
              <STText
                label={t("login.changePasswordDone2")}
                variant={"plainText"}
                labelCapitalized={false}
              />
            </div>

            <div className="flex justify-content-end mt-4">
              <STButton
                className="px-3"
                type="button"
                onClick={() => navigate(paths.login)}
              >
                <span>{t("login.ok")}</span>
              </STButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordChangedForm;
