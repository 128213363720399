import { InputText, InputTextProps } from "primereact/inputtext";
import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import { Password } from "primereact/password";

const StyledPassword = styled(Password)`
  border: 0;
  border-bottom: 2px solid var(--gray-180);
  border-radius: 0;
  box-shadow: none;

  div {
    width: 100%;

    input {
      width: 100%;
      padding-right: 30px;
    }
  }
`;
const StyledInputText = styled(InputText)`
  border: 0;
  border-bottom: 2px solid var(--gray-180);
  border-radius: 0;
  box-shadow: none;
`;
const FloatField: FC<
  Omit<InputTextProps, "size"> & { label: string; value: string | undefined }
> = ({ id, label, type, ...rest }) => (
  <span className="p-float-label">
    {type === "password" ? (
      <StyledPassword
        feedback={false}
        inputStyle={{ border: 0, borderLeft: 0, borderRight: 0 }}
        toggleMask
        className="loginField w-full border-left-0 border-bottom-1"
        {...rest}
        id={id}
        name={id}
      />
    ) : (
      <StyledInputText
        className="loginField w-full border-left-0 border-bottom-1"
        {...rest}
        id={id}
      />
    )}
    <label htmlFor={id}>{label}</label>
  </span>
);

export default FloatField;
