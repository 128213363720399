import { STBreadCrumb } from "Types/Breadcrumb";

export const commonBreadcrumbs: STBreadCrumb[] = [
  {
    title: "Home",
    url: "/",
    external: false,
    translationResource: "breadcrumbs:common.home",
  },
];
