import { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Container } from "Templates/Headers/PanelHeaders/commonStyles";
import { CloseContainer, Title } from "./styles";

type Props = {
  title: string;
  onClose: () => void;
};

const TitleHeader: FC<Props> = ({ title, onClose }) => (
  <>
    <Container>
      <Title>{title}</Title>
      <CloseContainer>
        <AiOutlineClose size={20} onClick={() => onClose()} />
      </CloseContainer>
    </Container>
  </>
);
export default TitleHeader;
