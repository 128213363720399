import { include } from "named-urls";

export default {
  orderWorkers: include("/orderWorkers", {
    main: "",
    batchEdit: "batchEdit",
    viewRequisition: "viewRequisition",
    addRequisition: "addRequisition",
  }),
};
