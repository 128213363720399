import styled from "styled-components";

export const HeaderRightContainer = styled.div`
  flex-basis: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  input {
    width: 8.3125rem;
    height: 2.5rem;
  }
  form {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const MenuButton = styled.button`
  background-color: var(--blue-660); !important;
  height: 2.75rem;
  width: 2.75rem;
  border: none;
`;
