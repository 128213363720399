import Action from "Assets/actions.svg";
import { STButton } from "StaffTrack";
import { useState } from "react";
import { DropDownAnchor } from "StaffTrack";
import { STDropdown } from "StaffTrack";
import ActionList from "Components/ActionList";
import { useMobile } from "App/media";
import { ActionIconContainer } from "./styles";
import { useAppContext } from "Context/AppContext";

const ActionButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useMobile();
  const { actions } = useAppContext();

  if (!actions || !actions.length) return <></>;
  return (
    <div className="relative">
      <DropDownAnchor
        top="6rem"
        right="0"
        width="15rem"
        parentId="appBody"
        position="fixed"
      >
        <STDropdown
          padding="0.8rem"
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
          dropDownId="actionButton"
        >
          <ActionList onClickItem={() => setIsOpen(false)} />
        </STDropdown>
      </DropDownAnchor>
      <STButton
        className="ui-icon-message font-semibold text-xs"
        onClick={() => setIsOpen(!isOpen)}
        id="actionButton"
        height="2.25rem"
      >
        <ActionIconContainer>
          <Action />
        </ActionIconContainer>
        {!isMobile && "ACTIONS"}
      </STButton>
    </div>
  );
};

export default ActionButton;
