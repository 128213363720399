import { PageContainer } from "Pages/styles";
import STForm from "Components/STForm";
import { SecuredResources, SystemOperations } from "Constants/Security";
import { ReadOnlyField, STSelect, STText } from "StaffTrack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useActionsAndBreadCrumbs } from "./useActionsAndBreadCrumbs";
import { useMobile } from "App/media";
import useAssociateParametersLogic from "./useAssociateParametersLogic";

const AssociateReportParametersMode = () => {
  const { t } = useTranslation("standardReports");
  const [status, setStatus] = useState<string>("-1");
  const { isMobile } = useMobile();
  const { formattedStatuses } = useAssociateParametersLogic();

  useActionsAndBreadCrumbs(status);

  return (
    <PageContainer>
      <STForm
        title={t("associateReport")}
        securityInfo={{
          resource: SecuredResources.MYSTN2_ASSOCIATE_REPORT,
          systemOperation: SystemOperations.VIEW_CONTENT,
        }}
        width="35rem"
      >
        <div className="pb-3">
          <STText
            label={t("reportParameters")}
            labelCapitalized={false}
            variant={"section"}
          />
        </div>
        <div className="responsive-row">
          <ReadOnlyField
            id="1"
            label={t("format")}
            value="Summary"
            labelPosition={isMobile ? "top" : "left-center"}
            labelWidth="10rem"
          />
        </div>
        <div className="responsive-row">
          <STSelect
            label={t("status")}
            items={formattedStatuses}
            labelPosition={isMobile ? "top" : "left-center"}
            labelWidth="10rem"
            width={isMobile ? "100%" : "15rem"}
            onValueChange={(id) => setStatus(id)}
          />
        </div>
      </STForm>
    </PageContainer>
  );
};

export default AssociateReportParametersMode;
