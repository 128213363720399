import { useStApi } from "Api/Common/UseStApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyURL } from "StaffTrack";
import { Endpoints } from "./endpoints";
import { APIResult } from "Types/Model";
import { PanelDTO } from "Api/StaffTrack/Dashboard/Models/panelDto";
import { DashboardAttendanceByDayDTO } from "Api/StaffTrack/Dashboard/Models/dashboardAttendanceByDayDto";
import { DashboardAttendanceByWeekDTO } from "Api/StaffTrack/Dashboard/Models/dashboardAttendanceByWeekDto";
import { DashboardActiveAgingDTO } from "Api/StaffTrack/Dashboard/Models/dashboardActiveAgingDto";
import { DatatrackWeeklyHoursDTO } from "Api/StaffTrack/Dashboard/Models/datatrackWeeklyHoursDto";
import { DatatrackWeeklyTurnoverDTO } from "Api/StaffTrack/Dashboard/Models/datatrackWeeklyTurnoverDto";
import { ClientDashboardWorkForceDTO } from "Api/StaffTrack/Dashboard/Models/clientDashboardWorkForceDto";

export const useAddDefaultPanels = () => {
  const { post } = useStApi({ throwExceptionOnStatus: [400] });
  const query = useQueryClient();
  return useMutation(
    (userId: number) => {
      return post(
        stringifyURL(Endpoints.api.dashboard.root, {
          queryStringParams: {},
          pathParams: { userId },
        }),
        ""
      );
    },
    { onSuccess: () => query.invalidateQueries(["UserDashboard"]) }
  );
};

export const useDailyAttendanceChart = () => {
  const { get } = useStApi({
    throwExceptionOnStatus: [400],
    excludeKey: "dailyAttendanceChart",
  });
  return useQuery<APIResult<DashboardAttendanceByDayDTO>>(
    ["dailyAttendanceChart"],
    () => {
      return get(Endpoints.api.client.dashboard.dailyAttendanceChart);
    }
  );
};

export const useWeeklyAttendanceChart = () => {
  const { get } = useStApi({
    throwExceptionOnStatus: [400],
    excludeKey: "weeklyAttendanceChart",
  });
  return useQuery<APIResult<DashboardAttendanceByWeekDTO[]>>(
    ["weeklyAttendanceChart"],
    () => {
      return get(Endpoints.api.client.dashboard.weeklyAttendanceChart);
    }
  );
};

export const useActiveAgingChart = () => {
  const { get } = useStApi({
    throwExceptionOnStatus: [400],
    excludeKey: "ActiveAgingChart",
  });
  return useQuery<APIResult<DashboardActiveAgingDTO[]>>(
    ["ActiveAgingChart"],
    () => {
      return get(Endpoints.api.client.dashboard.activeAgingChart);
    }
  );
};

export const useWeeklyHoursChart = () => {
  const { get } = useStApi({
    throwExceptionOnStatus: [400],
    excludeKey: "weeklyHoursChart",
  });
  return useQuery<APIResult<DatatrackWeeklyHoursDTO[]>>(
    ["weeklyHoursChart"],
    () => {
      return get(Endpoints.api.client.dashboard.weeklyHoursChart);
    }
  );
};

export const useWeeklyTurnoverChart = () => {
  const { get } = useStApi({
    throwExceptionOnStatus: [400],
    excludeKey: "weeklyTurnoverChart",
  });
  return useQuery<APIResult<DatatrackWeeklyTurnoverDTO[]>>(
    ["weeklyTurnoverChart"],
    () => {
      return get(Endpoints.api.client.dashboard.weeklyTurnoverChart);
    }
  );
};

type UpdatePanelsProps = {
  panels: PanelDTO[];
  userId: number;
};
export const useUpdatePanels = () => {
  const { put } = useStApi();
  const query = useQueryClient();
  return useMutation(
    ({ panels, userId }: UpdatePanelsProps) => {
      return put(
        stringifyURL(Endpoints.api.dashboard.root, {
          queryStringParams: {},
          pathParams: { userId },
        }),
        JSON.stringify(panels)
      );
    },
    { onSuccess: () => query.invalidateQueries(["UserDashboard"]) }
  );
};

export const useGetPanels = (userId: number | undefined) => {
  const { get } = useStApi();
  return useQuery<APIResult<PanelDTO[]>>(
    ["UserDashboard", userId],
    () => {
      return get(
        stringifyURL(Endpoints.api.dashboard.root, {
          queryStringParams: {},
          pathParams: { userId },
        })
      );
    },
    { enabled: userId !== undefined }
  );
};
export const useGetAssociateValues = () => {
  const { get } = useStApi();
  return useQuery<APIResult<ClientDashboardWorkForceDTO>>(
    ["DashBoardClientAssociates"],
    () => {
      return get(
        stringifyURL(Endpoints.api.client.dashboard.associateInformation, {})
      );
    }
  );
};
