import React from "react";
import { paths } from "Routes/Paths";
import { RouteDefinition } from "Routes/Definitions/index";

const ImportList = React.lazy(
  () =>
    import("Pages/Resources/ImportList" /*  webpackChunkName: "ImportList" */)
);

const ImportResultList = React.lazy(
  () =>
    import(
      "Pages/Resources/ImportList/ResultList" /*  webpackChunkName: "ResultList" */
    )
);

const ImportDetails = React.lazy(
  () =>
    import(
      "Pages/Resources/ImportList/Details" /*  webpackChunkName: "Details" */
    )
);

const MyServiceTeam = React.lazy(
  () =>
    import(
      "Pages/Resources/MyServiceTeam" /*  webpackChunkName: "MyServiceTeam" */
    )
);
const VendorForms = React.lazy(
  () => import("Pages/Resources/VendorForms" /*  webpackChunkName: "Details" */)
);

const VendorContactInfo = React.lazy(
  () =>
    import(
      "Pages/Resources/VendorContactInfo" /*  webpackChunkName: "VendorContactInfo" */
    )
);

const OtherOfferings = React.lazy(
  () =>
    import(
      "Pages/Resources/OtherOfferings" /* webpackPrefetch: true , webpackChunkName: "OtherOfferings" */
    )
);

const RecruitingServices = React.lazy(
  () =>
    import(
      "Pages/Resources/RecruitingServices" /* webpackPrefetch: true , webpackChunkName: "RecruitingServices" */
    )
);

const { resources } = paths;

const resourcesDefinitions: RouteDefinition[] = [
  {
    component: ImportList,
    path: resources.importList.weeks,
    views: ["vendor"],
  },
  {
    component: ImportResultList,
    path: resources.importList.list,
    views: ["vendor"],
  },
  {
    component: ImportDetails,
    path: resources.importList.details,
    views: ["vendor"],
  },
  {
    component: MyServiceTeam,
    path: resources.myServiceTeam,
    views: ["vendor", "client"],
  },
  {
    component: VendorForms,
    path: resources.vendorForms,
    views: ["vendor"],
  },
  {
    component: VendorContactInfo,
    path: resources.vendorContactInfo,
    views: ["vendor"],
  },
  {
    component: OtherOfferings,
    path: resources.otherOfferings,
    views: ["client"],
  },
  {
    component: RecruitingServices,
    path: resources.recruitingServices,
    views: ["client"],
  },
];

export default resourcesDefinitions;
