import { include } from "named-urls";

export const dashboardPaths = {
  dashboard: include("/dashboard", {
    main: "",
    addAssociate: "addAssociate",
    addHours: include("addHours", {
      main: "main",
      scheduleMissingTime: "scheduleMissingTime",
    }),
    takeAction: "takeAction",
    helpLibrary: "helpLibrary",
    approveHours: "approveHours",
    workLocationCodes: "workLocationCodes",
    actionRequests: "actionRequests",
    invoices: include("invoices", {
      list: "list",
      view: "view",
    }),
  }),
};
