import { useAppContext } from "Context/AppContext";
import { STDropdown, stringifyURL } from "StaffTrack";
import { Item, ItemText } from "./styles";
import { useLogoutUser, useUserBasicInfo } from "Api/Hooks/Auth";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import { useChangeCurrentOffice } from "Api/Hooks/User";
import { toastDefaults } from "Constants/Toast";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ConditionalRender from "Pages/AnaliticsAndReports/StandardReports/Shared/Components/ConditionalRender";
import { FaSignOutAlt } from "react-icons/fa";
import { useAtom } from "jotai";
import { manuallyLoggedOutAtom, viewModeAtom } from "Context/AppGlobalState";

const Profile = () => {
  const { mutate: logout } = useLogoutUser(true);
  const {
    setIsProfileOpen,
    isProfileOpen,
    currentOffice,
    toggleDashboardInChangeMode,
  } = useAppContext();
  const { mutateAsync: changeCurrentOffice } = useChangeCurrentOffice(true);
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { data: baseUserInfo } = useUserBasicInfo();
  const [, setManuallyLoggedOut] = useAtom(manuallyLoggedOutAtom);
  const [viewMode] = useAtom(viewModeAtom);

  return (
    <STDropdown
      onClose={() => setIsProfileOpen(false)}
      isOpen={isProfileOpen}
      dropDownId="btnShowProfile"
      padding="0.8rem"
      rowGap="0.4rem"
    >
      <ConditionalRender shouldRender={viewMode === "client"}>
        <Item
          onClick={() => {
            toggleDashboardInChangeMode();
            setIsProfileOpen(false);
          }}
        >
          Configure Home Screen
        </Item>
      </ConditionalRender>
      <Item
        onClick={() => {
          setIsProfileOpen(false);
          navigate(paths.resources.myServiceTeam);
        }}
      >
        {t("profile.ServiceTeam")}
      </Item>
      <Item
        onClick={() => {
          if (!currentOffice?.officeId) return;
          setIsProfileOpen(false);
          changeCurrentOffice(currentOffice.officeId.toString()).then(() => {
            toast.info(
              `You have successfully changed your default office to ${currentOffice?.officeNo}.`,
              toastDefaults
            );
          });
        }}
      >
        {t("profile.SetCurrentOfficeAsDefault")}
      </Item>
      <ConditionalRender
        shouldRender={
          baseUserInfo?.mySTNUserType === "vendor" ||
          baseUserInfo?.mySTNUserType === "client"
        }
      >
        <Item
          onClick={() => {
            setIsProfileOpen(false);
            navigate(
              stringifyURL(paths.login, {
                queryStringParams: { action: "changePassword" },
              })
            );
          }}
        >
          {t("profile.ChangePassword")}
        </Item>
      </ConditionalRender>
      <Item
        onClick={() => {
          setIsProfileOpen(false);
          navigate(paths.dashboard.helpLibrary);
        }}
      >
        {t("profile.HelpHowDoI")}
      </Item>
      <Item
        onClick={() => {
          setManuallyLoggedOut(true);
          logout();
        }}
        className="gap-1"
      >
        <FaSignOutAlt />
        {t("profile.Logout")}
      </Item>
      <ItemText>{baseUserInfo?.email}</ItemText>
      <ItemText>
        {t("common:profile.LastLogin")} {baseUserInfo?.lastLoginDate}
      </ItemText>
    </STDropdown>
  );
};

export default Profile;
