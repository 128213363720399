import { useMemo, useState } from "react";

import { useAppContext } from "Context/AppContext";
import ForgotPasswordForm from "Pages/Login/Forms/ForgotPassword/forgotPasswordForm";
import { Formik } from "formik";
import { useForgotPassword } from "Api/Hooks/Auth";
import { useTranslation } from "react-i18next";
import ForgotPasswordSentForm from "Pages/Login/Forms/ForgotPassword/forgotPasswordSentForm";
import { isValidEmail } from "StaffTrack";

const ForgotPassword = () => {
  const { setIsProfileOpen } = useAppContext();
  const { mutateAsync: forgotPassword, isLoading } = useForgotPassword();
  const [errorMessage, setErrorMessage] = useState("");
  const [requestSent, setRequestSent] = useState(false);
  const { t } = useTranslation("common");

  const form = useMemo(() => {
    if (requestSent) return <ForgotPasswordSentForm />;
    else
      return (
        <ForgotPasswordForm errorMessage={errorMessage} isLoading={isLoading} />
      );
  }, [requestSent, errorMessage, isLoading]);

  return (
    <>
      <Formik
        onSubmit={(values) => {
          setIsProfileOpen(false);
          setErrorMessage("");
          if (!values?.email) return setErrorMessage(t("login.emailRequired"));
          if (!isValidEmail(values.email)) {
            setErrorMessage(t("login.invalidEmail"));
            return;
          }
          forgotPassword({
            email: values?.email || "",
            lastPageUrl: window.location.href || "",
          })
            .then(() => {
              setRequestSent(true);
            })
            .catch(() => {
              setErrorMessage(t("login.generalError"));
            });
        }}
        initialValues={{ email: "" }}
      >
        {form}
      </Formik>
    </>
  );
};

export default ForgotPassword;
