import { paths } from "Routes/Paths";
import { ErrorMessage, STButton, stringifyURL } from "StaffTrack";
import { FC } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LoginInformation } from "Api/StaffTrack/Auth/Models/loginInformation";
import FormHeader from "Templates/Headers/PanelHeaders/FormHeader";
import { Tooltip } from "primereact/tooltip";
import FloatField from "Pages/Login/Components/FloatField";

const STLink = styled(Link)`
  text-decoration: none;
  font-size: 0.875rem;
  text-wrap: none;
  flex-wrap: nowrap;
  width: fit-content;
  color: ${({ theme }) => theme.primaryColor};
`;

type Props = {
  isLoading: boolean;
  errorMessage: string;
};
const LoginForm: FC<Props> = ({ isLoading, errorMessage }) => {
  const { values, handleChange, handleSubmit, handleBlur } =
    useFormikContext<LoginInformation>();
  const { t } = useTranslation("common");

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="panel panel-header">
          <FormHeader headerTitle={t("login.myStafftrackLogin")} />
        </div>
        {errorMessage && <ErrorMessage invertColors message={errorMessage} />}
        <div className="panel-body">
          <div
            style={{ width: "20rem", overflow: "hidden" }}
            className="flex flex-column"
          >
            <div className="pt-4">
              <FloatField
                id="email"
                label={t("login.UsernameLabel")}
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                width="30rem"
              />
            </div>
            <div className="pt-4">
              <FloatField
                id="password"
                label={t("login.PasswordLabel")}
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.password}
              />
            </div>
            <STButton className="mt-4 px-5" type="submit" disabled={isLoading}>
              <span className={`${isLoading ? `pr-1` : ``}`}>
                {t("login:login")}
              </span>
              {isLoading && <span className="pi pi-spin pi-spinner" />}
            </STButton>
            <div className="pt-4 pb-2 flex justify-content-between">
              <STLink
                to={stringifyURL(paths.login, {
                  queryStringParams: { action: "forgotPassword" },
                })}
              >
                {t("login.forgetPassword")}
              </STLink>
              <Tooltip
                target=".new-user-link"
                position="top"
                style={{ width: "15rem" }}
              />
              <STLink
                to={paths.login}
                className="new-user-link"
                data-pr-tooltip={t("login.NewUserTooltip")}
              >
                {t("login.newUser")}
              </STLink>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
