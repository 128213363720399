import { SubMenu } from "Components/Menu/MenuItems/SubMenu";
import { I18nProps } from "Types/I18n";
import { useAppContext } from "Context/AppContext";
import { MenuChevron, MenuGroupItem } from "./styles";
import useResourceSecurity from "Lib/UserSecurity";
import { SystemOperations } from "Constants/Security";
import { FC } from "react";
import { useAtom } from "jotai";
import { menuExtraInfoAtom } from "Context/AppGlobalState";

export type Submenu = {
  id: number;
  label: string;
  path: string;
  external?: string;
  resourceId?: number;
  isAssociateMenu?: boolean;
  queryStringParams?: {};
  params?: object;
};

export type MenuGroup = {
  id: number;
  icon: JSX.Element;
  label: string;
  resourceId?: number;
  dataTestid?: string;
  submenus: Submenu[];
};

interface Props extends I18nProps {
  menuGroup: MenuGroup;
}

const MenuLabel = ({ label }) => {
  return <span style={{ fontSize: "0.85rem" }}>{label}</span>;
};

export const MenuGroup: FC<Props> = ({ menuGroup, t }) => {
  const { toggleMenuGroupOpen, openedMenuGroups } = useAppContext();
  const [menuExtraInfo] = useAtom(menuExtraInfoAtom);
  const element = menuExtraInfo.find((item) => item.id === menuGroup.id);
  const hasAccess = useResourceSecurity(
    menuGroup.resourceId,
    SystemOperations.VIEW_CONTENT
  );
  if (!hasAccess) return null;

  return (
    <li key={menuGroup.id}>
      <MenuGroupItem
        type="button"
        onClick={() => toggleMenuGroupOpen(menuGroup.id, true)}
        className="flex justify-content-between align-items-center"
      >
        <div className="flex align-items-center">
          {menuGroup.icon}
          <MenuLabel label={t(`menu:${menuGroup.label}`)} />
          {element?.element}
        </div>
        <MenuChevron
          className="flex flex-column"
          size={15}
          active={
            openedMenuGroups.find((id) => id === menuGroup.id) !== undefined
          }
        />
      </MenuGroupItem>
      <SubMenu
        showSubmenu={
          openedMenuGroups.find((id) => id === menuGroup.id) !== undefined
        }
        submenuList={menuGroup.submenus}
        t={t}
      />
    </li>
  );
};
