import styled from "styled-components";
import { Link } from "react-router-dom";

export const SubMenuList = styled.ul`
  overflow: hidden;
  transition: all 0.3s;
`;

type SubMenuItemProps = {
  selected: boolean;
};

export const SubMenuItem = styled(Link)<SubMenuItemProps>`
  transition: all 0.3s;
  text-decoration: none;
  color: black;
  &:hover {
    background-color: ${({ theme }) => theme.surfaceHover};
  }
  font-size: 0.875rem;
  padding: 0.3rem 0.5rem 0.3rem 2rem;
  background-color: ${({ selected, theme }) =>
    selected ? theme.gray200 : `white`};
`;

export const SubMenuItemExternal = styled.a<SubMenuItemProps>`
  transition: all 0.3s;
  text-decoration: none;
  color: black;
  &:hover {
    background-color: ${({ theme }) => theme.surfaceHover};
  }
  font-size: 0.875rem;
  padding: 0.3rem 0.5rem 0.3rem 2rem;
  background-color: ${({ selected, theme }) =>
    selected ? theme.gray200 : `white`};
`;
