import { useNavigate } from "react-router-dom";
import { FC, useState } from "react";

import { useAppContext } from "Context/AppContext";
import LoginForm from "Pages/Login/Forms/Login/loginForm";
import { Formik } from "formik";

import { UserInformation } from "Api/StaffTrack/Auth/Models/userInformation";
import { stringifyURL } from "StaffTrack";
import { paths } from "Routes/Paths";
import { useAuthUser } from "Api/Hooks/Auth";
import useAuth from "Lib/UseAuth";
import { useAtom } from "jotai";
import {
  manuallyLoggedOutAtom,
  serviceTeamAtom,
  viewModeAtom,
} from "Context/AppGlobalState";
import { useTranslation } from "react-i18next";
import "Pages/Login/styles.css";
import { loginValues } from "Pages/Login/Logic/loginFormValues";
import useAlertModal from "Components/Modal/Logic/useAlertModal";
import { useQueryParams } from "Lib/UseQueryParams";
import useRedirectAfterLogin from "Pages/Login/Logic/useRedirectAfterLogin";

type LoginProps = {
  isCleaning: boolean;
};

const Login: FC<LoginProps> = ({ isCleaning }) => {
  const { setIsProfileOpen } = useAppContext();
  const { mutateAsync: mutateAuthUser, isLoading } = useAuthUser();
  const [errorMessage, setErrorMessage] = useState("");
  const { loginUser } = useAuth();
  const [, setViewMode] = useAtom(viewModeAtom);
  const [, setServiceTeam] = useAtom(serviceTeamAtom);
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { showSuccess } = useAlertModal();
  const { getUnsafeNullValue } = useQueryParams();
  const redirect = getUnsafeNullValue("redirect");
  const { redirectAfterLogin } = useRedirectAfterLogin(redirect);
  const [, setManuallyLoggedOut] = useAtom(manuallyLoggedOutAtom);

  return (
    <>
      <Formik
        onSubmit={(values, { resetForm }) => {
          if (values.email === "") {
            setErrorMessage(t("login.emailRequired"));
            return;
          }
          if (values.password === "") {
            setErrorMessage(t("login.passwordRequired"));
            return;
          }
          setIsProfileOpen(false);
          mutateAuthUser({
            email: values?.email || "",
            password: values?.password || "",
          })
            .then((response) => {
              const userInfo: UserInformation = response.data;
              setManuallyLoggedOut(false);
              loginUser();

              // Default view for service team is 'vendor'
              setViewMode(userInfo.userType === "vendor" ? "vendor" : "client");
              setServiceTeam(userInfo.userType === "serviceTeam");

              let continueToHome = true;
              if (
                userInfo.userType === "vendor" &&
                userInfo.daysToExpirePassword !== undefined
              ) {
                if (
                  userInfo.daysToExpirePassword <= 10 &&
                  userInfo.daysToExpirePassword >= 1
                ) {
                  showSuccess(
                    t("common:login.passwordExpire1") +
                      userInfo.daysToExpirePassword +
                      t("common:login.passwordExpire2"),
                    t("common:login.resetPasswordRequest")
                  );
                } else if (userInfo.daysToExpirePassword <= 0) {
                  navigate(
                    stringifyURL(paths.login, {
                      queryStringParams: {
                        action: "changePassword",
                      },
                    })
                  );
                  continueToHome = false;
                }
              }
              if (continueToHome) {
                redirectAfterLogin(userInfo);
              }
            })
            .catch((error) => {
              if (error.status === 500)
                setErrorMessage(t("login.generalError"));
              else if (error.status === 412)
                setErrorMessage(
                  error.message.replaceAll("[", "").replaceAll("]", "")
                );
              else if (error.message.includes("Password expired"))
                setErrorMessage(t("login.passwordExpired"));
              else if (error.message.includes("New mystn access restricted"))
                window.location.href = "/mystn/mystn_login.do";
              else if (
                error.message.includes("No security questions found for user")
              )
                navigate(
                  stringifyURL(paths.login, {
                    queryStringParams: {
                      action: "setQuestions",
                    },
                  })
                );
              else {
                setErrorMessage(t("login.incorrectPwdMsg"));
                resetForm();
              }
            });
        }}
        initialValues={loginValues}
      >
        <LoginForm
          errorMessage={errorMessage}
          isLoading={isLoading || isCleaning}
        />
      </Formik>
    </>
  );
};

export default Login;
