import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { currentAssociateAtom } from "Context/AppGlobalState";

const usePathChange = () => {
  const location = useLocation();
  const [, setCurrentAssociate] = useAtom(currentAssociateAtom);

  useEffect(() => {
    if (!location.pathname.includes("/associate")) {
      setCurrentAssociate(undefined);
    }
  }, [location.pathname]);
};

export default usePathChange;
