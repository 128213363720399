import { GenericMessageDialog, STButton, STText } from "StaffTrack";
import { FC, useEffect, useState } from "react";
import { useInterval } from "react-use";
import { useGetSystemSetting } from "Api/Hooks/Common";
import { paths } from "Routes/Paths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  onExit: () => void;
};
const TimeOutModal: FC<Props> = ({ onExit }) => {
  const { data: systemSetting } = useGetSystemSetting(
    "ST_SESSION_TIMEOUT_REDIRECT"
  );
  const { t } = useTranslation("sessionTimeOut");
  const [redirectToLogin, setRedirectToLogin] = useState(
    Number.MAX_SAFE_INTEGER
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!systemSetting) return;
    const redirect = parseInt(systemSetting.data?.value || "0");
    setRedirectToLogin(redirect);
  }, [systemSetting]);

  useInterval(() => {
    if (redirectToLogin === 1) {
      navigate(paths.login);
    }
    setRedirectToLogin(redirectToLogin - 1);
  }, 60000);

  if (redirectToLogin === Number.MAX_SAFE_INTEGER) return null;
  return (
    <GenericMessageDialog
      visible
      message={
        <div className="flex flex-column p-2">
          <h1 className="pb-5">{t("timedOutMsg", { redirectToLogin })}</h1>
          <STText label={t("loggedOutMsg")} variant="label" />
        </div>
      }
      buttons={<STButton onClick={onExit}>{t("btnMsg")}</STButton>}
    />
  );
};

export default TimeOutModal;
