import { FC } from "react";
import {
  ButtonContainer,
  Container,
  Title,
} from "Templates/Headers/PanelHeaders/commonStyles";
import ActionList from "Components/ActionList";

type Props = {
  headerTitle: string | JSX.Element;
};
const FormHeader: FC<Props> = ({ headerTitle }) => {
  const CalculatedTitle = () =>
    typeof headerTitle === "string" ? (
      <Title>{headerTitle}</Title>
    ) : (
      <Title className="w-full">{headerTitle}</Title>
    );
  return (
    <Container>
      <CalculatedTitle />
      <ButtonContainer>
        <ActionList local />
      </ButtonContainer>
    </Container>
  );
};

export default FormHeader;
