import { useTranslation } from "react-i18next";
import { STPageBreadCrumb, useAppContext } from "Context/AppContext";
import { useQueryParams } from "Lib/UseQueryParams";
import { stringifyURL } from "StaffTrack";
import { Container, Ul, Li, Chevron, ActiveScreen } from "./styles";
import { StyledExternalLink, StyledLink } from "Components/Links/styles";
import { useMobile } from "App/media";
import { Link } from "react-router-dom";

const STBreadCrumb = () => {
  const { breadcrumbs } = useAppContext();
  const { t } = useTranslation("ToolBar");
  const { getUnsafeNullValue } = useQueryParams();
  const { isMobile, isTablet } = useMobile();

  const getName = (item: STPageBreadCrumb) => {
    return (
      t(item.translationResource) +
      (item.additionalText ? " " + item.additionalText : "")
    );
  };

  if (breadcrumbs.length === 0) return null;
  if (isMobile || isTablet) {
    const elipsisPosition = breadcrumbs.length - 2;
    const textPosition = breadcrumbs.length - 1;
    return (
      <Container>
        <ul className="ul-responsive">
          {breadcrumbs.length > 1 && (
            <li className="li-responsive">
              <Link
                className="responsive-link"
                to={stringifyURL(breadcrumbs[elipsisPosition].url || "", {
                  queryStringParams: {
                    ...breadcrumbs[elipsisPosition].params,
                    officeId: getUnsafeNullValue("officeId"),
                  },
                })}
              >
                <span className="responsive-link">...</span>
              </Link>
            </li>
          )}
          <Chevron size={12} />
          <li className="li-responsive">
            <ActiveScreen>{getName(breadcrumbs[textPosition])}</ActiveScreen>
          </li>
        </ul>
      </Container>
    );
  } else {
    return (
      <Container>
        <Ul>
          {breadcrumbs.slice(0, -1).map((item, index) => (
            <Li key={item.title}>
              {index > 0 && <Chevron size={12} />}
              {item.external ? (
                <StyledExternalLink
                  href={stringifyURL(item.url || "", {
                    queryStringParams: {
                      ...item.params,
                      officeId: getUnsafeNullValue("officeId"),
                    },
                  })}
                >
                  {getName(item)}
                </StyledExternalLink>
              ) : (
                <StyledLink
                  to={stringifyURL(item.url || "", {
                    queryStringParams: item.params,
                  })}
                >
                  {getName(item)}
                </StyledLink>
              )}
            </Li>
          ))}
          <Li>
            <Chevron size={12} />
          </Li>
        </Ul>
        <span>
          {breadcrumbs.slice(-1).map((item, index) => (
            <Li key={item.title}>
              {index > 0 && <Chevron size={12} />}
              <ActiveScreen>{getName(item)}</ActiveScreen>
            </Li>
          ))}
        </span>
      </Container>
    );
  }
};

export default STBreadCrumb;
