import styled from "styled-components";
import { mobileQuery } from "App/media";

export const Menu = styled.div`
  position: absolute;
  top: 7rem;

  @media ${mobileQuery} {
    top: 4rem;
  }
`;
