import { STBreadCrumb } from "Types/Breadcrumb";
import { paths } from "Routes/Paths";

export const reportsBreadcrumbs: STBreadCrumb[] = [
  {
    title: "AnalyticsReporting",
    url: paths.standardReportsPaths.main,
    external: false,
    translationResource: "breadcrumbs:reports.analyticReports",
  },
  {
    title: "StandardReports",
    url: paths.standardReportsPaths.main,
    external: false,
    translationResource: "breadcrumbs:reports.standardReports",
    subMenuId: 14,
  },
  {
    title: "AssociateReports",
    url: paths.standardReportsPaths.vendorReports.associateReports.parameters,
    external: false,
    translationResource: "breadcrumbs:reports.associateReport",
    subMenuId: 14,
  },
  {
    title: "ViewAssociateReport",
    url: paths.standardReportsPaths.vendorReports.associateReports.view,
    external: false,
    translationResource: "breadcrumbs:reports.viewAssociateReport",
    subMenuId: 14,
  },
  {
    title: "RosterReports",
    url: paths.standardReportsPaths.vendorReports.rosterReports.parameters,
    external: false,
    translationResource: "breadcrumbs:reports.rosterReport",
    subMenuId: 14,
  },
  {
    title: "ClientRosterReports",
    url: paths.standardReportsPaths.clientReports.clientRosterReports
      .parameters,
    external: false,
    translationResource: "breadcrumbs:reports.rosterReport",
    subMenuId: 14,
  },
  {
    title: "ViewRosterReport",
    url: paths.standardReportsPaths.vendorReports.rosterReports.view,
    external: false,
    translationResource: "breadcrumbs:reports.viewRosterReport",
    subMenuId: 14,
  },
  {
    title: "TimeApprovalReports",
    url: paths.standardReportsPaths.vendorReports.timeApprovalReports
      .parameters,
    external: false,
    translationResource: "breadcrumbs:reports.timeApprovalReport",
    subMenuId: 14,
  },
  {
    title: "ViewTimeApprovalReport",
    url: paths.standardReportsPaths.vendorReports.timeApprovalReports.view,
    external: false,
    translationResource: "breadcrumbs:reports.viewTimeApprovalReport",
    subMenuId: 14,
  },
  {
    title: "DocumentAuditReports",
    url: paths.standardReportsPaths.vendorReports.documentAuditReports
      .parameters,
    external: false,
    translationResource: "breadcrumbs:reports.documentAuditReport",
    subMenuId: 14,
  },
  {
    title: "ViewDocumentAuditReport",
    url: paths.standardReportsPaths.vendorReports.documentAuditReports.view,
    external: false,
    translationResource: "breadcrumbs:reports.viewDocumentAuditReport",
    subMenuId: 14,
  },
  {
    title: "ClientDocumentAuditReports",
    url: paths.standardReportsPaths.clientReports.clientDocumentAuditReports
      .parameters,
    external: false,
    translationResource: "breadcrumbs:reports.documentAuditReport",
    subMenuId: 14,
  },
  {
    title: "InvoiceReports",
    url: paths.standardReportsPaths.vendorReports.invoiceReports.parameters,
    external: false,
    translationResource: "breadcrumbs:reports.invoiceReport",
    subMenuId: 14,
  },
  {
    title: "ViewInvoiceReport",
    url: paths.standardReportsPaths.vendorReports.invoiceReports.view,
    external: false,
    translationResource: "breadcrumbs:reports.viewInvoiceReport",
    subMenuId: 14,
  },
  {
    title: "HoursReport",
    url: paths.standardReportsPaths.vendorReports.hoursReport.parameters,
    external: false,
    translationResource: "breadcrumbs:reports.hoursReport",
    subMenuId: 14,
  },
  {
    title: "HoursReportClient",
    url: paths.standardReportsPaths.clientReports.hoursReportClient.main,
    external: false,
    translationResource: "breadcrumbs:reports.hoursReport",
    subMenuId: 14,
  },
  {
    title: "HoursReportList",
    url: paths.standardReportsPaths.vendorReports.hoursReport.parameters,
    external: false,
    translationResource: "breadcrumbs:reports.hoursReportList",
    subMenuId: 14,
  },
  {
    title: "CustomReports",
    url: paths.customReportsPaths.root,
    external: false,
    translationResource: "breadcrumbs:reports.customReports",
    subMenuId: 15,
  },
  {
    title: "CustomDashboards",
    url: paths.customDashboardsPaths.root,
    external: false,
    translationResource: "breadcrumbs:reports.customDashboards",
    subMenuId: 16,
  },
];
