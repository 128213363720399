import Profile from "Components/Profile";
import { useAppContext } from "Context/AppContext";
import ContactHelpModal from "Components/ContactHelpModal";
import ActionButton from "Components/ActionButton";
import { DropDownAnchor } from "StaffTrack";
import { MdHelpOutline } from "react-icons/md";
import { cloneElement, FC, useEffect } from "react";
import {
  Container,
  RightContainer,
  BreadCrumbMenuIconContainer,
} from "./styles";
import STBreadCrumb from "./STBreadCrumb";
import useMenuItems from "Constants/Menu";
import { STButton } from "StaffTrack";
import { useAtom } from "jotai";
import { viewModeAtom } from "Context/AppGlobalState";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import { SecuredResources, SystemOperations } from "Constants/Security";
import SecuredAction from "Templates/Actions/SecuredAction";
import { panelsAtom } from "Pages/Dashboard/Main/State";
import { useUpdatePanels } from "Api/Hooks/Dashboard/DashboardMain";
import { useUserBasicInfo } from "Api/Hooks/Auth";
import { IoMdSave } from "react-icons/io";
const MenuIcon: FC<{ subMenuId: number | undefined }> = ({ subMenuId }) => {
  const menu = useMenuItems();
  if (!subMenuId) return null;
  let selectedMenu = (menu || []).find(
    (menuItem) =>
      menuItem.submenus.find((submenu) => submenu.id === subMenuId) !==
      undefined
  );

  //Search in menu level if not found in submenus
  if (!selectedMenu)
    selectedMenu = (menu || []).find((menuItem) => menuItem.id === subMenuId);

  if (selectedMenu) return cloneElement(selectedMenu.icon, { size: "32" });
  return null;
};

const ToolBar = () => {
  const {
    isProfileOpen,
    isHelpModalOpen,
    selectedMenu,
    isMenuOpen,
    isDashboardInChangeMode,
    toggleDashboardInChangeMode,
    actionButtons,
    openedMenuGroups,
    toggleMenuGroupOpen,
    manuallyToggledMenu,
  } = useAppContext();
  const [viewMode] = useAtom(viewModeAtom);
  const navigate = useNavigate();
  const [panels] = useAtom(panelsAtom);
  const { mutateAsync: changePanels } = useUpdatePanels();
  const { data: baseUserInfo } = useUserBasicInfo();
  const menu = useMenuItems();

  useEffect(() => {
    let parentMenu = (menu || []).find(
      (menuItem) =>
        menuItem.submenus.find((submenu) => submenu.id === selectedMenu) !==
        undefined
    );
    if (
      parentMenu &&
      !openedMenuGroups.find((id) => id === parentMenu?.id) &&
      manuallyToggledMenu !== parentMenu?.id
    ) {
      toggleMenuGroupOpen(parentMenu.id, false);
    }
  }, [selectedMenu, openedMenuGroups, manuallyToggledMenu]);

  return (
    <Container id="appToolBar" isMenuOpen={isMenuOpen}>
      {isHelpModalOpen && <ContactHelpModal />}
      <span className="left-container">
        {selectedMenu && (
          <BreadCrumbMenuIconContainer>
            <MenuIcon subMenuId={selectedMenu} />
          </BreadCrumbMenuIconContainer>
        )}
        <STBreadCrumb />
      </span>
      <RightContainer>
        {isProfileOpen && (
          <DropDownAnchor
            top="3rem"
            right="0rem"
            width="15rem"
            parentId="fullBody"
          >
            <Profile />
          </DropDownAnchor>
        )}
        {isDashboardInChangeMode && (
          <SecuredAction
            securityInfo={{
              resource: SecuredResources.MYSTN2_DASHBOARD,
              systemOperation: SystemOperations.EDIT,
            }}
          >
            <STButton
              aria-label="save button"
              severity="success"
              className="p-1 align-items-center px-2"
              type="submit"
              id="saveButtonAction"
              onClick={() => {
                if (!baseUserInfo?.userId) return;
                changePanels({ panels, userId: baseUserInfo.userId }).then(
                  () => {
                    toggleDashboardInChangeMode();
                  }
                );
              }}
              height="2.25rem"
            >
              <span className="flex align-content-center gap-2">
                <IoMdSave size={24} className="m-auto" />
                <span className="font-semibold">Exit</span>
              </span>
            </STButton>
          </SecuredAction>
        )}
        {actionButtons?.map((action, index) => (
          <div key={index}>{action}</div>
        ))}
        <STButton
          icon={<MdHelpOutline size={28} />}
          height="2.25rem"
          width="2.25rem"
          className="border-round"
          onClick={() => navigate(paths.dashboard.helpLibrary)}
        />
        {viewMode === "client" && <ActionButton />}
      </RightContainer>
    </Container>
  );
};
export default ToolBar;
