import { useTranslation } from "react-i18next";
import { FC } from "react";
import { MenuItems } from "./MenuItems";
import { MenuContainer } from "./styles";

type Props = {
  visible?: boolean;
};

const Menu: FC<Props> = ({ visible }) => {
  const { t } = useTranslation();
  return (
    <MenuContainer visible={visible} id="appMenu">
      <MenuItems t={t} />
    </MenuContainer>
  );
};

export default Menu;
