import { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import ConditionalRender from "Pages/AnaliticsAndReports/StandardReports/Shared/Components/ConditionalRender";
import { changePasswordValues } from "Pages/Login/Forms/ChangePassword/Logic/resetPasswordFormValues";
import CurrentPasswordForm from "Pages/Login/Forms/ChangePassword/currentPasswordForm";
import ChangePasswordForm from "Pages/Login/Forms/ChangePassword/changePasswordForm";
import ChangePasswordChangedForm from "Pages/Login/Forms/ChangePassword/changePasswordChangedForm";
import { useChangePassword, useValidatePassword } from "Api/Hooks/User";
import { passwordRegex } from "Pages/Login/Logic/passwordValidation";

const ChangePassword = () => {
  const { mutateAsync: changePassword } = useChangePassword();
  const { mutateAsync: validatePassword, isLoading } = useValidatePassword();
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation("common");
  const [step, setStep] =
    useState<"currentPassword" | "changePassword" | "passwordChanged">(
      "currentPassword"
    );

  return (
    <>
      <Formik
        onSubmit={(values) => {
          setErrorMessage("");

          if (step === "currentPassword") {
            if (!values?.currentPassword)
              setErrorMessage(t("login.passwordIsRequired"));
            else {
              validatePassword({ password: values.currentPassword })
                .then((response) => {
                  if (response?.data) {
                    setStep("changePassword");
                  } else {
                    setErrorMessage(t("login.passwordInvalid"));
                  }
                })
                .catch((error) => {
                  if (error.status === 412) setErrorMessage(error.message);
                  else setErrorMessage(t("login.generalError"));
                });
            }
          } else if (step === "changePassword") {
            if (values.newPassword === "") {
              setErrorMessage(t("login.passwordIsRequired"));
              return;
            }
            if (values.confirmPassword === "") {
              setErrorMessage(t("login.confirmPasswordIsRequired"));
              return;
            }
            if (values.newPassword !== values.confirmPassword) {
              setErrorMessage(t("login.passwordsDoNotMatch"));
              return;
            }
            if (!passwordRegex.test(values.newPassword)) {
              setErrorMessage(t("login.passwordInvalid"));
              return;
            }

            changePassword({ password: values.newPassword })
              .then(() => {
                setStep("passwordChanged");
              })
              .catch((error) => {
                if (error.status === 412) setErrorMessage(error.message);
                else setErrorMessage(t("login.generalError"));
              });
          }
        }}
        initialValues={changePasswordValues}
      >
        <>
          <ConditionalRender shouldRender={step === "currentPassword"}>
            <CurrentPasswordForm
              isLoading={isLoading}
              errorMessage={errorMessage}
            />
          </ConditionalRender>
          <ConditionalRender shouldRender={step === "changePassword"}>
            <ChangePasswordForm
              errorMessage={errorMessage}
              isLoading={isLoading}
            />
          </ConditionalRender>
          <ConditionalRender shouldRender={step === "passwordChanged"}>
            <ChangePasswordChangedForm />
          </ConditionalRender>
        </>
      </Formik>
    </>
  );
};

export default ChangePassword;
