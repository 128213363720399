import React from "react";
import { paths } from "Routes/Paths";
import { RouteDefinition } from "Routes/Definitions/index";

const AddAssociate = React.lazy(
  () =>
    import(
      "Pages/Dashboard/AddAssociate" /*  webpackChunkName: "AddAssociate" */
    )
);

const AddHours = React.lazy(
  () =>
    import(
      "Pages/Dashboard/AddHours/AddHoursMainPage" /*  webpackChunkName: "AddHoursMainPage" */
    )
);

const ScheduleMissingTime = React.lazy(
  () =>
    import(
      "Pages/Dashboard/AddHours/ScheduleMissingTime" /*  webpackChunkName: "ScheduleMissingTime" */
    )
);

const TakeAction = React.lazy(
  () =>
    import("Pages/Dashboard/TakeAction" /*  webpackChunkName: "TakeAction" */)
);

const HelpLibrary = React.lazy(
  () =>
    import("Pages/Dashboard/HelpLibrary" /*  webpackChunkName: "HelpLibrary" */)
);

const ApproveHours = React.lazy(
  () =>
    import(
      "Pages/Dashboard/ApproveHours" /*  webpackChunkName: "ApproveHours" */
    )
);

const WorkLocationCodes = React.lazy(
  () =>
    import(
      "Pages/Dashboard/WorkLocationCodes" /* webpackPrefetch: true , webpackChunkName: "WorkLocationCodes" */
    )
);

const DashboardCaarMain = React.lazy(
  () =>
    import(
      "Pages/Associate/AssociateMain/CaarMain/DashboardTicklerList" /*  webpackChunkName: "dashboardTicklerList" */
    )
);

const Invoices = React.lazy(
  () => import("Pages/Dashboard/Invoices" /*  webpackChunkName: "Invoices" */)
);

const ViewInvoice = React.lazy(
  () =>
    import(
      "Pages/Dashboard/Invoices/View" /*  webpackChunkName: "ViewInvoice" */
    )
);

const { dashboard } = paths;

const commonDefinitions: RouteDefinition[] = [
  {
    component: AddAssociate,
    path: dashboard.addAssociate,
    views: ["vendor"],
  },
  {
    component: AddHours,
    path: dashboard.addHours.main,
    views: ["vendor"],
  },
  {
    component: ScheduleMissingTime,
    path: dashboard.addHours.scheduleMissingTime,
    views: ["vendor"],
  },
  {
    component: TakeAction,
    path: dashboard.takeAction,
    views: ["vendor"],
  },
  {
    component: HelpLibrary,
    path: dashboard.helpLibrary,
    views: ["client", "vendor"],
  },
  {
    component: ApproveHours,
    path: dashboard.approveHours,
    views: ["client"],
  },
  {
    component: WorkLocationCodes,
    path: dashboard.workLocationCodes,
    views: ["client"],
  },
  {
    component: DashboardCaarMain,
    path: dashboard.actionRequests,
    views: ["client", "vendor"],
  },
  {
    component: Invoices,
    path: dashboard.invoices.list,
    views: ["client"],
  },
  {
    component: ViewInvoice,
    path: dashboard.invoices.view,
    views: ["client"],
  },
];

export default commonDefinitions;
