import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStApi } from "Api/Common/UseStApi";
import { APIResult } from "Types/Model";
import { cacheQuery } from "Api/Common/Query";
import { Endpoints } from "Api/Hooks/User/endpoints";
import { OfficeDTO } from "Api/StaffTrack/Auth/Models/officeDto";
import { stringifyURL } from "StaffTrack";
import { PasswordRequest } from "Api/StaffTrack/Users/Models/passwordRequest";

const Queries = {
  ChangeDefaultOffice: "ChangeDefaultOffice",
  OfficeOptions: "OfficeOptions",
  UserLogout: "UserLogout",
  UserOffices: "UserOffices",
  CurrentOffice: "CurrentOffice",
};

export const useUserOffices = () => {
  const queryClient = useQueryClient();
  const { get } = useStApi();
  return useQuery<APIResult<OfficeDTO[]>>(
    [Queries.UserOffices],
    () => get<APIResult<OfficeDTO[]>>(`${Endpoints.api.user.officeList}`),
    {
      ...cacheQuery,
      onSuccess: () => queryClient.invalidateQueries(Queries.OfficeOptions),
    }
  );
};

export const useCurrentOffice = () => {
  const { get } = useStApi();
  return useQuery<APIResult<OfficeDTO>>(
    [Queries.CurrentOffice],
    () => get<APIResult<OfficeDTO>>(`${Endpoints.api.user.currentOffice}`),
    { ...cacheQuery }
  );
};

export const useChangeCurrentOffice = (setAsDefault: boolean) => {
  const { post } = useStApi();
  const queryClient = useQueryClient();
  return useMutation(
    (officeId: string) => {
      return post(
        stringifyURL(Endpoints.api.user.changeOffice, {
          queryStringParams: { officeId, setAsDefault },
        }),
        ""
      );
    },
    { onSuccess: () => queryClient.invalidateQueries() }
  );
};

export const useOfficeOptions = () =>
  useQuery(
    [Queries.OfficeOptions],
    () => () => Promise.resolve({ returnCode: 200, data: {}, notes: "" }),
    { ...cacheQuery }
  );

export const useChangePassword = () => {
  const { post } = useStApi({ throwExceptionOnStatus: [412, 500] });

  return useMutation((passwordRequest: PasswordRequest) => {
    return post<APIResult<boolean>>(
      Endpoints.api.user.changePassword,
      JSON.stringify(passwordRequest)
    );
  });
};

export const useValidatePassword = () => {
  const { post } = useStApi();

  return useMutation((passwordRequest: PasswordRequest) => {
    return post<APIResult<boolean>>(
      Endpoints.api.user.validatePassword,
      JSON.stringify(passwordRequest)
    );
  });
};

export const useGetUserOffices = () => {
  const { get } = useStApi();
  return useMutation<APIResult<OfficeDTO[]>>([Queries.UserOffices], () =>
    get<APIResult<OfficeDTO[]>>(`${Endpoints.api.user.officeList}`)
  );
};
