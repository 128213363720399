import { FC, useEffect, useState } from "react";
import { Formik } from "formik";
import {
  useRandomQuestionsForUser,
  useResetPassword,
  useValidateAnswers,
} from "Api/Hooks/Auth";
import { useTranslation } from "react-i18next";
import {
  ResetPasswordFullForm,
  resetPasswordValues,
} from "Pages/Login/Forms/ResetPassword/Logic/resetPasswordFormValues";
import ResetPasswordForm from "Pages/Login/Forms/ResetPassword/resetPasswordForm";
import { useQueryParams } from "Lib/UseQueryParams";
import ConditionalRender from "Pages/AnaliticsAndReports/StandardReports/Shared/Components/ConditionalRender";
import AddSecurityQuestionsForm from "Pages/Login/Forms/ResetPassword/addSecurityQuestionsForm";
import AnswerSecurityQuestionsForm from "Pages/Login/Forms/ResetPassword/answerSecurityQuestionsForm";
import { ForgetPwdQuestionDTO } from "Api/StaffTrack/Auth/Models/forgetPwdQuestionDto";
import ResetPasswordChangedForm from "Pages/Login/Forms/ResetPassword/resetPasswordChangedForm";
import { passwordRegex } from "Pages/Login/Logic/passwordValidation";
import useAlertModal from "Components/Modal/Logic/useAlertModal";
import { paths } from "Routes/Paths";
import { useNavigate } from "react-router-dom";

type ResetPasswordProps = {
  setShowingQuestions: (value: boolean) => void;
};
const ResetPassword: FC<ResetPasswordProps> = ({ setShowingQuestions }) => {
  const { mutateAsync: resetPassword, isLoading } = useResetPassword();
  const { mutateAsync: validateAnswers } = useValidateAnswers();
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation("common");
  const { getUnsafeNullValue } = useQueryParams();
  const token = getUnsafeNullValue("token");
  const userLoginId = getUnsafeNullValue("id");
  const type = getUnsafeNullValue("type");
  const [questions, setQuestions] = useState<ForgetPwdQuestionDTO[]>([]);
  const navigate = useNavigate();
  const { showAttention } = useAlertModal();
  const [step, setStep] =
    useState<
      | "start"
      | "addQuestions"
      | "answerQuestions"
      | "changePassword"
      | "passwordChanged"
    >("start");
  const { data: securityQuestions } = useRandomQuestionsForUser(
    userLoginId,
    token,
    (error) => {
      showAttention(error.message);
      navigate(paths.login);
    }
  );
  const [firstTimePassword, setFirstTimePassword] = useState(false);

  useEffect(() => {
    if (!securityQuestions) return;
    if (!securityQuestions?.data || !securityQuestions?.data.length) {
      setQuestions([]);
      setStep("changePassword");
      setFirstTimePassword(true);
    } else {
      setQuestions(securityQuestions.data);
      setStep("answerQuestions");
      setFirstTimePassword(false);
    }
  }, [securityQuestions]);

  useEffect(() => {
    setShowingQuestions(step === "addQuestions" || step === "answerQuestions");
  }, [step]);

  const sendResetPasswordRequest = (values: ResetPasswordFullForm) => {
    if (!token || !userLoginId) {
      setErrorMessage(t("login.generalError"));
      return;
    }
    resetPassword({ ...values, token, email: userLoginId })
      .then(() => {
        setStep("passwordChanged");
      })
      .catch((error) => {
        if (error.status === 412) setErrorMessage(error.message);
        else setErrorMessage(t("login.generalError"));
      });
  };

  const validateAnswersRequest = (values: ResetPasswordFullForm) => {
    if (!token || !userLoginId) {
      setErrorMessage(t("login.generalError"));
      return;
    }
    validateAnswers({ ...values, token, email: userLoginId })
      .then((response) => {
        if (response.data) setStep("changePassword");
        else setErrorMessage(t("login.answersNotCorrect"));
      })
      .catch((error) => {
        if (error.status === 412) setErrorMessage(error.message);
        else setErrorMessage(t("login.generalError"));
      });
  };

  return (
    <>
      <Formik
        onSubmit={(values) => {
          setErrorMessage("");

          if (step === "addQuestions") {
            if (
              !values?.questionId1 ||
              !values?.questionId2 ||
              !values?.questionId3
            )
              setErrorMessage(t("login.allQuestionsRequired"));
            else if (!values?.answer1 || !values?.answer2 || !values?.answer3)
              setErrorMessage(t("login.allAnswersRequired"));
            else {
              //Ok to continue, send request
              sendResetPasswordRequest(values);
            }
          } else if (step === "answerQuestions") {
            if (
              !values?.questionId1 ||
              !values?.questionId2 ||
              !values?.answer1 ||
              !values?.answer2
            )
              setErrorMessage(t("login.allAnswersRequired"));
            else validateAnswersRequest(values);
          } else if (step === "changePassword") {
            if (values.password === "") {
              setErrorMessage(t("login.passwordIsRequired"));
              return;
            }
            if (values.confirmPassword === "") {
              setErrorMessage(t("login.confirmPasswordIsRequired"));
              return;
            }
            if (values.password !== values.confirmPassword) {
              setErrorMessage(t("login.passwordsDoNotMatch"));
              return;
            }
            if (!passwordRegex.test(values.password)) {
              setErrorMessage(t("login.passwordInvalid"));
              return;
            }

            if (firstTimePassword) {
              //Redirect to add questions form
              setStep("addQuestions");
            } else {
              //Send reset password request
              sendResetPasswordRequest(values);
            }
          }
        }}
        initialValues={resetPasswordValues}
      >
        <>
          <ConditionalRender shouldRender={step === "addQuestions"}>
            <AddSecurityQuestionsForm
              errorMessage={errorMessage}
              isLoading={isLoading}
            />
          </ConditionalRender>
          <ConditionalRender shouldRender={step === "answerQuestions"}>
            <AnswerSecurityQuestionsForm
              errorMessage={errorMessage}
              isLoading={isLoading}
              questions={questions}
            />
          </ConditionalRender>
          <ConditionalRender shouldRender={step === "changePassword"}>
            <ResetPasswordForm
              errorMessage={errorMessage}
              isLoading={isLoading}
              type={type}
            />
          </ConditionalRender>
          <ConditionalRender shouldRender={step === "passwordChanged"}>
            <ResetPasswordChangedForm type={type} />
          </ConditionalRender>
        </>
      </Formik>
    </>
  );
};

export default ResetPassword;
