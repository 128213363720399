import { useAppContext } from "Context/AppContext";
import { useInterval } from "react-use";
import { differenceInMinutes } from "date-fns";
import { useEffect, useState } from "react";
import TimeOutModal from "Components/SessionTimeOut/timeOutModal";
import { useGetSystemSetting, usePingApi } from "Api/Hooks/Common";

const SessionTimeOut = () => {
  const { lastApiCall } = useAppContext();
  const { data: systemSetting } = useGetSystemSetting(
    "ST_SESSION_TIMEOUT_WARNING"
  );
  useGetSystemSetting("ST_SESSION_TIMEOUT_REDIRECT");
  const { mutateAsync: pingAPI } = usePingApi();
  const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);

  useEffect(() => {
    setShowTimeoutMessage(false);
  }, [lastApiCall]);

  useInterval(() => {
    if (!systemSetting) return;
    const warningTime = parseInt(systemSetting.data.value || "0");

    if (
      lastApiCall != undefined &&
      differenceInMinutes(new Date(), lastApiCall) >= warningTime &&
      !showTimeoutMessage
    ) {
      setShowTimeoutMessage(true);
    }
  }, 30000);

  return showTimeoutMessage ? (
    <TimeOutModal
      onExit={() => {
        pingAPI().then(() => {
          setShowTimeoutMessage(false);
        });
      }}
    />
  ) : null;
};

export default SessionTimeOut;
