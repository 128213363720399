import styled from "styled-components";

export const ErrorWrapper = styled.div`
  height: 100%;
  opacity: 100%;
  background-color: white;
`;

export const ErrorContainer = styled.div`
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorName = styled.div`
  padding: 0;
  margin: 0;
`;

export const ErrorDescription = styled.div`
  padding: 0;
  margin: 0;
`;

export const ErrorButton = styled.div`
  padding: 0;
  margin: 0;
`;
