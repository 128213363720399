import { I18nProps } from "Types/I18n";
import useMenuItems from "Constants/Menu";
import { Container } from "./styles";
import { MenuGroup } from "./MenuGroup";

interface Props extends I18nProps {}

export const MenuItems: React.FC<Props> = ({ t }) => {
  const menu = useMenuItems();
  if (!menu) return null;
  return (
    <Container>
      <ul>
        {menu.map((m) => (
          <MenuGroup key={m.id} menuGroup={m} t={t} />
        ))}
      </ul>
    </Container>
  );
};
