import { useAssociateStatuses } from "Api/Hooks/AnaliticsAndReports/StandardReports/AssociateReport";
import { addEmptyState, NameValuePair } from "StaffTrack";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { viewModeAtom } from "Context/AppGlobalState";

const useAssociateParametersLogic = () => {
  const [viewMode] = useAtom(viewModeAtom);
  const { data: statuses } = useAssociateStatuses(viewMode);
  const [formattedStatuses, setFormattedStatuses] = useState<NameValuePair[]>(
    []
  );
  const selectStatuses =
    statuses !== undefined && statuses.data !== undefined
      ? statuses.data.filter((item) => item.id !== 5)
      : [];
  useEffect(() => {
    const newStatuses = addEmptyState(
      selectStatuses.map((item) => ({
        name: (item.id || "-1").toString(),
        text: item.description || "",
      })),
      "All",
      "-1"
    );
    setFormattedStatuses(newStatuses);
  }, [selectStatuses]);

  return { formattedStatuses };
};
export default useAssociateParametersLogic;
