import { FC, useEffect, useMemo } from "react";
import FormHeader from "Templates/Headers/PanelHeaders/FormHeader";
import { ErrorMessage } from "StaffTrack";
import styled from "styled-components";
import { useAppContext } from "Context/AppContext";
import { SecuredResource } from "Types/Security";
import useResourceSecurity from "Lib/UserSecurity";
import { useAtom } from "jotai";
import { formModeAtom } from "Context/AppGlobalState";
//import { useMedia } from "react-use";
import { useMobile } from "App/media";

const Form = styled.form`
  width: 100%;
`;

type Props = {
  title: string | JSX.Element;
  securityInfo: SecuredResource;
  id?: string;
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined;
  errorMessage?: string;
  fullWidthMode?: boolean;
  width?: string;
  children: React.ReactNode;
};
const STForm: FC<Props> = ({
  title,
  onSubmit,
  children,
  id,
  errorMessage,
  fullWidthMode = false,
  securityInfo,
  width,
}) => {
  const { isMenuOpen, apiErrorMessage, setApiErrorMessage } = useAppContext();
  const { isMobile } = useMobile();
  const widthValue = useMemo(
    () => (isMenuOpen && !fullWidthMode ? "calc(100vw - 16)" : "100%"),
    [fullWidthMode, isMenuOpen]
  );
  const [, setFormMode] = useAtom(formModeAtom);
  const hasAccess = useResourceSecurity(
    securityInfo.resource || 0,
    securityInfo.systemOperation || 0
  );

  useEffect(() => {
    return () => setApiErrorMessage("");
  }, []);

  useEffect(() => {
    setFormMode("none");
  }, []);

  function calculateWidth() {
    let result = width ? width : widthValue;
    if (isMobile) {
      return "100%";
    }
    return result;
  }

  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (onSubmit) onSubmit(e);
        }}
        id={id}
        style={{ width: widthValue }}
      >
        <div className="overflow-hidden">
          {(errorMessage || apiErrorMessage) && (
            <ErrorMessage
              width={width ? width : widthValue}
              message={errorMessage || apiErrorMessage}
            />
          )}
          <div style={{ width: calculateWidth() }}>
            {hasAccess ? (
              <div className="flex flex-column">
                <div className="panel panel-header">
                  <FormHeader headerTitle={title} />
                </div>
                <div className="panel panel-body">{children}</div>
              </div>
            ) : (
              <div className="flex justify-content-center">
                <span className="p-5 font-bold">
                  You don't have access to this content.
                </span>
              </div>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default STForm;
