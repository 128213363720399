import styled from "styled-components";

export const HeaderContainer = styled.div`
  background-color: var(--blue-660);
  position: sticky;
  width: 100%;
  height: 3.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
