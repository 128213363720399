import { IoChevronDownSharp } from "react-icons/io5";
import { useAppContext } from "Context/AppContext";
import { Button, SelectedItem } from "./styles";
import { useMobile } from "App/media";

type Props = {
  onClick(): void;
};

export const OfficeButton = ({ onClick }: Props) => {
  const { currentOffice, isMenuOpen } = useAppContext();
  const { isMobile } = useMobile();

  return (
    <Button
      className={`border-noround h-4rem ${
        isMobile && !isMenuOpen ? "w-5rem" : ""
      }`}
      role="button"
      onClick={onClick}
    >
      <SelectedItem>{currentOffice?.officeName}</SelectedItem>
      {isMobile && isMenuOpen && (
        <IoChevronDownSharp
          className="flex flex-column"
          style={{ marginLeft: "-1.2rem" }}
          size={14}
        />
      )}
    </Button>
  );
};
