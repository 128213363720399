import { createContext, ReactNode, useContext, useMemo, useState } from "react";

export type LocalActionsContextProps = {
  actionsLocal: JSX.Element[];
  setActionsLocal: (actions: JSX.Element[]) => void;
};

const LocalActionsContext = createContext<LocalActionsContextProps>({
  actionsLocal: [],
  setActionsLocal: () => {},
});
export const useLocalContext = () => useContext(LocalActionsContext);
export const LocalActionsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [actionsLocal, setActionsLocal] = useState<JSX.Element[]>([]);

  const value = useMemo(
    () => ({
      actionsLocal,
      setActionsLocal,
    }),
    [actionsLocal]
  );

  return (
    <LocalActionsContext.Provider value={value}>
      {children}
    </LocalActionsContext.Provider>
  );
};
