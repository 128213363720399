import { StrictMode } from "react";
import { render } from "react-dom";
import App from "App";
// Warning: Never change the order of these CSSs
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "react-toastify/dist/ReactToastify.css";
import "@trueblue1/stafftrack_react_components/dist/main.css";
import "Styles/mySTNStyles.css";
const root = document.createElement("div");
document.body.appendChild(root);
render(
  <StrictMode>
    <App />
  </StrictMode>,
  root
);
