import { commonBreadcrumbs } from "Constants/Breadcrumb/common";
import { STBreadCrumb } from "Types/Breadcrumb";
import { dashBoardBreadcrumbs } from "Constants/Breadcrumb/dashboard";
import { orderWorkersBreadcrumbs } from "Constants/Breadcrumb/orderWorkers";
import { reportsBreadcrumbs } from "Constants/Breadcrumb/reports";
import { associateBreadcrumbs } from "Constants/Breadcrumb/associate";
import { resourcesBreadcrumbs } from "Constants/Breadcrumb/resources";
import { ordersBreadcrumbs } from "Constants/Breadcrumb/orders";

export const breadCrumbs: STBreadCrumb[] = [
  ...commonBreadcrumbs,
  ...dashBoardBreadcrumbs,
  ...orderWorkersBreadcrumbs,
  ...reportsBreadcrumbs,
  ...associateBreadcrumbs,
  ...resourcesBreadcrumbs,
  ...ordersBreadcrumbs,
];
