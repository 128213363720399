import { FC } from "react";

type Props = {
  shouldRender: boolean;
  children: React.ReactNode;
};

const ConditionalRender: FC<Props> = ({ shouldRender, children }) =>
  shouldRender ? <>{children}</> : null;

export default ConditionalRender;
