import { include } from "named-urls";

export const Endpoints = {
  api: include("api", {
    auth: include("auth", {
      login: "login",
      test: "test",
      logout: "logout",
      basicUserInfo: "basicUserInfo",
      forgotPassword: "forgotPassword",
      resetPassword: "resetPassword",
      randomPwdQuestionsForUser: "randomPwdQuestionsForUser",
      validateAnswers: "validateAnswers",
      forgetPwdQuestions: "forgetPwdQuestions",
      saveSecurityQuestions: "saveSecurityQuestions",
    }),
  }),
};
