import { HeaderContainer } from "./styles";
import { HeaderLeft } from "./HeaderLeft";
import { HeaderRight } from "./HeaderRight";

export const Header = () => (
  <HeaderContainer className="shadow-4" id="appHeader">
    <HeaderLeft />
    <HeaderRight />
  </HeaderContainer>
);
