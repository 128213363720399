import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.surface350};
  flex-basis: 100%;
  overflow-y: auto;
`;

export const PencilContainer = styled.button`
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  width: 20px;
  height: 24px;
`;

export const ColumnCenteredContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
