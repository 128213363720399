import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useSaveSecurityQuestions, useUserBasicInfo } from "Api/Hooks/Auth";
import { useTranslation } from "react-i18next";
import { resetPasswordValues } from "Pages/Login/Forms/ResetPassword/Logic/resetPasswordFormValues";
import AddSecurityQuestionsForm from "Pages/Login/Forms/ResetPassword/addSecurityQuestionsForm";
import { paths } from "Routes/Paths";
import { useNavigate } from "react-router-dom";
import { stringifyURL } from "StaffTrack";
import { useAtom } from "jotai";
import { serviceTeamAtom, viewModeAtom } from "Context/AppGlobalState";

const SetSecurityQuestions = () => {
  const { mutateAsync: saveQuestions, isLoading } = useSaveSecurityQuestions();
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { data: baseUserInfo } = useUserBasicInfo();
  const [, setViewMode] = useAtom(viewModeAtom);
  const [, setServiceTeam] = useAtom(serviceTeamAtom);

  useEffect(() => {
    if (baseUserInfo) {
      setViewMode(
        baseUserInfo.mySTNUserType === "vendor" ? "vendor" : "client"
      );
      setServiceTeam(baseUserInfo.mySTNUserType === "serviceTeam");
    }
  }, [baseUserInfo]);

  return (
    <>
      <Formik
        onSubmit={(values) => {
          setErrorMessage("");

          if (
            !values?.questionId1 ||
            !values?.questionId2 ||
            !values?.questionId3
          )
            setErrorMessage(t("login.allQuestionsRequired"));
          else if (!values?.answer1 || !values?.answer2 || !values?.answer3)
            setErrorMessage(t("login.allAnswersRequired"));
          else {
            saveQuestions(values)
              .then(() => {
                navigate(
                  stringifyURL(paths.home, {
                    queryStringParams: {},
                  })
                );
              })
              .catch((error) => {
                if (error.status === 412) setErrorMessage(error.message);
                else setErrorMessage(t("login.generalError"));
              });
          }
        }}
        initialValues={resetPasswordValues}
      >
        <>
          <AddSecurityQuestionsForm
            errorMessage={errorMessage}
            isLoading={isLoading}
          />
        </>
      </Formik>
    </>
  );
};

export default SetSecurityQuestions;
