import { createBrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import { STSpinner } from "StaffTrack";
import privateRoutes, { publicRoutes } from "./Definitions";
import { RouterProvider, useRouteError } from "react-router";
import Layout from "Templates/Layout";
import { createRouteElementFromReactType } from "Routes/Logic/routeUtil";
import { LayoutType } from "Routes/Definitions/LayoutType";
import ErrorFallback, { ErrorFallbackProps } from "Components/ErrorFallBack";
import ErrorUnknown from "Pages/Error/Unknown";
import { UI_BASE_PATH } from "Constants/Environment";

function ErrorBoundary() {
  let error = useRouteError();
  // eslint-disable-next-line no-console
  console.info(error);
  if (status in (error as Object)) {
    return <ErrorUnknown />;
  }
  return <ErrorFallback {...(error as ErrorFallbackProps)} />;
}
export default function STRoutes() {
  const routes = createBrowserRouter(
    [
      {
        path: "/",
        element: <Layout />,
        errorElement: <ErrorBoundary />,
        children: privateRoutes
          .filter(
            (privateRoute) =>
              privateRoute.layoutType === undefined ||
              privateRoute.layoutType === LayoutType.ST_DESKTOP
          )
          .map((route) => createRouteElementFromReactType(route, false)),
      },
      ...privateRoutes
        .filter(
          (privateRoute) => privateRoute.layoutType === LayoutType.FRAGMENT
        )
        .map((route) => createRouteElementFromReactType(route, false)),
      ...publicRoutes.map((route) =>
        createRouteElementFromReactType(route, true)
      ),
    ],
    { basename: UI_BASE_PATH }
  );
  return (
    <Suspense fallback={<STSpinner visible />}>
      <RouterProvider router={routes} />
    </Suspense>
  );
}
