import { useRef, useState } from "react";
import { useUserOffices } from "Api/Hooks/User";
import { useAppContext } from "Context/AppContext";
import useClickAway from "Lib/UseClickOutside";
import { Container } from "./styles";
import { OfficeButton } from "./OfficeButton";
import { OfficeList } from "./OfficeList";
import { useAtom } from "jotai";
import { filterValueAtom } from "./Atom";
import { useMobile } from "App/media";

export const OfficeSelection = () => {
  const [officeSelection, setOfficeSelection] = useState<boolean>(false);
  const { data: officeList } = useUserOffices();
  const { currentOffice, isMenuOpen, setIsMenuOpen } = useAppContext();
  const [, setFilterValue] = useAtom(filterValueAtom);
  const { isMobile } = useMobile();

  const containerRef = useRef(null);
  useClickAway(containerRef, () => {
    setOfficeSelection(false);
    setFilterValue("");
  });
  if (currentOffice === undefined) return <div></div>;
  return (
    <>
      <Container className="shadow-2" ref={containerRef}>
        <OfficeButton
          onClick={() => {
            if (!isMenuOpen) return;
            setOfficeSelection(!officeSelection);
          }}
        />
        <OfficeList
          data={officeList?.data}
          showList={officeSelection}
          onClick={() => {
            if (isMobile) setIsMenuOpen(false);
            setOfficeSelection(false);
          }}
        />
      </Container>
    </>
  );
};
