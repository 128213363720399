import styled from "styled-components";

export const CloseContainer = styled.div`
  * {
    cursor: pointer;
    color: var(--gray-500);
    :hover {
      color: var(--gray-800);
    }
  }
`;
export const Title = styled.div`
  font-size: 1.3125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.gray860};
`;
