import { STBreadCrumb } from "Types/Breadcrumb";
import { paths } from "Routes/Paths";

export const resourcesBreadcrumbs: STBreadCrumb[] = [
  {
    title: "Resources",
    url: "/",
    external: false,
    translationResource: "breadcrumbs:resources.resources",
    menuId: 5,
  },
  {
    title: "ImportList",
    url: paths.resources.importList.weeks,
    external: false,
    translationResource: "breadcrumbs:resources.importList",
    subMenuId: 33,
  },
  {
    title: "importResults",
    url: paths.resources.importList.details,
    external: false,
    translationResource: "breadcrumbs:resources.importResults",
    subMenuId: 33,
  },
  {
    title: "myServiceTeam",
    url: paths.resources.myServiceTeam,
    external: false,
    translationResource: "breadcrumbs:resources.myServiceTeam",
    subMenuId: 19,
  },
  {
    title: "VendorForms",
    url: paths.resources.vendorForms,
    external: false,
    translationResource: "breadcrumbs:resources.vendorForms",
    subMenuId: 32,
  },
  {
    title: "VendorContactInfo",
    url: paths.resources.vendorContactInfo,
    external: false,
    translationResource: "breadcrumbs:resources.vendorContactInfo",
    subMenuId: 31,
  },
  {
    title: "OtherOfferings",
    url: paths.resources.otherOfferings,
    external: false,
    translationResource: "breadcrumbs:resources.otherOfferings",
    subMenuId: 21,
  },
  {
    title: "RecruitingServices",
    url: paths.resources.recruitingServices,
    external: false,
    translationResource: "breadcrumbs:resources.recruitingServices",
    subMenuId: 20,
  },
];
