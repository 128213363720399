import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa";

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 0.5rem;
  width: 30rem;
`;
export const Li = styled.li`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 0.2rem;
  a:not(:first-child) {
    padding-left: 0.2rem;
  }
`;

export const Ul = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 50rem;
`;

export const Chevron = styled(FaChevronRight)`
  color: ${({ theme }) => theme.gray400};
`;

export const ActiveScreen = styled.span`
  font-size: 1.3125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.blue850};
  margin-top: -0.3rem;
  padding-left: 0;
  width: fit-content;
`;
