import { include } from "named-urls";

export const Endpoints = {
  api: include("api", {
    client: include("client", {
      dashboard: include("dashboard", {
        associateInformation: "associateInformation",
        dailyAttendanceChart: "dailyAttendanceChart",
        weeklyAttendanceChart: "weeklyAttendanceChart",
        weeklyHoursChart: "weeklyHoursChart",
        weeklyTurnoverChart: "weeklyTurnoverChart",
        activeAgingChart: "activeAgingChart",
      }),
    }),
    dashboard: include("dashboard", {
      root: ":userId",
    }),
  }),
};
