import React from "react";
import commonDefinitions from "Routes/Definitions/Common/definitions";
import dashboardDefinitions from "Routes/Definitions/Dashboard/definitions";
import analyticAndReportsDefinition from "Routes/Definitions/AnalyticsAndReports/definitions";
import associateDefinition from "Routes/Definitions/Associate/definitions";
import resourcesDefinitions from "Routes/Definitions/Resources/definitions";
import ordersDefinition from "Routes/Definitions/Orders/definitions";
import orderWorkersDefinition from "Routes/Definitions/OrderWorkers/definitions";
import { RouteObject } from "react-router";
import { LayoutType } from "Routes/Definitions/LayoutType";
import Login from "Pages/Login";
import { publicPaths } from "Routes/Paths";
import { ViewTypes } from "Context/AppGlobalState";

export type RouteDefinition = RouteObject & {
  component?: React.ComponentType;
  layoutType?: LayoutType;
  views: Exclude<ViewTypes, undefined>[];
};

const PrivateRoutes: RouteDefinition[] = [
  ...commonDefinitions,
  ...dashboardDefinitions,
  ...analyticAndReportsDefinition,
  ...associateDefinition,
  ...resourcesDefinitions,
  ...ordersDefinition,
  ...orderWorkersDefinition,
];

export const publicRoutes: RouteDefinition[] = [
  {
    component: Login,
    path: publicPaths.login,
    views: ["vendor", "client"],
  },
];

export default PrivateRoutes;
