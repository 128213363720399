import { ErrorMessage, STButton, STText } from "StaffTrack";
import { FC } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import FormHeader from "Templates/Headers/PanelHeaders/FormHeader";
import { paths } from "Routes/Paths";
import { useNavigate } from "react-router-dom";
import { ResetPasswordFullForm } from "Pages/Login/Forms/ResetPassword/Logic/resetPasswordFormValues";
import FloatField from "Pages/Login/Components/FloatField";

type Props = {
  isLoading: boolean;
  errorMessage: string;
  type: string | null;
};
const ResetPasswordForm: FC<Props> = ({ isLoading, errorMessage, type }) => {
  const { values, handleChange, handleSubmit, handleBlur } =
    useFormikContext<ResetPasswordFullForm>();
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="panel panel-header">
          <FormHeader
            headerTitle={
              type === "forget"
                ? t("login.changePassword")
                : t("login.setResetPassword")
            }
          />
        </div>
        {errorMessage && <ErrorMessage invertColors message={errorMessage} />}
        <div className="panel-body">
          <STText
            label={t("login.passwordDisclaimer")}
            variant="plainText"
            labelCapitalized={false}
          />
          <div style={{ width: "20rem", overflow: "hidden" }}>
            <div className="mt-4 pt-1">
              <FloatField
                id="password"
                label={t("login.PasswordLabel")}
                value={values?.password}
                onChange={handleChange}
                onBlur={handleBlur}
                width="30rem"
                type="password"
              />
            </div>
            <div className="mt-4 pt-1">
              <FloatField
                id="confirmPassword"
                label={t("login.confirmPasswordLabel")}
                value={values?.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                width="30rem"
                type="password"
              />
            </div>
            <div className="flex justify-content-between mt-4">
              <STButton className="px-4" type="submit" disabled={isLoading}>
                <span className={`${isLoading ? `pr-1` : ``}`}>
                  {t("login:submit")}
                </span>
                {isLoading && <span className="pi pi-spin pi-spinner" />}
              </STButton>
              <STButton
                secondary
                className="px-4"
                type="button"
                disabled={isLoading}
                onClick={() => navigate(paths.login)}
              >
                <span>{t("login.cancel")}</span>
              </STButton>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ResetPasswordForm;
