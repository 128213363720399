import { CancelAction, SimpleButtonAction, stringifyURL } from "StaffTrack";
import { useLocalContext } from "Context/LocalActionsContext";
import { useAppContext } from "Context/AppContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SecuredAction from "Templates/Actions/SecuredAction";
import { SecuredResources, SystemOperations } from "Constants/Security";
import { paths } from "Routes/Paths";
import { useTranslation } from "react-i18next";
import useAssociateParametersLogic from "./useAssociateParametersLogic";

export const useActionsAndBreadCrumbs = (status: string) => {
  const { setActionsLocal } = useLocalContext();
  const { setBreadcrumbsFromTitleList } = useAppContext();
  const { associateReports } = paths.standardReportsPaths.vendorReports;
  const { main } = paths.standardReportsPaths;
  const { t } = useTranslation("standardReports");
  const { formattedStatuses } = useAssociateParametersLogic();
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbsFromTitleList([
      "AnalyticsReporting",
      "StandardReports",
      "AssociateReports",
    ]);
    return () => setBreadcrumbsFromTitleList([]);
  }, []);
  useEffect(() => {
    setActionsLocal([
      <SecuredAction
        securityInfo={{
          resource: SecuredResources.MYSTN2_ASSOCIATE_REPORT,
          systemOperation: SystemOperations.VIEW_CONTENT,
        }}
      >
        <SimpleButtonAction
          label={t("view")}
          style={{
            backgroundColor: "white",
            borderColor: "var(--primary-color)",
            color: "var(--primary-color)",
            height: "2.25rem",
            textTransform: "uppercase",
          }}
          onActionExecuted={() => {
            const calculatedStatus =
              status === "-1"
                ? formattedStatuses
                    .filter((item) => item.name !== "-1")
                    .map((item) => item.name)
                    .join(",")
                : status;
            navigate(
              stringifyURL(associateReports.view, {
                queryStringParams: { status: calculatedStatus },
              })
            );
          }}
        />
      </SecuredAction>,
      <CancelAction url={main} />,
    ]);
    return () => setActionsLocal([]);
  }, [status, formattedStatuses]);
};
