const baseCrystalPath = "/servlet/CrystalReportExportServlet";
export default {
  externalPaths: {
    mystnServletPath: `${baseCrystalPath}/:reportName?officeId=:officeId&userReportId=:reportId&getReportFromDB=true`,
    stnServletPath: baseCrystalPath,
    crystalReport: {
      hoursReportPdfPath: `${baseCrystalPath}/OrdersReport.pdf?officeId=:officeId&userReportId=:reportId&getReportFromDB=true`,
      hoursReportExcelPath: `${baseCrystalPath}/OrdersReport.csv?officeId=:officeId&userReportId=:reportId&getReportFromDB=true`,
      timeApprovalReport: {
        pdfPath: `${baseCrystalPath}/TimeApproval_Detail_Report.pdf?officeId=:officeId&userReportId=:reportId&getReportFromDB=true`,
        excelPath: `${baseCrystalPath}/TimeApproval_Detail_Report.csv?officeId=:officeId&userReportId=:reportId&getReportFromDB=true`,
      },
      customReports: {
        pdfPath: `${baseCrystalPath}/custom_report.pdf?officeId=:officeId&userReportId=:reportId&mystafftrack=true&getReportFromDB=true`,
        excelPath: `${baseCrystalPath}/custom_report.csv?officeId=:officeId&userReportId=:reportId&mystafftrack=true&getReportFromDB=true`,
      },
      documentAuditReport: {
        pdfPath: `${baseCrystalPath}/Document_Audit_Detail_Report.pdf?officeId=:officeId&userReportId=:reportId&mystafftrack=true&getReportFromDB=true`,
        excelPath: `${baseCrystalPath}/Document_Audit_Detail_Report.csv?officeId=:officeId&userReportId=:reportId&mystafftrack=true&getReportFromDB=true`,
      },
      associateReports: {
        excelPath: `${baseCrystalPath}/AssociatePhotosReport.csv?officeId=:officeId&userReportId=:reportId&mystafftrack=true&getReportFromDB=true`,
      },
      rosterReport: {
        pdfPath: `${baseCrystalPath}/Roster_Report.pdf?officeId=:officeId&userReportId=:reportId&mystafftrack=true&getReportFromDB=true`,
        excelPath: `${baseCrystalPath}/Roster_Report.csv?officeId=:officeId&userReportId=:reportId&mystafftrack=true&getReportFromDB=true`,
      },
    },
    powerBiEmbed: "https://app.powerbi.com/reportEmbed",
  },
};
