import React from "react";
import { RouteDefinition } from "Routes/Definitions/index";
import { paths } from "Routes/Paths";

const OrderWorkers = React.lazy(
  () =>
    import(
      "Pages/OrderWorkers" /* webpackPrefetch: true , webpackChunkName: "OrderWorkers" */
    )
);

const BatchEdit = React.lazy(
  () =>
    import(
      "Pages/OrderWorkers/BatchEdit" /* webpackPrefetch: true , webpackChunkName: "BatchEditForm" */
    )
);

const RequisitionView = React.lazy(
  () =>
    import(
      "Pages/OrderWorkers/Requisition/ViewMode/index" /* webpackPrefetch: true , webpackChunkName: "RequisitionViewPage" */
    )
);

const RequisitionParameter = React.lazy(
  () =>
    import(
      "Pages/OrderWorkers/Requisition/ParameterMode/index" /* webpackPrefetch: true , webpackChunkName: "newRequisitionPage" */
    )
);
const { main, batchEdit, viewRequisition, addRequisition } = paths.orderWorkers;

const orderWorkersDefinition: RouteDefinition[] = [
  {
    component: OrderWorkers,
    path: main,
    views: ["client"],
  },
  {
    component: BatchEdit,
    path: batchEdit,
    views: ["client"],
  },
  {
    component: RequisitionView,
    path: viewRequisition,
    views: ["client"],
  },
  {
    component: RequisitionParameter,
    path: addRequisition,
    views: ["client"],
  },
];
export default orderWorkersDefinition;
