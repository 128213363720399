import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3rem 0.5rem;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  border: 0 transparent;
`;

export const SelectedItem = styled.span`
  width: 100%;
  padding: 6px 4px;
  font-size: 0.97rem;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;
