// This is the Jotai global state for the app.
// We decided not to mix with context api to avoid confusion.

import { atom } from "jotai";
import { AssociateCachedInfoDTO } from "Api/StaffTrack/Associate/Models/associateCachedInfoDto";
import { atomWithStorage } from "jotai/utils";
import { ReactElement } from "react";

export const formModeAtom = atom<"add" | "edit" | "none">("none");

export type ViewTypes = "client" | "vendor" | undefined;
export const viewModeAtom = atomWithStorage<ViewTypes>("viewMode", undefined);
export const serviceTeamAtom = atomWithStorage<boolean | undefined>(
  "serviceTeam",
  undefined
);

export type ModalState = {
  id: string;
  title?: string;
  message: string;
  customButtons?: JSX.Element[];
  mode: "attention" | "success";
};

export const modalsAtom = atom<ModalState[]>([]);

export const alertModalModeAtom =
  atom<"attention" | "success" | undefined>(undefined);
alertModalModeAtom.debugLabel = "alertModalModeAtom";

export const currentAssociateAtom =
  atom<AssociateCachedInfoDTO | undefined>(undefined);
export const currentAssociatePageAtom = atom<string | undefined>(undefined);

export const lastRouteAtom = atom<
  { route: string | undefined; query: string | undefined }[]
>([]);

export const manuallyLoggedOutAtom = atom<boolean>(false);

export const menuExtraInfoAtom = atom<
  {
    id: number;
    element: ReactElement;
  }[]
>([]);
