import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppContextProvider } from "Context/AppContext";
import { LocalActionsContextProvider } from "Context/LocalActionsContext";
import STRoutes from "Routes";
import { ToastContainer } from "react-toastify";
import { theme } from "./theme";
import "App/I18n/index";
import { AlertModal } from "Components/Modal";
import { ReactQueryDevtools } from "react-query/devtools";
import { DevTools } from "jotai-devtools";
import { Provider } from "jotai";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      cacheTime: 5000,
      retry: (failureCount) => failureCount < 3,
      useErrorBoundary: true,
    },
    mutations: {},
  },
});
const App = () => (
  <ThemeProvider theme={theme}>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
      theme="colored"
    />
    <Provider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <DevTools />
        <AppContextProvider>
          <LocalActionsContextProvider>
            <STRoutes />
          </LocalActionsContextProvider>
        </AppContextProvider>
      </QueryClientProvider>
      <AlertModal />
    </Provider>
  </ThemeProvider>
);

export default App;
