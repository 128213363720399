import { useCallback } from "react";
import useAuth from "Lib/UseAuth";
import { API_BASE_PATH, BASE_PATH } from "Constants/Environment";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import { useTranslation } from "react-i18next";
import { useAppContext } from "Context/AppContext";
import { HttpException } from "Types/HTTPException";
import useAlertModal from "Components/Modal/Logic/useAlertModal";
import {
  APIOptions,
  AppLevelAPIOptions,
  useBaseSTApi,
} from "Api/Common/UseStApi/useBaseStApi";
import { stringifyURL } from "StaffTrack";

export function useStApi(options?: APIOptions) {
  const { showAttention } = useAlertModal();
  const { t } = useTranslation();
  const { logoutUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLastApiCall, setApiErrorMessage } = useAppContext();

  const logoutIfUnauthorized = useCallback(
    (status: number): void => {
      // check for an unauthorized status, which indicates an expired token
      if (status === 401) {
        logoutUser();
        navigate(
          stringifyURL(paths.login, {
            queryStringParams: {
              redirect:
                location.pathname !== "/"
                  ? location.pathname + location.search
                  : null,
            },
          })
        );
      }
    },
    [logoutUser]
  );

  const appLevelOptions: AppLevelAPIOptions = {
    onUnhandledErrorStatus: (status, method, errorsList) => {
      if (status === 412 || status === 400) {
        showAttention(t("common:apiErrors.unprocessableEntity"));
        if (
          method === "POST" ||
          method === "PUT" ||
          method === "PATCH" ||
          method === "DELETE"
        ) {
          throw new HttpException(
            t("common:apiErrors.unprocessableEntity"),
            status,
            errorsList
          );
        }
      }

      if (status === 403) {
        setApiErrorMessage(t("common:apiErrors.forbidden"));
        if (
          method === "POST" ||
          method === "PUT" ||
          method === "PATCH" ||
          method === "DELETE"
        ) {
          throw new HttpException(
            t("common:apiErrors.forbidden"),
            status,
            errorsList
          );
        }
      }

      if (status === 500) navigate(paths.error.serverError);

      if (status === 401) logoutIfUnauthorized(status);
    },
    setLastApiCall: (dt) => {
      setLastApiCall(dt);
    },
    defaultErrorMessage: "Unknown error",
    defaultMessagesWhenOk: {
      onGet: t("common:notification.get"),
      onPost: t("common:notification.save"),
      onPut: t("common:notification.edit"),
      onDelete: t("common:notification.delete"),
    },
    basePath: API_BASE_PATH || BASE_PATH,
  };
  return useBaseSTApi(appLevelOptions, options);
}
