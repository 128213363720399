import { MdAccountCircle } from "react-icons/md";
import { useAppContext } from "Context/AppContext";
import { HeaderRightContainer, MenuButton } from "./styles";

export const HeaderRight = () => {
  const { setIsProfileOpen, isProfileOpen } = useAppContext();
  const toggleProfileMenu = () => {
    setIsProfileOpen(!isProfileOpen);
  };
  return (
    <HeaderRightContainer
      className="fadeinup animation-duration-500"
      style={{ paddingRight: "0.5rem" }}
    >
      <MenuButton
        role="button"
        onClick={toggleProfileMenu}
        id="btnShowProfile"
        className="ml-1 mr-2 border-noround py-1"
      >
        <MdAccountCircle
          id="btnShowProfile"
          size={38}
          color="white"
          onClick={() => toggleProfileMenu()}
        />
      </MenuButton>
    </HeaderRightContainer>
  );
};
