import { useMutation, useQuery } from "react-query";
import { useStApi } from "Api/Common/UseStApi";
import { cacheQuery } from "Api/Common/Query";
import { Endpoints } from "./endpoints";
import { APIResult } from "Types/Model";
import { LoginInformation } from "Api/StaffTrack/Auth/Models/loginInformation";
import { UserInformation } from "Api/StaffTrack/Auth/Models/userInformation";
import { BaseUserInfo } from "Api/StaffTrack/Auth/Models/baseUserInfo";
import { useNavigate } from "react-router-dom";
import { paths } from "Routes/Paths";
import { ForgotPasswordRequest } from "Api/StaffTrack/Auth/Models/forgotPasswordRequest";
import { ResetPasswordRequest } from "Api/StaffTrack/Auth/Models/resetPasswordRequest";
import { ForgetPwdQuestionDTO } from "Api/StaffTrack/Auth/Models/forgetPwdQuestionDto";
import { stringifyURL } from "StaffTrack";

export const useAuthUser = () => {
  const { post } = useStApi({ throwExceptionOnStatus: [401, 400, 500, 412] });

  return useMutation((userInfo: LoginInformation) => {
    return post<APIResult<UserInformation>>(
      Endpoints.api.auth.login,
      JSON.stringify(userInfo)
    );
  });
};

export const useLogoutUser = (redirect: boolean) => {
  const { post } = useStApi();
  const navigate = useNavigate();

  return useMutation(
    () => {
      return post<APIResult<Boolean>>(Endpoints.api.auth.logout, "");
    },
    {
      onSuccess: () => {
        if (redirect) navigate(paths.login);
      },
    }
  );
};

export const useUserBasicInfo = () => {
  const { get } = useStApi();
  return useQuery<BaseUserInfo>(
    "basicUserInfo",
    () => get(Endpoints.api.auth.basicUserInfo),
    {
      ...cacheQuery,
    }
  );
};

export const useForgotPassword = () => {
  const { post } = useStApi({ throwExceptionOnStatus: [401, 400, 500] });

  return useMutation((forgePasswordRequest: ForgotPasswordRequest) => {
    return post<APIResult<void>>(
      Endpoints.api.auth.forgotPassword,
      JSON.stringify(forgePasswordRequest)
    );
  });
};

export const useResetPassword = () => {
  const { post } = useStApi({ throwExceptionOnStatus: [412, 500] });

  return useMutation((resetPasswordRequest: ResetPasswordRequest) => {
    return post<APIResult<void>>(
      Endpoints.api.auth.resetPassword,
      JSON.stringify(resetPasswordRequest)
    );
  });
};

export const useValidateAnswers = () => {
  const { post } = useStApi({ throwExceptionOnStatus: [412, 500] });

  return useMutation((resetPasswordRequest: ResetPasswordRequest) => {
    return post<APIResult<void>>(
      Endpoints.api.auth.validateAnswers,
      JSON.stringify(resetPasswordRequest)
    );
  });
};

export const useRandomQuestionsForUser = (
  email: string | null,
  token: string | null,
  onError?: (error: Error) => void
) => {
  const { get } = useStApi({ throwExceptionOnStatus: [412, 500] });

  return useQuery(
    ["randomPwdQuestionsForUser", email, token],
    () =>
      get<APIResult<ForgetPwdQuestionDTO[]>>(
        stringifyURL(Endpoints.api.auth.randomPwdQuestionsForUser, {
          queryStringParams: {
            email,
            token,
          },
        })
      ),
    {
      enabled: email !== null && email !== "" && token !== null && token !== "",
      onError,
      retry: false,
      useErrorBoundary: false,
    }
  );
};

export const useForgePwdQuestions = () => {
  const { get } = useStApi();
  return useQuery<APIResult<ForgetPwdQuestionDTO[]>>("forgetPwdQuestions", () =>
    get(Endpoints.api.auth.forgetPwdQuestions)
  );
};

export const useSaveSecurityQuestions = () => {
  const { post } = useStApi({ throwExceptionOnStatus: [412, 500] });

  return useMutation((resetPasswordRequest: ResetPasswordRequest) => {
    return post<APIResult<void>>(
      Endpoints.api.auth.saveSecurityQuestions,
      JSON.stringify(resetPasswordRequest)
    );
  });
};
